const footer = {
    requestACallBack: 'Minta Panggilan Kembali',
    freeAccountMessage: 'Buat akun gratis 14 hari Anda sekarang',
    footerMessage:
        'Revolusi manajemen karyawan dan operasi penjualan dengan mengotomatiskan tugas administratif, menyederhanakan proses, dan meningkatkan keterlibatan karyawan. Fitur kami sederhana, kuat, dan mudah diterapkan di berbagai industri untuk meningkatkan produktivitas organisasi.',
    address:
        '922, A Wing, Nyati Empress, Di sebelah Baker dan Guages India Pvt Ltd, Off Viman Nagar Road, Viman Nagar, 411014, Pune.',
    rightsReserved: 'Naavy Hak Cipta Dilindungi',
};
export default footer;
