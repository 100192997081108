const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Planes flexibles que crecen contigo',
    subFlexiblePlansThatGrowsWithYou:
        'Elige el plan de precios que se ajuste a las necesidades de tu negocio. Naavy ofrece paquetes de precios óptimos con un valor superior.',
    pricingPlans: 'Planes de precios',
    pricingThatWorksForEveryOne: 'Precios que funcionan para todos',
    monthly: 'Mensual',
    annualy: 'Anual',
    international: 'Internacional',
    domestic: 'Nacional',
    month: 'Mes',
    benifits: 'Beneficios',
    allPlanIncludeTheseBenifits: 'Todos los planes incluyen estos beneficios',
    someBenfitsOfMonthlyYearlySubscription: 'Algunos beneficios de la suscripción mensual/anual',
    benifitsArr: [
        {
            title: 'Soporte técnico',
            description: 'Nuestro equipo de soporte técnico profesional te asistirá en cada paso del camino.',
        },
        {
            title: 'Tecnología',
            description: 'Entrenamiento especial para comenzar con la plataforma de la mano de profesionales.',
        },
        {
            title: 'Análisis de crecimiento',
            description: 'Un equipo dedicado para proporcionarte información sobre tu crecimiento mensual.',
        },
        {
            title: 'Recompensa',
            description: 'Una recompensa especial para la cuenta de mejor desempeño cada mes.',
        },
    ],
    fAQs: 'Preguntas Frecuentes',
    frequentlyAskedQuestions: 'Preguntas Frecuentes',
    someBaTypeOfQuestions: 'Aquí tienes algunos tipos comunes de preguntas de nuestros clientes',
    fAQArr: [
        {
            question: '¿Cómo puedo registrar mi empresa?',
            ans: 'Puedes registrarte en Naavy en 3 sencillos pasos a través de nuestra aplicación móvil o aplicación web. Solo agrega tu nombre, correo electrónico y número GST, y estarás listo para comenzar.',
        },
        {
            question: '¿Cuáles son las diferentes formas de autenticar mi inicio de sesión?',
            ans: 'Naavy te ofrece varias opciones para ingresar, por ejemplo, tenemos la opción de inicio de sesión móvil por defecto, la opción basada en selfies y, por último, la más segura, la autenticación facial basada en IA.',
        },
        {
            question: '¿Cómo marcar la asistencia?',
            ans: 'El usuario solo puede marcar la asistencia cuando se encuentra dentro de la geovalla asignada a él. Además, para evitar el uso de proxy, tenemos diferentes métodos de autenticación.',
        },
        {
            question:
                '¿Podemos simular la ubicación o cambiar la hora para las tareas completadas o la asistencia marcada?',
            ans: 'Naavy detecta cualquier actividad de spam o no solicitada y desactivará la aplicación y notificará al usuario.',
        },
        {
            question: '¿Naavy soporta empleados remotos?',
            ans: 'Naavy soporta empleados contratados por hora y calcula la nómina en función de un turno de horas fijas o flexibles.',
        },
        {
            question: '¿Puedo rastrear mi fuerza laboral a través del móvil?',
            ans: 'Naavy te ayuda a rastrear y administrar tu fuerza laboral a través del móvil y la web, de manera cronológica, brindándote una vista detallada del día.',
        },
        {
            question: '¿Qué otros servicios ofrece Naavy además de HRMS?',
            ans: 'Naavy ofrece una lista muy completa de servicios para facilitar el trabajo de los gerentes o propietarios de negocios y ayudarles solo con las notificaciones necesarias.',
        },
    ],
};
export default pricingPage;
