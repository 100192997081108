const joinUsPage = {
    joinUsString: 'Junte-se a Nós',
    joinUsHeroPara:
        'Venha fazer parte da nossa equipe na Naavy! Explore oportunidades emocionantes onde o trabalho em equipe e a criatividade são o caminho. Descubra como você pode contribuir para nossa missão de capacitar empresas através de soluções de software intuitivas. Desenvolva sua carreira em um ambiente de apoio que valoriza seus talentos e ideias únicas.',
    ourRecruitmentProcess: 'Nosso Processo de Recrutamento',
    ourBenifitsString: 'Nossos Benefícios',
    ourRecruitmentProcessObjects: [
        {
            title: 'Entrevista Técnica',
            description:
                'Esta entrevista é projetada para avaliar suas habilidades técnicas, habilidades de resolução de problemas e como você aplica seu conhecimento em cenários do mundo real. Queremos obter uma visão sobre sua proficiência técnica, capacidades de codificação e sua abordagem para enfrentar desafios. Esta etapa é realizada por meio de chamada no Meet/Skype.',
        },
        {
            title: 'Teste Prático (Aptidão)',
            description:
                'Avaliamos sua lógica de codificação e otimização de código ao atribuir uma tarefa que será enviada por e-mail após sua primeira etapa, com base em sua entrevista. A tarefa terá um prazo determinado dentro do qual o candidato deve completá-la.',
        },
        {
            title: 'Entrevista com a Gestão',
            description:
                'Conversa rápida com nossa gestão para entender sua visão de carreira e adequação cultural à nossa organização. Procuramos uma pessoa com sólido conhecimento técnico e forte ética de trabalho.',
        },
        {
            title: 'Seleção Final',
            description:
                'A finalização da oferta será feita com a equipe de RH. Fazemos nossa seleção final com base na consolidação detalhada do desempenho dos candidatos em todas as etapas da entrevista. Com base na decisão mútua, as datas de início serão confirmadas, e fornecemos as melhores ferramentas desde o primeiro dia.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'Trabalhe de Qualquer Lugar!',
            description:
                "Acho que é de volta à mesa! Porque seu escritório em casa é um pouco amigável demais com cães e sua 'sala de reuniões' está atualmente ocupada por um gato dorminhoco. Não oferecemos trabalho de qualquer lugar porque gostamos de ver você todos os dias.",
        },
        {
            name: 'Fins de Semana Alternados',
            description:
                'Quando seus fins de semana são tão alternados quanto seus humores. Uma semana, você está planejando uma escapada divertida ou colocando o sono em dia, e na outra, você está enfrentando prazos e respondendo e-mails. É tudo parte da aventura em equilibrar trabalho e lazer.',
        },
        {
            name: 'Seguro de Saúde',
            description:
                "Para todos aqueles momentos de 'talvez eu precise de um médico para isso'. Com cobertura abrangente, você não terá que se preocupar com os 'e se' — apenas foque em melhorar e voltar ao seu melhor. Porque quando você não está se sentindo bem, merece ter o melhor atendimento ao seu alcance.",
        },
        {
            name: 'Bônus de Indicação',
            description:
                'Tem amigos? Ganhe bônus! É um ganha-ganha. Imagine isso: você tem um círculo de amigos que são incríveis no que fazem e estão procurando novas oportunidades. Ao indicá-los para nossa empresa, você está não apenas ajudando-os a encontrar um trabalho fantástico, mas também enchendo seus próprios bolsos com um doce bônus de indicação.',
        },
        {
            name: 'Kit de Funcionário',
            description:
                'Kit de Funcionário - Tudo o que você precisa para conquistar o dia de trabalho, exceto um clone de si mesmo. Desde materiais de escritório essenciais até brindes motivacionais, este kit tem tudo. Precisa de uma caneta? Verificado. Caderno para todas as suas ideias brilhantes? Verificado.',
        },
        {
            name: 'Horário de Trabalho Flexível',
            description:
                'Nada de correr para casa para assistir ao seu programa favorito; agora, seu escritório pode ser o seu sofá, e seu horário de pausa pode ser o horário nobre. Com horários flexíveis, você pode fazer a transição suavemente de chamadas de conferência para maratonas de Netflix, de planilhas para episódios de sua série preferida.',
        },
        {
            name: 'Licença Sabática',
            description: 'Licença Sabática',
        },
        {
            name: 'Licença Paternidade/Maternidade',
            description: 'Licença Paternidade/Maternidade',
        },
        {
            name: 'Equilíbrio entre Vida e Trabalho',
            description: 'Equilíbrio entre Vida e Trabalho',
        },
        {
            name: 'Celebrações no Escritório',
            description: 'Celebrações no Escritório',
        },
    ],
    jobsAtTraceForce: 'Vagas na Naavy',
};
export default joinUsPage;
