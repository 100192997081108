const homePage = {
    heroHeader: 'تعزيز التميز في المبيعات وكفاءة العمليات من خلال',
    heroHeaderTypist: ['إدارة الحضور', 'مراقبة تتبع الحقل', 'إدارة علاقات العملاء (CRM)'],
    heroHeaderSubText:
        'الحضور بناءً على الموقع في الوقت الفعلي، التتبع المباشر، إدارة المهام والطلبات، رؤى البيانات وخدمات API',
    moreProductivityLessExpenses: 'إنتاجية أكثر، نفقات أقل',
    keyFeatures: [
        {
            title: 'العدسة',
            description:
                'تسجيل الحضور والخروج عبر التعرف على الوجه بدون لمس بمساعدة الذكاء الاصطناعي. سجل الحضور باستخدام كاميرا هاتفك المحمول.',
        },
        {
            title: 'تطبيق الويب',
            description:
                'لوحة تحكم سهلة الاستخدام، يمكن لفريق الموارد البشرية والمديرين الوصول بسهولة إلى التقارير التفصيلية، وإدارة سجلات الموظفين، وتتبع الأداء، وفحص الرواتب.',
        },
        {
            title: 'التحكم الهرمي',
            description:
                'نظام تحكم هرمي لاستيعاب المستويات التنظيمية المختلفة بما في ذلك الإداريين ومديري الموارد البشرية والمشرفين والموظفين.',
        },
        {
            title: 'تحسين المسار',
            description:
                'عزز الكفاءة وخفض التكاليف التشغيلية من خلال حسابات المسار الديناميكية، وتحسين التوقفات المتعددة، وتقدير التكاليف، والتحديثات في الوقت الفعلي.',
        },
    ],
    features: 'الميزات',
    ourFeatures: 'ميزاتنا بسيطة وقوية وسهلة التكيف مع أي صناعة لزيادة إنتاجية المؤسسة.',
    ourFeaturesObject: [
        {
            title: 'مراقبة الحضور',
            description: 'تسجيل الحضور مع الموقع وتتبع ساعات العمل.',
        },
        {
            title: 'إدارة المصاريف',
            description: 'يتم تتبع وتسجيل المصاريف بدقة.',
        },
        {
            title: 'تتبع القوى العاملة',
            description: 'مراقبة وتسجيل أنشطة الموظفين والإنتاجية.',
        },
        {
            title: 'إدارة الرواتب',
            description: 'تحسين معالجة الرواتب والدفع بدقة.',
        },
        {
            title: 'إدارة الطلبات',
            description: 'عملية إدارة الطلبات/المجموعات من خلال استراتيجيات مخصصة متعددة.',
        },
        {
            title: 'تحسين المسار',
            description: 'العثور على المسار الأكثر كفاءة لتوفير الوقت والوقود والموارد.',
        },
    ],
    whatWeDoQuestion: 'ماذا تفعل Naavy',
    whatWeDoCards: [
        {
            title: 'تحليل أنشطة الموظفين في الوقت الفعلي',
            description: 'احصل على تحليل مفصل لأنشطة الفريق على أساس يومي أو ساعتي.',
        },
        {
            title: 'تقارير الحضور وتسجيل الدخول بناءً على الجيوفنس',
            description: 'تعزيز كفاءة الفريق من خلال إدارة الحضور والإجازات التشغيلية القياسية.',
        },
        {
            title: 'إدارة الطلبات/التحصيل بفعالية',
            description:
                'تقليل الأخطاء أثناء زيادة الإنتاجية ورضا العملاء من خلال استراتيجيات الطلب الذكية والتحصيل في الوقت المناسب.',
        },
        {
            title: 'إدارة المهام من البداية إلى النهاية',
            description: 'تبسيط تخطيط المشاريع، تعيين المهام بسهولة، وتعزيز التعاون في الوقت الفعلي بين أعضاء الفريق.',
        },
        {
            title: 'رؤى بناءً على التقارير المؤتمتة',
            description: 'تصور مشاركة الموظفين وأداء تحليل الفريق بشكل مثالي.',
        },
        {
            title: 'إدارة النفقات بناءً على المخصصات',
            description: 'إدارة أنظمة الموارد البشرية لتوزيع قسائم الدفع الإلكترونية وفقًا للسجلات في جداول الحضور.',
        },
    ],
    whatWeDoList: [
        'سجلات الحضور',
        'متعقب الموقع الجغرافي',
        'تخزين سحابي',
        'تنبيهات وإشعارات',
        'إدارة المشاريع',
        'إدارة قنوات المبيعات',
    ],
    featureTitle: 'الميزات',
    featureSubTitle: 'تعد ميزاتنا بسيطة وقوية وسهلة التكيف مع أي صناعة لزيادة إنتاجية المنظمة.',
    featureCards: [
        {
            title: 'مراقبة الحضور',
            description: 'تسجيل الحضور بالموقع وتتبع ساعات العمل',
        },
        {
            title: 'إدارة النفقات',
            description: 'تتبع وتسجيل النفقات بدقة',
        },
        {
            title: 'تتبع القوى العاملة',
            description: 'مراقبة وتسجيل أنشطة الموظفين، والإنتاجية',
        },
        {
            title: 'تحليلات المبيعات الميدانية',
            description: 'جمع بيانات المبيعات وقياس أداء المبيعات',
        },
        {
            title: 'إدارة الطلبات',
            description: 'عملية إدارة الطلبات/التحصيل من خلال استراتيجيات مخصصة متعددة',
        },
        {
            title: 'التقارير المباشرة',
            description: 'معرفة مكان موظفي الميدان وماذا يفعلون',
        },
    ],
    integrationTitle: 'الصناعات',
    integrationSubTitle: 'جسر إدارة القوى العاملة والإنتاجية لكل الصناعات',
    orderManagementSubtitle: 'أكثر من مجرد نظام إدارة الطلبات',

    orderManagementTitle: 'أتمتة وتتبع وتوصيل الطلبات بدقة مع إدارة الطلبات والتحصيل لفريق المبيعات الخاص بك.',
    orderManagementAccordian: [
        {
            title: 'إدخال الطلبات الإلكتروني',
            points: [
                'تتبع حالة الطلب في الوقت الفعلي من التقديم إلى التسليم.',
                'رقمنة العملية من خلال تسجيل الطلبات وتقليل الأخطاء اليدوية.',
            ],
        },
        {
            title: 'الفوترة المؤتمتة',
            points: [
                'إنشاء الفواتير تلقائيًا بناءً على تفاصيل الطلب.',
                'إرسال الفواتير إلكترونيًا لسرعة المعالجة والدفع.',
                'التحقق من الدفع باستخدام OTP ضد الفواتير.',
            ],
        },
        {
            title: 'أهداف المبيعات',
            points: [
                'تتبع التقدم ضد الأهداف باستخدام تقارير وتحليلات مفصلة.',
                'تعيين ومراقبة أهداف المبيعات للفرق بناءً على الأدوار والمسؤوليات.',
            ],
        },
        {
            title: 'تتبع المخزون في الوقت الحقيقي',
            points: [
                'الحفاظ على حساب دقيق لمستويات المخزون كلما تم إنشاء الطلب وإكماله.',
                'تعيين نقاط إعادة الطلب لتوليد أوامر شراء تلقائيًا عند انخفاض المخزون.',
            ],
        },
        {
            title: 'توزيع الطلب',
            points: [
                'مراقبة حركة البضائع من المخزن إلى العميل، مع ضمان المساءلة والشفافية.',
                'تحسين عملية التوزيع بين فريق المبيعات بناءً على الأولوية والموقع.',
                'تحسين مسارات التوصيل باستخدام الذكاء الاصطناعي لتسريع التوصيل وتوفير التكاليف.',
            ],
        },
    ],
    whyChooseTraceforce: 'لماذا تختار Naavy',
    whyChooseTraceforceSubtitle:
        'ثورة إدارة الموارد البشرية من خلال أتمتة المهام الإدارية، تبسيط العمليات، وتعزيز مشاركة الموظفين.',
    whyChooseTraceForceList: [
        'تحسين الإنتاجية',
        'كفاءة الوقت',
        'دقة في المطالبات',
        'مراقبة في الوقت الحقيقي',
        'استجابة كاملة',
        'تحسين باستخدام الذكاء الاصطناعي',
        'تخصيص بناءً على المعايير',
        'دعم فروع متعددة',
        'خدمة دعم على مدار الساعة',
    ],
    calculatorTitle: 'الآلة الحاسبة',
    calculatorSubtitle: 'تعد ميزاتنا بسيطة وقوية وسهلة التكيف مع أي صناعة لزيادة إنتاجية المنظمة.',
    workingHours: 'ساعات العمل',
    uncountedHours: 'الساعات غير المحسوبة',
    dailySavings: 'التوفير اليومي',
    monthlySavings: 'التوفير الشهري',
    productivityGrowth: 'نمو الإنتاجية',
    numberOfEmployees: 'عدد الموظفين',
    faqs: 'الأسئلة الشائعة',
    frequentlyAskedQuestions: 'أسئلة متكررة',
    faqLine: 'إليك بعض الأسئلة الأساسية لعملائنا',
    faqAccordian: [
        {
            question: 'كيف يمكنني تسجيل شركتي؟',
            ans: 'يمكنك التسجيل في Naavy بخطوات سهلة على تطبيقنا المحمول أو الويب. فقط أضف اسمك وبريدك الإلكتروني ورقم GST وستكون جاهزًا.',
        },
        {
            question: 'ما هي الطرق المختلفة التي يمكنني بها توثيق تسجيل الدخول الخاص بي؟',
            ans: 'تقدم Naavy لك خيارات تسجيل دخول متعددة، على سبيل المثال، لدينا خيار تسجيل الدخول الافتراضي عبر الهاتف المحمول، خيار تسجيل الدخول بناءً على الصور الشخصية، وأخيرًا الأكثر أمانًا وهو التوثيق عبر الذكاء الاصطناعي.',
        },
        {
            question: 'كيف يتم تسجيل الحضور؟',
            ans: 'يمكن للمستخدم تسجيل الحضور فقط عندما يكون الشخص داخل الجيوفنس المخصص له. أيضًا، لضمان عدم التلاعب، لدينا طرق توثيق مختلفة.',
        },
        {
            question: 'هل يمكننا تزوير الموقع أو تغيير الوقت لإكمال المهمة أو تسجيل الحضور؟',
            ans: 'تكتشف Naavy أي نشاط غير مرغوب فيه أو احتيالي في التطبيق وتقوم بتعطيله وت notifying المستخدم.',
        },
        {
            question: 'هل تدعم Naavy الموظفين عن بُعد؟',
            ans: 'نعم، تدعم Naavy الموظفين العاملين على أساس تعاقدي على أساس الساعة، وبالتالي حساب الرواتب بناءً على الساعات الثابتة أو الساعات المرنة.',
        },
        {
            question: 'هل يمكنني تتبع القوى العاملة الخاصة بي عبر الهاتف المحمول؟',
            ans: 'تساعدك Naavy في تتبع وإدارة قوتك العاملة عبر الهاتف المحمول والويب، مما يمنحك تفاصيل شاملة عن اليوم.',
        },
        {
            question: 'ما هي الخدمات الأخرى التي تقدمها Naavy بخلاف نظام إدارة الموارد البشرية؟',
            ans: 'تقدم Naavy قائمة شاملة جدًا من الخدمات لتسهيل عمل المديرين أو أصحاب الأعمال ومساعدتهم بالمعلومات المطلوبة فقط.',
        },
    ],

    referAndGet: 'أوصي واحصل',
    referAndGetH2: 'احصل على خصم 8% على باقات Naavy السنوية!',
    referAndGetp:
        'أوصِ بصديق أو عميل إلى Naavy واحصل على خصم 8% على باقتك السنوية بمجرد انضمامهم إلينا! شارك الفوائد ووفّر معًا!',
};
export default homePage;
