const footer = {
    requestACallBack: 'Request a Callback',
    freeAccountMessage: 'Create your free 14-day account now',
    footerMessage:
        'Revolutionising Employee management and sales operation by automating administrative tasks, streamlining processes and enhancing employee engagement. Our features are simple, powerful and an easy fit to any industry to increase the organisation’s productivity.',
    address: `922, A Wing, Nyati Empress,
Next to Baker and Guages India Pvt Ltd,
Off Viman Nagar Road, Viman Nagar, 411014, Pune.`,
    rightsReserved: 'Naavy All Right Reserved',
};
export default footer;
