const joinUsPage = {
    joinUsString: 'Join Us',
    joinUsHeroPara:
        'Come be a part of our team at Naavy! Explore exciting opportunities where teamwork and creativity lead the way. Discover how you can contribute to our mission of empowering businesses through intuitive software solutions. Grow your career in a supportive environment that values your unique talents and ideas.',
    ourRecruitmentProcess: 'Our Recruitment Process',
    ourBenifitsString: 'Our Benefits',
    ourRecruitmentProcessObjects: [
        {
            title: 'Technical Interview',
            description:
                'This interview is designed to assess your technical skills, problem-solving abilities, and how well you apply your knowledge to real-world scenarios. We want to gain insight into your technical proficiency, coding capabilities, and your approach to tackling challenges. this round is done via meet/skype call.',
        },
        {
            title: 'Practice Test(Aptitude)',
            description:
                'We evaluate your coding logic and code optimization by assigning a task which will be mailed after your first round based upon your interview. The assignment will be given a certain timeline within which the candidate has to complete it.',
        },
        {
            title: 'Management Interview',
            description:
                'Quich talk with our management to understand your career vision and cultural fit in our organization.We look out for a person with solid subject matter knowledge and strong work ethics.',
        },
        {
            title: 'Final Selection',
            description:
                'Offer finalization will be done with HR team.We make our final selection with in depth consolidation of candidates performance. in all rounds of interview. based on mutual decision joining dates will be confirmed, we provide you with best tools on day one.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'Work from Anywhere!',
            description:
                "Guess it's back to the desk! Because your home office is a bit too dog-friendly and your 'meeting room' is currently occupied by a cat nap. We don’t offer Work from anywhere because we like to see you everyday.",
        },
        {
            name: 'Alternate weekends',
            description:
                "When your weekends are as alternating as your mood swings. One week, you're planning a fun getaway or catching up on sleep, and the next, you're tackling deadlines and answering emails. It’s all part of the adventure in balancing work and play.",
        },
        {
            name: 'Health Insurance',
            description:
                'For all those ‘I might need a doctor for this’ moments. With comprehensive coverage, you won’t have to worry about the ‘what ifs’—just focus on getting better and getting back to your best self. Because when you’re not feeling your best, you deserve to have the best care at your fingertips.',
        },
        {
            name: 'Referral Bonus',
            description:
                "Got friends? Get bonuses! It's a win-win. Imagine this: You’ve got a circle of friends who are amazing at what they do and looking for new opportunities. By referring them to our company, you’re not only helping them find a fantastic job, but you're also lining your own pockets with a sweet referral bonus",
        },
        {
            name: 'Employee Kit',
            description:
                'Employee Kit - Everything you need to conquer the workday, except a clone of yourself. From essential office supplies to motivational goodies, this kit has it all. Need a pen? Check. Notebook for all your brilliant ideas? Check.',
        },
        {
            name: 'Flexible Working Hours',
            description:
                'No more rushing home to catch your favorite show; now, your office can be your couch, and your break time can be prime time. With flexible hours, you can seamlessly transition from conference calls to cliffhangers, from spreadsheets to binge-watching Netflix shows.',
        },
        {
            name: 'Sabbatical Leave',
            description: 'Sabbatical Leave',
        },
        {
            name: 'Paternity/ Maternity Leave',
            description: 'Paternity/ Maternity Leave',
        },
        {
            name: 'Work-Life Balance',
            description: 'Work-Life Balance',
        },
        {
            name: 'Office Celebrations',
            description: 'Office Celebrations',
        },
    ],
    jobsAtTraceForce: 'Jobs at Naavy',
};
export default joinUsPage;
