import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setDataForQR, setShowQRModal } from 'reduxFolder/uiSlice';
import { QRCodeSVG } from 'qrcode.react';
import config from 'config';

const QRModal = () => {
    const dataForQR = useSelector((state) => state.ui.dataForQR);
    const showQRModal = useSelector((state) => state.ui.showQRModal);
    const dispatch = useDispatch();

    const [qrLink, setQrLink] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchQRCode = async () => {
            if (dataForQR) {
                setLoading(true); // Start loading
                console.log('Data for QR:', dataForQR);

                const myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json');

                const raw = JSON.stringify(dataForQR);

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };

                fetch(config.QR_CODE_API, requestOptions)
                    .then((response) => response.json()) // Parse JSON response
                    .then((result) => {
                        console.log('API Response:', result);
                        if (result?.status === 'success') {
                            setQrLink(result.details.emailLink);
                        } else {
                            console.error('API Error:', result?.message || 'Unknown error');
                        }
                    })
                    .catch((error) => {
                        console.error('API Request Failed:', error.message || error);
                    })
                    .finally(() => {
                        setLoading(false); // Stop loading
                    });
            }
        };

        fetchQRCode();
    }, [dataForQR]);

    return (
        <Modal
            closeButton
            centered
            size="sm"
            onHide={() => {
                dispatch(setShowQRModal(false));
                dispatch(setDataForQR(null));
                setQrLink(null);
            }}
            show={showQRModal}
            className="p-3">
            <Card className="text-center" style={{ backgroundColor: 'white' }}>
                <Card.Body>
                    <p style={{ fontSize: '12px' }} className="text-secondary p-1">
                        We have received your information. We shall call you in a few minutes to confirm the details.
                        For any queries please call us at +919209640804.
                    </p>
                    <h6 className="fw-semibold">Meanwhile, you can explore our Mobile App</h6>
                    <hr className="m-1" />
                    <h6 className="fw-semibold">Download Naavy Mobile App</h6>
                    <Row className="justify-content-center p-1">
                        {loading ? (
                            <p>Loading QR Code...</p>
                        ) : qrLink ? (
                            <QRCodeSVG value={qrLink} size={150} />
                        ) : (
                            <p>QR Code will appear here.</p>
                        )}
                    </Row>
                    <h6 className="mt-5 mt-sm-2 fs-14 fw-semibold text-uppercase">Mobile Apps</h6>
                    <Row className=" justify-content-center">
                        <Col className="d-flex justify-content-center" xs={6} sm={5}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.trace.attendancePro&pcampaignid=web_share"
                                className="">
                                <img src="/images/buttons/google.png" alt="" height="30" />
                            </a>
                        </Col>
                        <Col className="d-flex justify-content-center" xs={6} sm={5}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.trace.attendancePro&pcampaignid=web_share"
                                className="">
                                <img src="/images/buttons/store.png" alt="" height="30" />
                            </a>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Modal>
    );
};

export default QRModal;
