const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Des plans flexibles qui évoluent avec vous',
    subFlexiblePlansThatGrowsWithYou: `Choisissez le plan tarifaire qui répond aux besoins de votre entreprise. Naavy propose des forfaits à des prix optimaux tout en offrant une valeur supérieure.`,
    pricingPlans: 'Plans tarifaires',
    pricingThatWorksForEveryOne: 'Tarification adaptée à tous',
    monthly: 'Mensuel',
    annualy: 'Annuel',
    international: 'International',
    domestic: 'National',
    month: 'Mois',
    benifits: 'Avantages',
    allPlanIncludeTheseBenifits: 'Tous les plans incluent ces avantages',
    someBenfitsOfMonthlyYearlySubscription: 'Certains avantages de l’abonnement mensuel/annuel',
    benifitsArr: [
        {
            title: 'Assistance technique',
            description: 'Notre équipe d’assistance technique professionnelle vous aidera à chaque étape',
        },
        {
            title: 'Technologie',
            description: 'Formation spéciale pour démarrer avec la plateforme par des professionnels',
        },
        {
            title: 'Analyse de croissance',
            description: 'Une équipe dédiée pour fournir des insights sur votre croissance mensuelle',
        },
        {
            title: 'Récompense',
            description: 'Une récompense spéciale pour le meilleur compte de performance chaque mois',
        },
    ],
    fAQs: 'Questions fréquentes',
    frequentlyAskedQuestions: 'Questions fréquemment posées',
    someBaTypeOfQuestions: 'Voici quelques types de questions courantes de nos clients',
    fAQArr: [
        {
            question: 'Comment puis-je inscrire mon entreprise ?',
            ans: 'Vous pouvez vous inscrire sur Naavy en 3 étapes simples via notre application mobile ou web. Ajoutez simplement votre nom, votre email et votre numéro de TVA, et c’est tout.',
        },
        {
            question: 'Quelles sont les différentes façons d’authentifier ma connexion ?',
            ans: 'Naavy vous offre plusieurs options de connexion, telles que la connexion par défaut via mobile, l’option basée sur un selfie et, enfin, l’authentification faciale basée sur l’IA, la plus sécurisée.',
        },
        {
            question: 'Comment enregistrer la présence ?',
            ans: 'L’utilisateur peut enregistrer sa présence uniquement lorsqu’il se trouve dans la géozone qui lui est attribuée. En outre, nous disposons de différentes méthodes d’authentification pour prévenir les fraudes.',
        },
        {
            question:
                'Peut-on simuler la localisation ou modifier l’heure pour les tâches terminées ou la présence marquée ?',
            ans: 'Naavy détecte toute activité frauduleuse ou non sollicitée, désactive l’application et informe l’utilisateur.',
        },
        {
            question: 'Naavy prend-il en charge les employés à distance ?',
            ans: 'Naavy prend en charge les employés sous contrat à l’heure et calcule les salaires sur la base d’horaires fixes ou flexibles.',
        },
        {
            question: 'Puis-je suivre mon équipe via mobile ?',
            ans: 'Naavy vous aide à suivre et à gérer votre équipe via mobile et web de manière chronologique, offrant une vue détaillée de la journée.',
        },
        {
            question: 'Quels autres services Naavy propose-t-il en dehors de HRMS ?',
            ans: 'Naavy offre une liste complète de services pour faciliter le travail des gestionnaires ou des propriétaires d’entreprise et les aider uniquement avec les notifications nécessaires.',
        },
    ],
};

export default pricingPage;
