const footer = {
    requestACallBack: 'Запросить обратный звонок',
    freeAccountMessage: 'Создайте свою бесплатную учетную запись на 14 дней сейчас',
    footerMessage:
        'Революционизируем управление сотрудниками и операции продаж, автоматизируя административные задачи, упрощая процессы и повышая вовлеченность сотрудников. Наши функции просты, мощны и легко подходят для любой отрасли, чтобы увеличить продуктивность организации.',
    address:
        '922, A Wing, Nyati Empress, Рядом с Baker и Guages India Pvt Ltd, Off Viman Nagar Road, Viman Nagar, 411014, Pune.',
    rightsReserved: 'Naavy Все права защищены',
};
export default footer;
