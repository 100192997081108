const industries = {
    pharmaceutical: {
        industryHeading:
            'Monitor safety protocols, tracking time, attendance and control access to sensitive areas by facilitating effective project management, off site activities and performance evaluation.',

        featureHeader: 'Pharmaceuticals',
        featureParagraph:
            'Pharmaceutical-focused attendance management and field tracking software streamlines workforce management for pharmaceutical/healthcare companies with features of  time tracking, mobile accessibility for field employees, field operations compliance reporting, and integration with different systems for sales generation and productivity.',
        keyPoints: [
            {
                header: 'Integrate employee tracking systems with HR systems  related to employee records, payroll, and compliance reporting.',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Utilize shift management systems to optimize workforce scheduling',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Monitoring schedules of manufacturing and production areas where continuous operations are crucial.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Ensure that all employee tracking systems comply with relevant regulations and standards in the pharmaceutical industry.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
    },
    education: {
        industryHeading: 'A Comprehensive Admissions CRM for Seamless Students Collaboration and Prospect Management',

        featureHeader: 'Education',
        featureParagraph:
            'Student Recruitment and Admissions \n Student Engagement and Retention \n Manage admissions at multiple locations \n Personalized Student Support',
        keyPoints: [
            {
                header: 'Track and manage prospective students from the first point of contact through the application process.',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Identify and manage potential students who show interest in enrolling. Track communication and progress to convert inquiries into enrolled students.',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Visualize and manage the different stages of the student enrollment process.Move applicants through stages like Inquiry, Application, Interview, Acceptance, and Enrollment.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Simplify the application process with customizable online forms.Collect necessary information from prospective students efficiently.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'Optimize the performance of sales teams on the move by enabling real time location tracking, ensuring effecient route planning and timely customer engagements.',

        featureHeader: 'Field Sales',
        featureParagraph:
            'Accelerate growth and turbocharge your field force with the right tools to Plan, Convert & Execute. Naavy simplifies field sales tracking and managing leads and crm',
        keyPoints: [
            {
                header: 'Real Time Attendance',
                paragraph:
                    'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
            },
            {
                header: 'Total WorkingHours Calculation',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Remote Access Attendance Reports',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Centralized attendance management',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'Performance metrics, expense management and compliance tracking effective for sales force management, enhancing customer engagement  with communication and collaboration features',
        featureParagraph:
            'Real-time attendance tracking enables quick adjustments to staffing levels based on market demand, enhancing the agility of FMCG operations.',
        keyPoints: [
            {
                header: 'Accurate attendance tracking aids',
                paragraph:
                    'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
            },
            {
                header: 'Precise payroll processing, reducing errors and minimizing financial discrepancies.',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Ensuring adherence to labor laws and regulations,',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Facilitating effective leave management, preventing understaffing issues',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Ensuring continuous production and distribution.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Ensuring accurate reimbursement processes.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Tracking orders on the go and completions.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
    },
    services: {
        industryHeading:
            'Streamline project and task management,optimize resources allocation by ensuring accurate time and attendance records,aiding in payrool procession and compliance.',
        featureHeader: 'Services',
        featureParagraph:
            'Field-tested features supercharge productivity, minimize complexity, and automate tasks, saving time and resources for your business.',
        keyPoints: [
            {
                header: 'Real-time field staff GPS tracking',
                paragraph:
                    'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
            },
            {
                header: 'Custom Forms & Geo-tagged Photos',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Intuitive dashboard & downloadable reports',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Manage & monitor collections',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
    },
    fieldOperations: {
        industryHeading:
            'Facilitate data driven decisions and enhance communication between field teams and headquarters.',

        featureHeader: 'Field Operations',
        featureParagraph:
            'Say goodbye to inefficient field operations & service management with Naavy all-in-one solution. Optimize task management,track attendance on the go, AI based route optimization to increase productivity and sales revenue.',
        keyPoints: [
            {
                header: 'Schedule & monitor tasks',
                paragraph:
                    'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
            },
            {
                header: 'Track the real-time location of all field employees',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Validate and process expense claims',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Set SLA Targets & Monitor Delays',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Intuitive dashboard & downloadable reports',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'OTP Verified Task Completition',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'Facilitate data driven decisions and enhance communication between field teams and headquarters.',

        featureHeader: 'Logistics And Transportation',
        featureParagraph:
            'Streamline and organize logistics and delivery in a more simple way. Manage logistics operations of large workforce with its robust and advanced features.',
        keyPoints: [
            {
                header: 'Gain real time tracking of delivery person location.',
                paragraph:
                    'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
            },
            {
                header: 'Improve operational efficiency by monitoring activities and workflows.',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Simplify workforces task management.',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Analyse historical data and real time traffic information to optimise delivery routes.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Automated reimbursement and payroll processing.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
            {
                header: 'Greater customer service with automated field works.',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
    },
};

export default industries;
