const industries = {
    pharmaceutical: {
        industryHeading:
            'Surveillez les protocoles de sécurité, suivez le temps, la présence et contrôlez l’accès aux zones sensibles en facilitant la gestion de projets, les activités hors site et l’évaluation des performances.',

        featureHeader: 'Pharmaceutique',
        featureParagraph:
            'Un logiciel de gestion de présence et de suivi sur le terrain axé sur le secteur pharmaceutique simplifie la gestion de la main-d’œuvre pour les entreprises pharmaceutiques/de soins de santé avec des fonctionnalités de suivi du temps, d’accessibilité mobile pour les employés de terrain, de reporting sur la conformité des opérations sur le terrain et d’intégration avec différents systèmes pour la génération de ventes et la productivité.',
        keyPoints: [
            {
                header: 'Intégrer les systèmes de suivi des employés avec les systèmes RH liés aux dossiers des employés, à la paie et aux rapports de conformité.',
                paragraph: 'Surveiller les heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: 'Utiliser des systèmes de gestion des quarts pour optimiser la planification de la main-d’œuvre',
                paragraph: 'Accéder aux rapports de présence de plusieurs branches à distance',
            },
            {
                header: 'Surveiller les horaires des zones de fabrication et de production où les opérations continues sont cruciales.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Veiller à ce que tous les systèmes de suivi des employés soient conformes aux réglementations et normes pertinentes dans l’industrie pharmaceutique.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
        ],
    },
    education: {
        industryHeading:
            'Un CRM d’admissions complet pour une collaboration transparente des étudiants et une gestion des prospects',

        featureHeader: 'Éducation',
        featureParagraph:
            'Recrutement et admissions des étudiants \n Engagement et fidélisation des étudiants \n Gérer les admissions à plusieurs emplacements \n Support personnalisé aux étudiants',
        keyPoints: [
            {
                header: 'Suivre et gérer les étudiants potentiels depuis le premier point de contact jusqu’au processus de candidature.',
                paragraph: 'Surveiller les heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: 'Identifier et gérer les étudiants potentiels intéressés par l’inscription. Suivre la communication et les progrès pour convertir les demandes en étudiants inscrits.',
                paragraph: 'Accéder aux rapports de présence de plusieurs branches à distance',
            },
            {
                header: 'Visualiser et gérer les différentes étapes du processus d’inscription des étudiants. Faire avancer les candidats à travers les étapes telles que la demande, l’entretien, l’acceptation et l’inscription.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Simplifier le processus de candidature avec des formulaires en ligne personnalisables. Collecter les informations nécessaires auprès des étudiants potentiels de manière efficace.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'Optimisez la performance des équipes de vente en déplacement en permettant un suivi de localisation en temps réel, en assurant une planification efficace des itinéraires et des engagements clients opportuns.',

        featureHeader: 'Ventes sur le terrain',
        featureParagraph:
            'Accélérez la croissance et boostez votre force de vente avec les bons outils pour Planifier, Convertir et Exécuter. Naavy simplifie le suivi des ventes sur le terrain et la gestion des leads et du CRM.',
        keyPoints: [
            {
                header: 'Présence en temps réel',
                paragraph:
                    'Informations en temps réel sur la présence ou l’absence d’individus dans la zone de géorepérage ou dans la branche',
            },
            {
                header: 'Calcul des heures de travail totales',
                paragraph: 'Surveiller les heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: 'Accès à distance aux rapports de présence',
                paragraph: 'Accéder aux rapports de présence de plusieurs branches à distance',
            },
            {
                header: 'Gestion centralisée des présences',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'Métriques de performance, gestion des dépenses et suivi de la conformité efficaces pour la gestion de la force de vente, amélioration de l’engagement client avec des fonctionnalités de communication et de collaboration',
        featureParagraph:
            'Le suivi de présence en temps réel permet des ajustements rapides des niveaux de personnel en fonction de la demande du marché, améliorant l’agilité des opérations FMCG.',
        keyPoints: [
            {
                header: 'Le suivi précis des présences aide',
                paragraph:
                    'Informations en temps réel sur la présence ou l’absence d’individus dans la zone de géorepérage ou dans la branche',
            },
            {
                header: 'Traitement de la paie précis, réduction des erreurs et minimisation des écarts financiers.',
                paragraph: 'Surveiller les heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: 'Assurer le respect des lois et règlements du travail',
                paragraph: 'Accéder aux rapports de présence de plusieurs branches à distance',
            },
            {
                header: 'Faciliter la gestion des congés, prévenir les problèmes de sous-effectif',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Assurer une production et une distribution continues.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Assurer des processus de remboursement précis.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Suivi des commandes en déplacement et des complétions.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
        ],
    },
    services: {
        industryHeading:
            'Rationalisez la gestion de projets et de tâches, optimisez l’allocation des ressources en garantissant des enregistrements précis de temps et de présence, en aidant à la gestion de la paie et à la conformité.',
        featureHeader: 'Services',
        featureParagraph:
            'Des fonctionnalités éprouvées sur le terrain boostent la productivité, minimisent la complexité et automatisent les tâches, économisant temps et ressources pour votre entreprise.',
        keyPoints: [
            {
                header: 'Suivi GPS du personnel sur le terrain en temps réel',
                paragraph:
                    'Informations en temps réel sur la présence ou l’absence d’individus dans la zone de géorepérage ou dans la branche',
            },
            {
                header: 'Formulaires personnalisés et photos géolocalisées',
                paragraph: 'Surveiller les heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: 'Tableau de bord intuitif et rapports téléchargeables',
                paragraph: 'Accéder aux rapports de présence de plusieurs branches à distance',
            },
            {
                header: 'Gérer et surveiller les collections',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
        ],
    },

    fieldOperations: {
        industryHeading:
            'Facilitez les décisions basées sur les données et améliorez la communication entre les équipes de terrain et le siège.',

        featureHeader: 'Opérations sur le terrain',
        featureParagraph:
            'Dites adieu aux opérations de terrain inefficaces et à la gestion des services avec la solution tout-en-un de Naavy. Optimisez la gestion des tâches, suivez la présence en déplacement et utilisez l’optimisation des itinéraires basée sur l’IA pour augmenter la productivité et les revenus de ventes.',
        keyPoints: [
            {
                header: 'Planifier et surveiller les tâches',
                paragraph:
                    'Informations en temps réel sur la présence ou l’absence d’individus dans la zone de géorepérage ou dans la branche',
            },
            {
                header: 'Suivre la localisation en temps réel de tous les employés de terrain',
                paragraph: 'Surveiller les heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: 'Valider et traiter les demandes de remboursement',
                paragraph: 'Accéder aux rapports de présence de plusieurs branches à distance',
            },
            {
                header: 'Définir des objectifs SLA et surveiller les retards',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Tableau de bord intuitif et rapports téléchargeables',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Validation des tâches par OTP',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'Facilitez les décisions basées sur les données et améliorez la communication entre les équipes de terrain et le siège.',

        featureHeader: 'Logistique et Transport',
        featureParagraph:
            'Simplifiez et organisez la logistique et la livraison de manière plus simple. Gérez les opérations logistiques d’une grande main-d’œuvre avec ses fonctionnalités robustes et avancées.',
        keyPoints: [
            {
                header: 'Obtenez un suivi en temps réel de la localisation des livreurs.',
                paragraph:
                    'Informations en temps réel sur la présence ou l’absence d’individus dans la zone de géorepérage ou dans la branche',
            },
            {
                header: 'Améliorez l’efficacité opérationnelle en surveillant les activités et les flux de travail.',
                paragraph: 'Surveiller les heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: 'Simplifiez la gestion des tâches de la main-d’œuvre.',
                paragraph: 'Accéder aux rapports de présence de plusieurs branches à distance',
            },
            {
                header: 'Analysez les données historiques et les informations sur le trafic en temps réel pour optimiser les itinéraires de livraison.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Traitement automatisé des remboursements et de la paie.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
            {
                header: 'Meilleur service client avec des travaux sur le terrain automatisés.',
                paragraph:
                    'Consolidation des données liées à la présence, des processus et des systèmes en une seule plateforme ou système centralisé',
            },
        ],
    },
};

export default industries;
