const products = {
    attendanceManagement: {
        productName: 'Manajemen Kehadiran',
        titleDescription: 'Pengaturan Mudah, Check In dan Check Out Otomatis, Laporan',
        productCards: [
            {
                title: 'Keseimbangan Kerja-Hidup',
                data: 'Perhatikan keseimbangan kerja-hidup karyawan Anda dengan pengingat Naavy tentang pekerjaan dan kehidupan pribadi.',
            },
            {
                title: 'Jenis Anggota',
                data: 'Sederhanakan manajemen ruang kerja dengan peran anggota seperti manajer eksekutif, manajer proyek, manajer tim, dan pemirsa proyek.',
            },
            {
                title: 'Membantu Melacak',
                data: 'Timesheet cerdas dari Naavy membuat manajemen tugas lebih sederhana dengan memungkinkan Anda melihat jam aktif, jam tidak aktif, dan pembagian jam produktif dalam gaya sheet yang terperinci.',
            },
        ],
        whyChooseSubCaption:
            'Bertransisi Menuju Lingkungan Kerja yang Sangat Efisien dan Rasakan Peningkatan Produktivitas dan Kolaborasi. Pusatkan data di kartu.',
        headerCaption: 'Mengapa Memilih Manajemen Kehadiran Naavy',
        headerT1: 'Fitur Pelacakan Kehadiran yang Lebih dari Sekadar Itu',
        subCaptionT1:
            'Rasakan pelacakan karyawan, pemantauan kerja jarak jauh, pemantauan produktivitas, manajemen kehadiran, timesheet, dan lainnya — semuanya dengan lapisan fungsionalitas canggih.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'Pemantauan Kehadiran',
                featureDescription:
                    'Informasi clock in/clock out secara real-time tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat mulai dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Informasi real-time tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang',
                    'Pantau jam kerja berdasarkan Harian, Mingguan dan Bulanan',
                    'Akses Laporan kehadiran dari Banyak Cabang secara remote',
                    'Konsolidasi data, proses, dan sistem terkait kehadiran ke dalam platform atau sistem terpusat',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Kehadiran Waktu Nyata',
                        paragraph:
                            'Informasi real-time tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'Perhitungan Total Jam Kerja',
                        paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan dan Bulanan',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Akses Jarak Jauh Laporan Kehadiran',
                        paragraph: 'Akses laporan kehadiran dari Banyak Cabang secara remote',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Pemantauan Kehadiran Satu',
                        description: [
                            'Dapatkan Transparansi Kedisiplinan Karyawan',
                            'Hemat waktu dan atur kehadiran karyawan tanpa tindakan tambahan.',
                            'Pantau dengan mudah apakah karyawan Anda telah tiba di tempat kerja tepat waktu dan menyelesaikan jam kerja mereka atau tidak.',
                            'Jaga agar operasi tempat kerja Anda lebih teratur dan memiliki lingkungan kerja yang sangat bertanggung jawab.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Pemantauan Kehadiran Dua',
                        description: [
                            'Analisis Cerdas Data Kehadiran',
                            'Filter keluar lebih awal, datang terlambat, dan pelacakan kurang yang dapat Anda pilih dari daftar dropdown.',
                            'Ini akan membantu Anda untuk segera menghasilkan informasi yang Anda inginkan tanpa harus mencarinya secara manual.',
                            'Anda dapat melacak kehadiran setiap hari atau memilih interval dari kalender.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Pemantauan Kehadiran Tiga',
                        description: [
                            'Cara yang Aman dan Transparan untuk Memantau dan Melacak Kehadiran Karyawan secara real-time dengan sistem tanpa sentuhan 100%.',
                            'Pengaturan Mudah',
                            'CHECK IN DAN OUT OTOMATIS LAPORAN TERPERINCI',
                            'SCREENSHOTS DENGAN WAKTU PELACAKAN',
                            'PENCATATAN TINGKAT AKTIVITAS',
                            'PEMANTAUAN APLIKASI DAN SITUS WEB',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'Manajemen Shift',
                featureDescription:
                    'Atur dan jadwalkan jam kerja yang mengoptimalkan produktivitas, memenuhi kebutuhan operasional, dan memastikan cakupan di berbagai periode waktu.',
                fIIPoints: [
                    'Kembangkan jadwal shift yang jelas dan teratur. Tentukan berbagai jenis shift berdasarkan sifat bisnis Anda.',
                    'Analisis beban kerja dan tugas untuk menentukan jumlah karyawan yang diperlukan selama shift yang berbeda',
                    'Platform terpusat untuk karyawan mengakses jadwal mereka, dan mendirikan saluran komunikasi yang andal untuk pembaruan atau perubahan.',
                    'Pengawasan dan kontrol terpusat terhadap jadwal karyawan, memungkinkan manajer untuk secara efisien mengalokasikan shift, melacak kehadiran, dan mengelola sumber daya tenaga kerja di berbagai lokasi dari satu platform.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'Manajemen Kehadiran Berdasarkan Geo Fence',
                featureDescription:
                    'Teknologi berbasis lokasi untuk memantau dan memverifikasi kehadiran karyawan dalam batas geografis yang telah ditentukan.',
                fIIPoints: [
                    'Catatan kehadiran yang akurat untuk memastikan bahwa karyawan hadir di lokasi kerja yang ditunjuk.',
                    'Terapkan pelacakan real-time untuk memantau lokasi karyawan dalam geo-fence.',
                    'Tentukan batas geografis tertentu, yang dikenal sebagai geo-fences, di sekitar tempat kerja atau lokasi kehadiran yang ditentukan.',
                    'Atur peringatan atau notifikasi untuk memberi tahu manajer atau personel HR saat karyawan masuk atau meninggalkan batas geo-fence yang ditentukan.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'Berbagai Pilihan Clock In',
                featureDescription:
                    'Memberikan fleksibilitas kepada karyawan untuk mencatat jam kerja mereka menggunakan pemindai biometrik, aplikasi seluler, jam waktu, dan platform berbasis web.',
                fIIPoints: [
                    'Cocok untuk Organisasi dengan Banyak Lokasi Pilihan clock in fleksibel untuk manajemen kehadiran di lapangan dan di luar lapangan dari berbagai cabang',
                    'Berikan akses kepada karyawan ke portal mandiri dari catatan kehadiran dan kelola check-in dan check-out mereka dalam geo-fence.',
                    'Pilihan clock-in yang fleksibel termasuk pemindaian biometrik, aplikasi seluler, kartu RFID, dan pengenalan wajah yang memastikan pelacakan waktu yang mulus di berbagai lingkungan kerja.',
                    'Penandaan kehadiran otomatis untuk karyawan dalam jangkauan area kantor dengan notifikasi peringatan.',
                ],
            },
            attendanceReport: {
                featureHeader: 'Laporan Kehadiran',
                featureDescription:
                    'Ringkas data kehadiran karyawan, jam kerja, ketidakhadiran, keterlambatan, dan kepergian awal dalam jangka waktu tertentu.',
                fIIPoints: [
                    'Berikan ringkasan keseluruhan kehadiran, termasuk jumlah hari kerja, total jam kerja, dan jam lembur.',
                    'Akses laporan kehadiran cabang banyak secara remote dengan kategori yang dapat disesuaikan.',
                    'Tampilkan saldo cuti untuk setiap karyawan, termasuk hari libur, cuti sakit, dan jenis cuti lainnya yang diperoleh dan digunakan.',
                    'Izinkan pengguna untuk menyesuaikan laporan berdasarkan kriteria tertentu, seperti departemen, tim, atau karyawan individu.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'Manajemen Tenaga Kerja Lapangan',
        titleDescription: 'Operasi Penjualan dan Layanan Lapangan dengan Pelaporan Langsung dan Rute Beat',
        productCards: [
            {
                title: 'Pengurangan Biaya Operasional',
                data: 'Nikmati pengurangan biaya operasional dengan mengotomatisasi jadwal karyawan. Lacak waktu, tugas, & pengeluaran karyawan untuk mengidentifikasi area di mana Anda dapat menghemat uang.',
            },
            {
                title: 'Nilai Pelanggan yang Hebat',
                data: 'Tetapkan jaminan jadwal dan layanan yang efisien. Lacak interaksi dan umpan balik pelanggan, untuk dengan mudah mengidentifikasi kekurangan dan area untuk perbaikan.',
            },
            {
                title: 'Analitik Kinerja',
                data: 'Hasilkan laporan komprehensif dengan mudah di dasbor yang terpadu, memberikan wawasan berharga tentang kinerja tenaga kerja Anda, jadwal pekerjaan, kepuasan pelanggan & area untuk perbaikan.',
            },
        ],
        whyChooseSubCaption:
            'Puncak-Efisiensi Dengan Perangkat Lunak Manajemen Tenaga Kerja Lapangan\nSub caption - Lacak karyawan lapangan Anda secara real-time, pantau kemajuan tugas, dan dapatkan wawasan tentang lokasi, aktivitas, dan kinerja mereka. Tingkatkan akuntabilitas dan manfaatkan pengambilan keputusan berbasis data.',
        headerCaption: 'Mengapa Memilih Manajemen Tenaga Kerja Lapangan Naavy',
        headerT1: 'Sorotan Sistem Manajemen Tenaga Kerja Lapangan',
        subCaptionT1:
            'Manfaatkan pelacakan lokasi karyawan secara real-time yang dikombinasikan dengan optimisasi berbasis AI untuk menyederhanakan operasi, pelaporan langsung untuk wawasan segera, dan gunakan analitik penjualan lapangan untuk meningkatkan kinerja. Pastikan alokasi sumber daya yang efisien, produktivitas yang lebih baik, dan pengambilan keputusan berbasis data.',

        features: {
            workforceTracking: {
                featureHeader: 'Pelacakan Tenaga Kerja',
                featureDescription:
                    'Pemantauan sistematis dan manajemen aktivitas, produktivitas, dan kehadiran karyawan.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat mulai dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Pantau Acara seperti Clock in dan Clock Out serta wawasan Karyawan ke dalam operasi, metrik kinerja, atau acara mereka.',
                    'Simpan Data untuk Rapat dan tugas untuk Melacak Tren dan Pengambilan keputusan berbasis data untuk Notulen Rapat',
                    'Manajemen Pelanggan dan Prospek untuk melacak Produktivitas Karyawan dan indikator kinerja utama dapat diukur berdasarkan jumlah pekerjaan yang diselesaikan oleh Karyawan',
                    'Lacak jarak yang akurat yang ditempuh karyawan untuk pekerjaan lapangan berdasarkan harian, mingguan, dan bulanan serta reimburse pengeluaran sesuai dengan jarak dan lokasi yang ditempuh.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Pelacakan Kehadiran Berbasis Waktu',
                        paragraph:
                            'Pantau Kehadiran dan Pergerakan Acara seperti Clock in dan Clock Out serta wawasan Karyawan ke dalam operasi, metrik kinerja, atau acara mereka.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulir Kustom untuk Manajemen Data',
                        paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Akses Jarak Jauh Laporan Kehadiran',
                        paragraph: 'Akses Laporan Kehadiran dari Banyak Cabang secara remote',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Pemantauan Kehadiran Satu',
                        description: [
                            'Dapatkan Transparansi Kedisiplinan Karyawan',
                            'Hemat waktu dan atur kehadiran karyawan tanpa tindakan tambahan.',
                            'Pantau dengan mudah apakah karyawan Anda telah tiba di tempat kerja tepat waktu dan menyelesaikan jam kerja mereka atau tidak.',
                            'Jaga agar operasi tempat kerja Anda lebih teratur dan memiliki lingkungan kerja yang sangat bertanggung jawab.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Pemantauan Kehadiran Dua',
                        description: [
                            'Analisis Cerdas Data Kehadiran',
                            'Filter keluar lebih awal, datang terlambat, dan pelacakan kurang yang dapat Anda pilih dari daftar dropdown.',
                            'Ini akan membantu Anda untuk segera menghasilkan informasi yang Anda inginkan tanpa harus mencarinya secara manual.',
                            'Anda dapat melacak kehadiran setiap hari atau memilih interval dari kalender.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'Pemantauan Kehadiran Tiga',
                        description: [
                            'Cara yang Aman dan Transparan untuk Memantau dan Melacak Kehadiran Karyawan secara real-time dengan sistem tanpa sentuhan 100%.',
                            'Pengaturan Mudah',
                            'CHECK IN DAN OUT OTOMATIS LAPORAN TERPERINCI',
                            'SCREENSHOTS DENGAN WAKTU PELACAKAN',
                            'PENCATATAN TINGKAT AKTIVITAS',
                            'PEMANTAUAN APLIKASI DAN SITUS WEB',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'Analitik Penjualan Lapangan',
                featureDescription:
                    'Lacak dan analisis metrik kunci tim penjualan di lapangan seperti pendapatan penjualan, interaksi pelanggan, dan tren geografis.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat mulai dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Indikator Kinerja Utama dapat membantu dalam membuat keputusan berbasis data yang dapat membantu dalam membuat keputusan produktif untuk lain kali atau merombak strategi.',
                    'Laporan dapat diakses secara harian, mingguan, atau bulanan tentang Penjualan, Prospek, dan Akuisisi Pelanggan yang dapat membantu dalam produktivitas dan efisiensi individu.',
                    'Validasi kunjungan dengan check-in berbasis Geofence untuk akurasi. Koordinat lokasi dapat dilampirkan pada foto kamera saat membagikannya.',
                    'Rute Beat dapat digunakan untuk merencanakan kunjungan atau rencana beat untuk karyawan ke kunjungan klien. Integrasikan rencana beat Anda dalam Peta langsung untuk mengoptimalkan navigasi.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'Kehadiran Berbasis Lokasi',
                featureDescription:
                    'Kehadiran berbasis geo fence untuk memastikan bahwa karyawan hanya dapat clock in atau out ketika mereka berada dalam batas geografis yang telah ditentukan.',
                fIIPoints: [
                    'Terapkan batas virtual berbasis geo di sekitar lokasi tertentu dari tempat kerja. Membantu dalam mengotomatisasi kehadiran berdasarkan karyawan yang memasuki atau meninggalkan area yang ditentukan.',
                    'Metode autentikasi untuk memastikan akurasi dan keamanan data kehadiran menggunakan PIN, biometrik, atau kehadiran wajah.',
                    'Pastikan catatan kehadiran diperbarui tepat waktu, memungkinkan keputusan manajerial yang tepat waktu.',
                    'Pertimbangkan metode alternatif untuk pelacakan kehadiran untuk karyawan jarak jauh.',
                ],
            },
            liveReporting: {
                featureHeader: 'Pelaporan Langsung',
                featureDescription:
                    'Pelacakan dan analisis real-time berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan atau kemajuan proyek.',
                fIIPoints: [
                    'Admin atau Manajer dapat berlangganan atau memilih notifikasi yang ingin mereka terima. Ini akan membantu fokus pada notifikasi yang benar-benar ingin diterima.',
                    'Tetap Diberitahu tentang Kehadiran dan Cuti, tetap selangkah lebih maju dan ketahui jumlah karyawan sebelumnya sehingga perencanaan hari bisa lebih produktif dan efisien.',
                    'Lacak Semua Persetujuan, Lacak semua persetujuan dari reimbursements hingga cuti dan lihat nanti.',
                    'Pelacakan Jarak Jauh melalui Formulir dan Notulen Rapat, formulir kustom dapat membantu dalam mengambil data spesifik untuk rapat yang dapat diakses secara jarak jauh.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'Optimisasi Berbasis AI',
                featureDescription:
                    'Optimalkan dan otomatisasikan perencanaan rute untuk perwakilan lapangan atau personel penjualan berdasarkan rute beat berbasis AI.',
                fIIPoints: [
                    'Sumber data real-time, seperti pembaruan lalu lintas langsung, kondisi cuaca, dan penutupan jalan, untuk menyesuaikan rute secara dinamis berdasarkan keadaan saat ini, membantu dalam perencanaan harian yang efisien.',
                    'Lihat rute yang dioptimalkan, dan buat penyesuaian yang diperlukan. Ini bisa menjadi bagian dari aplikasi seluler atau platform berbasis web.',
                    'Sistem AI untuk mempertimbangkan karakteristik unik dari beat atau rute yang berbeda.',
                    'Manfaatkan layanan pemetaan dan data spasial untuk memahami jaringan jalan, kondisi lalu lintas, dan informasi terkait lokasi lainnya.',
                ],
            },
            taskManagement: {
                featureHeader: 'Manajemen Tugas',
                featureDescription:
                    'Mengorganisir, memprioritaskan, dan mengeksekusi aktivitas secara efisien untuk mencapai tujuan atau sasaran tertentu.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat mulai dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Pantau kemajuan secara real-time dengan melihat papan tugas, memeriksa status tugas, dan melacak waktu yang dihabiskan.',
                    'Fitur kolaborasi manajemen tugas untuk berkomunikasi dengan anggota tim secara real-time. Komentari tugas, ajukan pertanyaan, dan berikan pembaruan untuk menjaga semua orang tetap terinformasi.',
                    'Manajemen Pelanggan dan Prospek untuk melacak Produktivitas Karyawan dan indikator kinerja utama dapat diukur berdasarkan jumlah pekerjaan yang diselesaikan oleh Karyawan.',
                    'Struktur hierarkis untuk mengorganisir tujuan. Tujuan proyek, tujuan departemen, tujuan kinerja individu, inisiatif strategis, dll.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'Manajemen Penggajian dan Cuti',
        titleDescription: 'Optimalkan pemrosesan penggajian dan administrasi cuti',
        headerCaption: 'Mengapa Memilih Naavy Payroll and Leave Management',
        productCards: [
            {
                title: 'Kemudahan Manajemen',
                data: 'Sederhanakan manajemen dengan memusatkan informasi karyawan, mengotomatisasi tugas rutin, dan menyederhanakan komunikasi.',
            },
            {
                title: 'Kustomisasi',
                data: 'Kustomisasi untuk memenuhi kebutuhan spesifik organisasi Anda.',
            },
            {
                title: 'Integrasi',
                data: 'Aktifkan integrasi untuk berbagi data, otomatisasi tugas, dan kolaborasi yang lebih baik di seluruh tim, yang mengarah pada implementasi penggajian dan manajemen cuti yang lebih efisien dan efektif.',
            },
        ],
        whyChooseSubCaption:
            'Lacak dan kelola penggajian serta cuti dengan efisien, memastikan tidak ada kesempatan yang hilang dan meningkatkan akurasi administrasi.',
        headerT1: 'Manfaat Sistem Manajemen Penggajian dan Cuti Naavy',
        subCaptionT1:
            'Otomatisasi Proses Penggajian dan Cuti\nIntegrasi Data dan Laporan Real-time\nOtomatisasi Tugas Penggajian dan Administrasi',

        features: {
            payrollProcessing: {
                featureHeader: 'Pemrosesan Penggajian',
                featureDescription:
                    'Tindak lanjut tepat waktu, interaksi personal, dan akurasi tinggi dalam penggajian.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan kehadiran dan penggajian Anda dengan sistem kami dan lihat jam kerja karyawan dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Permudah proses penggajian berdasarkan waktu dan lokasi, sehingga memudahkan pencapaian tujuan dalam waktu yang lebih singkat.',
                    'Gunakan riwayat kontak untuk membuat segmen berdasarkan data seperti kunjungan situs web, interaksi email, dan lainnya. Segmentasikan karyawan berdasarkan data ini untuk manajemen yang lebih efektif.',
                    'Gunakan skor karyawan untuk menentukan prioritas dalam penggajian atau mengatur alur kerja untuk pemberitahuan ketika data karyawan siap untuk verifikasi.',
                    'Akses dengan mudah riwayat setiap karyawan, termasuk informasi perusahaan dan garis waktu setiap titik kontak terkait penggajian.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Pelacakan Kehadiran Berbasis Waktu',
                        paragraph:
                            'Pantau kehadiran dan pergerakan seperti Clock in dan Clock Out serta wawasan karyawan ke dalam operasi, metrik kinerja, atau acara mereka.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulir Kustom untuk Manajemen Data',
                        paragraph: 'Pantau jam kerja berdasarkan harian, mingguan, dan bulanan.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Akses Jarak Jauh Laporan Kehadiran',
                        paragraph: 'Akses laporan kehadiran dari berbagai cabang secara remote.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Pemantauan Kehadiran Satu',
                        description: [
                            'Dapatkan Transparansi Kedisiplinan Karyawan',
                            'Hemat waktu dan atur kehadiran karyawan tanpa tindakan tambahan.',
                            'Pantau dengan mudah apakah karyawan Anda telah tiba di tempat kerja tepat waktu dan menyelesaikan jam kerja mereka.',
                            'Jaga agar operasi tempat kerja Anda lebih teratur dan memiliki lingkungan kerja yang sangat bertanggung jawab.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Pemantauan Kehadiran Dua',
                        description: [
                            'Analisis Cerdas Data Kehadiran',
                            'Filter untuk keluar lebih awal, datang terlambat, dan pelacakan kurang yang dapat dipilih dari daftar dropdown.',
                            'Bantu Anda menghasilkan informasi yang diinginkan tanpa pencarian manual.',
                            'Pantau kehadiran harian atau pilih interval dari kalender.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Pemantauan Kehadiran Tiga',
                        description: [
                            'Cara yang Aman dan Transparan untuk Memantau Kehadiran Karyawan secara real-time dengan sistem tanpa sentuhan 100%.',
                            'Pengaturan Mudah',
                            'CHECK IN DAN OUT OTOMATIS LAPORAN TERPERINCI',
                            'SCREENSHOTS DENGAN WAKTU PELACAKAN',
                            'PENCATATAN TINGKAT AKTIVITAS',
                            'PEMANTAUAN APLIKASI DAN SITUS WEB',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'Manajemen Cuti',
                featureDescription:
                    'Menangani dan melacak cuti dengan efisien, memastikan dokumentasi yang tepat, kepatuhan terhadap kebijakan perusahaan, dan menjaga kelancaran alur kerja.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan cuti dengan sistem kami dan lihat detail cuti karyawan dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Kelola akumulasi cuti, memastikan karyawan tidak melebihi jumlah yang diberikan, dan mencegah saldo cuti negatif.',
                    'Konfigurasikan kebijakan cuti fleksibel organisasi dengan mengatur Kalender Cuti. Aktifkan Pengaturan Hirarki Paralel atau Berurutan untuk Persetujuan atau Penolakan Permintaan Cuti.',
                    'Tetap diberi informasi tentang undang-undang dan peraturan tenaga kerja terkait hak cuti. Pastikan bahwa kebijakan manajemen cuti Anda sesuai dengan kebijakan perusahaan.',
                    'Berikan opsi layanan mandiri kepada karyawan, memungkinkan mereka untuk memeriksa saldo cuti mereka, mengajukan permintaan, dan melihat status permintaan mereka.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'Manajemen Pesanan',
        titleDescription: 'Sederhanakan operasi pesanan/koleksi dengan solusi yang skalabel dan otomatis.',
        headerCaption: 'Mengapa Memilih Naavy Order Management',
        productCards: [
            {
                title: 'Kemudahan Manajemen',
                data: 'Sederhanakan manajemen dengan memusatkan informasi, mengotomatisasi tugas rutin, dan menyederhanakan komunikasi.',
            },
            {
                title: 'Kustomisasi',
                data: 'Kustomisasi untuk memenuhi kebutuhan spesifik organisasi Anda.',
            },
            {
                title: 'Integrasi',
                data: 'Aktifkan integrasi untuk berbagi data, otomatisasi tugas, dan kolaborasi yang lebih baik di seluruh tim, yang mengarah pada implementasi manajemen pesanan yang lebih efisien dan efektif.',
            },
        ],
        whyChooseSubCaption:
            'Lacak dan kelola pesanan serta koleksi dengan efisien, memastikan tidak ada kesempatan yang hilang dan meningkatkan akurasi proses.',
        headerT1: 'Manfaat Sistem Manajemen Pesanan Naavy',
        subCaptionT1:
            'Otomatisasi Proses Pesanan dan Koleksi\nIntegrasi Data dan Laporan Real-time\nOtomatisasi Tugas Pesanan dan Koleksi',
        features: {
            digitizationOfOrder: {
                featureHeader: 'Digitalisasi Pesanan',
                featureDescription: 'Permudah manajemen pesanan dari awal hingga akhir dan kurangi kesalahan manual.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan pesanan Anda dengan sistem kami dan lihat detail pesanan dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Hasilkan laporan dan analitik terperinci untuk memantau kinerja pemrosesan pesanan dan produktivitas karyawan.',
                    'Tangkap dan validasi detail pesanan yang dimasukkan oleh karyawan atau pelanggan secara otomatis.',
                    'Berikan pembaruan real-time tentang status pesanan dan pelacakan pengiriman kepada karyawan dan pelanggan.',
                    'Otomatiskan proses persetujuan pesanan dan tugaskan tugas kepada karyawan yang sesuai.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Pelacakan Kehadiran Berbasis Waktu',
                        paragraph:
                            'Pantau kehadiran dan pergerakan seperti Clock in dan Clock Out serta wawasan karyawan ke dalam operasi, metrik kinerja, atau acara mereka.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulir Kustom untuk Manajemen Data',
                        paragraph: 'Pantau jam kerja berdasarkan harian, mingguan, dan bulanan.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Akses Jarak Jauh Laporan Kehadiran',
                        paragraph: 'Akses laporan kehadiran dari berbagai cabang secara remote.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Pemantauan Kehadiran Satu',
                        description: [
                            'Dapatkan Transparansi Kedisiplinan Karyawan',
                            'Hemat waktu dan atur kehadiran karyawan tanpa tindakan tambahan.',
                            'Pantau dengan mudah apakah karyawan Anda telah tiba di tempat kerja tepat waktu dan menyelesaikan jam kerja mereka.',
                            'Jaga agar operasi tempat kerja Anda lebih teratur dan memiliki lingkungan kerja yang sangat bertanggung jawab.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Pemantauan Kehadiran Dua',
                        description: [
                            'Analisis Cerdas Data Kehadiran',
                            'Filter untuk keluar lebih awal, datang terlambat, dan pelacakan kurang yang dapat dipilih dari daftar dropdown.',
                            'Bantu Anda menghasilkan informasi yang diinginkan tanpa pencarian manual.',
                            'Pantau kehadiran harian atau pilih interval dari kalender.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Pemantauan Kehadiran Tiga',
                        description: [
                            'Cara yang Aman dan Transparan untuk Memantau Kehadiran Karyawan secara real-time dengan sistem tanpa sentuhan 100%.',
                            'Pengaturan Mudah',
                            'Check-In dan Check-Out Otomatis, Laporan Terperinci',
                            'Screenshots dengan Waktu Pelacakan',
                            'Pencatatan Tingkat Aktivitas',
                            'Pemantauan Aplikasi dan Situs Web',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'Manajemen Target',
                featureDescription:
                    'Tetapkan target yang jelas dan dapat dicapai untuk setiap karyawan berdasarkan peran dan kemampuannya.',
                featureParagraph:
                    'Tidak ada lagi kartu waktu, pekerjaan kertas yang berlebihan, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasikan proses pemantauan target dengan sistem kami dan lihat hasil kinerja karyawan dalam format yang terstruktur dengan baik.',
                fIIPoints: [
                    'Capai target harian, mingguan, bulanan, dan simpan catatan pencapaian karyawan dengan pelacakan lapangan langsung.',
                    'Kemajuan Real-Time (jumlah target yang tercapai vs. yang tersisa), Waktu Check-In/Check-Out, Jumlah jam (durasi) yang dihabiskan untuk setiap tugas, dan pelacakan lokasi.',
                    'Metrik Kinerja (tingkat penyelesaian, waktu yang diambil, skor kepuasan pelanggan), Perbandingan terhadap Target (kinerja aktual vs. target), Data Historis (tren kinerja masa lalu).',
                    'Kirim notifikasi otomatis untuk menjaga karyawan tetap terinformasi tentang Peringatan Pencapaian Target, Pengingat Kinerja, Peringatan Tenggat Waktu.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'Manajemen Faktur',
                featureDescription:
                    'Buat, lacak status faktur secara real-time dan proses dengan peringatan dan notifikasi otomatis.',
                fIIPoints: [
                    'Siapkan sistem untuk melacak status faktur dan mengirim pengingat otomatis serta peringatan untuk pembayaran yang jatuh tempo dan terlambat.',
                    'Template faktur standar yang mencakup semua detail penting seperti informasi pelanggan, detail pesanan, pajak, diskon, dan syarat pembayaran.',
                    'Kumpulkan tanda tangan digital dan bukti pengiriman berbasis OTP sebagai bagian dari proses faktur.',
                    'Sinkronisasi Otomatis dengan Sistem Pusat (penyimpanan berbasis cloud) dan Pembaruan Real-Time pada Status Faktur.',
                ],
            },
            distributorOrder: {
                featureHeader: 'Pesanan Distribusi',
                featureDescription:
                    'Kelola produk/ koleksi dan tangani prioritas tugas dengan pembaruan real-time pada inventaris.',
                fIIPoints: [
                    'Buat dan proses pesanan dengan semua detail relevan untuk setiap distribusi dan tugaskan tugas berdasarkan ketersediaan.',
                    'Integrasikan dengan manajemen inventaris untuk memastikan alokasi stok yang akurat dan perbarui level inventaris secara real-time.',
                    'Konfigurasikan sistem untuk mengirim notifikasi otomatis pada tahap kunci proses pesanan.',
                    'Akses dasbor tempat karyawan dapat memeriksa pesanan, melacak kemajuan, dan mengunduh dokumen yang diperlukan.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'Manajemen Inventaris',
                featureDescription:
                    'Menjaga keseimbangan stok yang tepat dengan pembaruan real-time dan pelacakan inventaris untuk data yang akurat.',
                fIIPoints: [
                    'Pastikan integrasi yang mulus antara sistem inventaris dan manajemen pesanan untuk menyelaraskan level stok dengan pesanan masuk dan keluar.',
                    'Pantau inventaris di berbagai gudang, toko, atau lokasi dan akses kontrol terpusat, optimalkan distribusi stok dan tingkatkan visibilitas.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'Manajemen Pesanan / Koleksi',
                featureDescription: 'Sederhanakan operasi pesanan/ koleksi dengan solusi yang skalabel dan otomatis.',
                fIIPoints: [
                    'Sistem terpusat yang dapat diakses oleh karyawan di lokasi dan di luar lokasi untuk mengelola pesanan, melacak kemajuan, dan memperbarui status secara real-time.',
                    'Berbagai level akses dan izin berdasarkan peran (misalnya, manajer, karyawan di lokasi, karyawan di luar lokasi) untuk memastikan keamanan data dan akses yang sesuai.',
                    'Pastikan bahwa semua data pesanan tersinkronisasi secara real-time di semua perangkat dan lokasi, sehingga semua orang memiliki akses ke informasi terbaru.',
                    'Fasilitasi komunikasi antara karyawan di lokasi dan di luar lokasi melalui pesan dalam aplikasi dan notifikasi.',
                ],
            },
        },
    },
};
export default products;
