const industries = {
    pharmaceutical: {
        industryHeading:
            'सुरक्षा प्रोटोकॉल की निगरानी, ​​समय और उपस्थिति ट्रैकिंग, और प्रभावी परियोजना प्रबंधन, साइट से बाहर की गतिविधियों और प्रदर्शन मूल्यांकन की सुविधा के साथ संवेदनशील क्षेत्रों तक पहुंच पर नियंत्रण।',

        featureHeader: 'फार्मास्युटिकल्स',
        featureParagraph:
            'फार्मास्युटिकल और स्वास्थ्य सेवा कंपनियों के लिए कर्मचारी प्रबंधन को सरल बनाने के लिए उपस्थिति प्रबंधन और फील्ड ट्रैकिंग सॉफ़्टवेयर, जिसमें समय ट्रैकिंग, फील्ड कर्मचारियों के लिए मोबाइल पहुंच, फील्ड संचालन अनुपालन रिपोर्टिंग, और बिक्री उत्पन्न करने और उत्पादकता बढ़ाने के लिए विभिन्न प्रणालियों के साथ एकीकरण जैसी विशेषताएं हैं।',
        keyPoints: [
            {
                header: 'कर्मचारी ट्रैकिंग सिस्टम को कर्मचारी रिकॉर्ड, वेतन, और अनुपालन रिपोर्टिंग से संबंधित एचआर सिस्टम के साथ एकीकृत करें।',
                paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर काम के घंटे मॉनिटर करें',
            },
            {
                header: 'शिफ्ट प्रबंधन प्रणाली का उपयोग करके कर्मचारियों के शेड्यूल को अनुकूलित करें',
                paragraph: 'दूरस्थ रूप से कई शाखाओं की उपस्थिति रिपोर्ट तक पहुंचें',
            },
            {
                header: 'उत्पादन क्षेत्रों की निगरानी करना, जहां निरंतर संचालन महत्वपूर्ण है।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
            {
                header: 'सुनिश्चित करें कि सभी कर्मचारी ट्रैकिंग सिस्टम प्रासंगिक नियमों और मानकों के अनुरूप हैं।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
        ],
    },
    education: {
        industryHeading: 'एक व्यापक प्रवेश CRM छात्रों के सहयोग और प्रॉस्पेक्ट प्रबंधन के लिए।',

        featureHeader: 'शिक्षा',
        featureParagraph:
            'छात्र भर्ती और प्रवेश \n छात्र सगाई और प्रतिधारण \n कई स्थानों पर प्रवेश प्रबंधन \n व्यक्तिगत छात्र समर्थन',
        keyPoints: [
            {
                header: 'पहले संपर्क बिंदु से आवेदन प्रक्रिया तक संभावित छात्रों को ट्रैक और प्रबंधित करें।',
                paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर काम के घंटे मॉनिटर करें',
            },
            {
                header: 'उन संभावित छात्रों की पहचान और प्रबंधन करें जो दाखिला लेने में रुचि दिखाते हैं। संचार और प्रगति को ट्रैक करें ताकि पूछताछ को दाखिला लेने वाले छात्रों में बदला जा सके।',
                paragraph: 'दूरस्थ रूप से कई शाखाओं की उपस्थिति रिपोर्ट तक पहुंचें',
            },
            {
                header: 'छात्र नामांकन प्रक्रिया के विभिन्न चरणों को देखें और प्रबंधित करें। आवेदन जैसे चरणों के माध्यम से आवेदकों को स्थानांतरित करें, साक्षात्कार, स्वीकृति, और नामांकन।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
            {
                header: 'कस्टमाइज़ेबल ऑनलाइन फ़ॉर्म के साथ आवेदन प्रक्रिया को सरल बनाएं। संभावित छात्रों से आवश्यक जानकारी कुशलतापूर्वक एकत्र करें।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'समय पर ग्राहक सहभागिता और प्रभावी मार्ग योजना सुनिश्चित करके, चलती बिक्री टीमों के प्रदर्शन को अनुकूलित करें।',

        featureHeader: 'फील्ड बिक्री',
        featureParagraph:
            'विकास को तेज करें और अपने फील्ड बल को सही उपकरणों के साथ योजना बनाने, परिवर्तित करने और निष्पादित करने के लिए सशक्त करें। Naavy फील्ड बिक्री ट्रैकिंग और लीड और सीआरएम प्रबंधन को सरल बनाता है।',
        keyPoints: [
            {
                header: 'वास्तविक समय की उपस्थिति',
                paragraph:
                    'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति की वास्तविक समय की जानकारी',
            },
            {
                header: 'कुल कार्य घंटे की गणना',
                paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर काम के घंटे मॉनिटर करें',
            },
            {
                header: 'दूरस्थ पहुंच उपस्थिति रिपोर्ट',
                paragraph: 'दूरस्थ रूप से कई शाखाओं की उपस्थिति रिपोर्ट तक पहुंचें',
            },
            {
                header: 'केंद्रीकृत उपस्थिति प्रबंधन',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
        ],
    },
    fmcg: {
        featureHeader: 'एफएमसीजी',
        industryHeading:
            'प्रदर्शन मेट्रिक्स, व्यय प्रबंधन और बिक्री बल प्रबंधन के लिए अनुपालन ट्रैकिंग, संचार और सहयोग सुविधाओं के साथ ग्राहक जुड़ाव को बढ़ाना।',
        featureParagraph:
            'वास्तविक समय की उपस्थिति ट्रैकिंग बाजार की मांग के आधार पर स्टाफिंग स्तरों में त्वरित समायोजन को सक्षम बनाती है, जिससे एफएमसीजी संचालन की चुस्ती बढ़ती है।',
        keyPoints: [
            {
                header: 'सटीक उपस्थिति ट्रैकिंग सहायता',
                paragraph:
                    'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति की वास्तविक समय की जानकारी',
            },
            {
                header: 'सटीक वेतन प्रसंस्करण, त्रुटियों को कम करना और वित्तीय विसंगतियों को कम करना।',
                paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर काम के घंटे मॉनिटर करें',
            },
            {
                header: 'श्रम कानूनों और नियमों का अनुपालन सुनिश्चित करना।',
                paragraph: 'दूरस्थ रूप से कई शाखाओं की उपस्थिति रिपोर्ट तक पहुंचें',
            },
            {
                header: 'प्रभावी छुट्टी प्रबंधन को सक्षम बनाना, कर्मचारियों की कमी को रोकना।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
            {
                header: 'निरंतर उत्पादन और वितरण सुनिश्चित करना।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
            {
                header: 'सटीक प्रतिपूर्ति प्रक्रियाओं को सुनिश्चित करना।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
            {
                header: 'ऑर्डर ट्रैकिंग और पूर्णता।',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
        ],
    },
    services: {
        industryHeading:
            'परियोजना और कार्य प्रबंधन को सरल बनाएं, संसाधन आवंटन को अनुकूलित करें और वेतन प्रसंस्करण और अनुपालन में सहायता के लिए सटीक समय और उपस्थिति रिकॉर्ड सुनिश्चित करें।',
        featureHeader: 'सेवाएँ',
        featureParagraph:
            'फील्ड-टेस्टेड सुविधाएं उत्पादकता को बढ़ाती हैं, जटिलता को कम करती हैं, और कार्यों को स्वचालित करती हैं, जिससे आपके व्यवसाय के लिए समय और संसाधन की बचत होती है।',
        keyPoints: [
            {
                header: 'वास्तविक समय में फील्ड स्टाफ जीपीएस ट्रैकिंग',
                paragraph:
                    'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति की वास्तविक समय की जानकारी',
            },
            {
                header: 'कस्टम फॉर्म और जियो-टैग की गई फ़ोटो',
                paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर काम के घंटे मॉनिटर करें',
            },
            {
                header: 'इंट्यूटिव डैशबोर्ड और डाउनलोड करने योग्य रिपोर्ट',
                paragraph: 'दूरस्थ रूप से कई शाखाओं की उपस्थिति रिपोर्ट तक पहुंचें',
            },
            {
                header: 'संग्रहों को प्रबंधित और मॉनिटर करें',
                paragraph:
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और सिस्टम को एकल, केंद्रीकृत प्लेटफ़ॉर्म या सिस्टम में समेकित करना',
            },
        ],
    },
    fieldOperations: {
        industryHeading: 'डेटा-संचालित निर्णयों को सुविधाजनक बनाएं और फील्ड टीमों और मुख्यालय के बीच संचार को बढ़ाएं।',

        featureHeader: 'फील्ड संचालन',
        featureParagraph:
            'अप्रभावी फील्ड संचालन और सेवा प्रबंधन को अलविदा कहें। ट्रेसफोर्स की ऑल-इन-वन सॉल्यूशन से कार्य प्रबंधन को अनुकूलित करें, चलते-फिरते उपस्थिति ट्रैक करें, और उत्पादकता और बिक्री राजस्व बढ़ाने के लिए एआई आधारित रूट ऑप्टिमाइजेशन का उपयोग करें।',
        keyPoints: [
            {
                header: 'कार्य अनुसूची और निगरानी करें',
                paragraph:
                    'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति के बारे में वास्तविक समय की जानकारी।',
            },
            {
                header: 'सभी फील्ड कर्मचारियों का वास्तविक समय स्थान ट्रैक करें',
                paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर काम के घंटे की निगरानी करें।',
            },
            {
                header: 'खर्च दावों को सत्यापित और संसाधित करें',
                paragraph: 'विभिन्न शाखाओं की उपस्थिति रिपोर्ट को दूरस्थ रूप से एक्सेस करें।',
            },
            {
                header: 'एसएलए लक्ष्यों को सेट करें और देरी की निगरानी करें',
                paragraph:
                    'उपस्थिति-संबंधित डेटा, प्रक्रियाओं, और प्रणालियों को एक केंद्रीकृत प्लेटफॉर्म में समेकित करें।',
            },
            {
                header: 'इंट्यूटिव डैशबोर्ड और डाउनलोडेबल रिपोर्ट्स',
                paragraph:
                    'उपस्थिति-संबंधित डेटा, प्रक्रियाओं, और प्रणालियों को एक केंद्रीकृत प्लेटफॉर्म में समेकित करें।',
            },
            {
                header: 'ओटीपी सत्यापित कार्य पूर्णता',
                paragraph:
                    'उपस्थिति-संबंधित डेटा, प्रक्रियाओं, और प्रणालियों को एक केंद्रीकृत प्लेटफॉर्म में समेकित करें।',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading: 'डेटा संचालित निर्णयों को सक्षम करें और फील्ड टीमों और मुख्यालय के बीच संचार को बढ़ावा दें।',
        featureHeader: 'लॉजिस्टिक्स और परिवहन',
        featureParagraph:
            'लॉजिस्टिक्स और डिलीवरी को सरल और सुव्यवस्थित करें। बड़ी कार्यबल की लॉजिस्टिक्स संचालन को इसके मजबूत और उन्नत फीचर्स के साथ प्रबंधित करें।',
        keyPoints: [
            {
                header: 'डिलीवरी कर्मियों की स्थान की वास्तविक समय में ट्रैकिंग प्राप्त करें।',
                paragraph:
                    'व्यक्ति की उपस्थिति या अनुपस्थिति के बारे में वास्तविक समय की जानकारी जियोफेंस क्षेत्र या शाखा के भीतर',
            },
            {
                header: 'गतिविधियों और कार्यप्रवाह की निगरानी करके परिचालन दक्षता में सुधार करें।',
                paragraph: 'दैनिक, साप्ताहिक और मासिक के आधार पर कार्य समय की निगरानी करें',
            },
            {
                header: 'कार्यबल कार्य प्रबंधन को सरल बनाएं।',
                paragraph: 'दूरस्थ रूप से कई शाखाओं की उपस्थिति रिपोर्टों तक पहुँचें',
            },
            {
                header: 'डिलीवरी मार्गों को अनुकूलित करने के लिए ऐतिहासिक डेटा और वास्तविक समय ट्रैफ़िक जानकारी का विश्लेषण करें।',
                paragraph:
                    'उपस्थिति-संबंधित डेटा, प्रक्रियाओं और प्रणालियों का एकल, केंद्रीकृत मंच या प्रणाली में एकीकरण',
            },
            {
                header: 'स्वचालित प्रतिपूर्ति और पेरोल प्रोसेसिंग।',
                paragraph:
                    'उपस्थिति-संबंधित डेटा, प्रक्रियाओं और प्रणालियों का एकल, केंद्रीकृत मंच या प्रणाली में एकीकरण',
            },
            {
                header: 'स्वचालित फील्ड वर्क्स के साथ बेहतर ग्राहक सेवा।',
                paragraph:
                    'उपस्थिति-संबंधित डेटा, प्रक्रियाओं और प्रणालियों का एकल, केंद्रीकृत मंच या प्रणाली में एकीकरण',
            },
        ],
    },
    construction: {
        industryHeading:
            'उन्नत निगरानी और रिपोर्टिंग टूल्स के साथ साइट उपस्थिति, परियोजना समयसीमा, और कार्यबल ट्रैकिंग को कुशलतापूर्वक प्रबंधित करें।',
        featureHeader: 'निर्माण',
        featureParagraph:
            'वास्तविक समय उपस्थिति ट्रैकिंग, शिफ्ट प्रबंधन, और निर्माण उद्योग के लिए अनुकूलित अनुपालन रिपोर्टिंग के साथ कार्यबल आवंटन और परियोजना प्रबंधन को अनुकूलित करें।',
        keyPoints: [
            {
                header: 'कई साइटों पर वास्तविक समय कार्यबल उपस्थिति निगरानी।',
                paragraph: 'विभिन्न निर्माण स्थलों पर उपस्थिति को ट्रैक करें और विस्तृत रिपोर्ट उत्पन्न करें।',
            },
            {
                header: 'शिफ्ट प्रबंधन और ओवरटाइम ट्रैकिंग।',
                paragraph: 'कर्मचारी शिफ्ट्स को प्रबंधित करें और कई साइटों पर ओवरटाइम को सटीक रूप से ट्रैक करें।',
            },
            {
                header: 'सुरक्षा नियमों और श्रम कानूनों का अनुपालन सुनिश्चित करें।',
                paragraph:
                    'सुरक्षा प्रोटोकॉल और नियामक रिपोर्टिंग को सुव्यवस्थित करने के लिए अनुपालन डेटा को समेकित करें।',
            },
            {
                header: 'परियोजना समयसीमा को सुधारने के लिए उत्पादकता रिपोर्ट उत्पन्न करें।',
                paragraph:
                    'समय पर परियोजना वितरण सुनिश्चित करने और देरी को कम करने के लिए प्रदर्शन मीट्रिक्स की निगरानी करें।',
            },
        ],
    },
};
export default industries;
