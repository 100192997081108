const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'लचीले योजनाएं जो आपके साथ बढ़ती हैं',
    subFlexiblePlansThatGrowsWithYou:
        'उस मूल्य निर्धारण योजना का चयन करें जो आपकी व्यावसायिक आवश्यकताओं को पूरा करती है। ट्रेसफोर्स आदर्श मूल्य पर मूल्य पैकेज प्रदान करता है, जो उत्कृष्ट मूल्य के साथ है।',
    pricingPlans: 'मूल्य निर्धारण योजनाएं',
    pricingThatWorksForEveryOne: 'हर किसी के लिए काम करने वाली मूल्य निर्धारण',
    monthly: 'मासिक',
    annualy: 'वार्षिक',
    international: 'अंतर्राष्ट्रीय',
    domestic: 'घरेलू',
    month: 'महीना',
    benifits: 'लाभ',
    allPlanIncludeTheseBenifits: 'सभी योजनाओं में ये लाभ शामिल हैं',
    someBenfitsOfMonthlyYearlySubscription: 'मासिक/वार्षिक सदस्यता के कुछ लाभ',
    benifitsArr: [
        {
            title: 'तकनीकी सहायता',
            description: 'हमारी पेशेवर तकनीकी सहायता टीम हर कदम पर आपकी मदद करेगी',
        },
        {
            title: 'प्रौद्योगिकी',
            description: 'प्लेटफ़ॉर्म के साथ शुरुआत करने के लिए पेशेवरों द्वारा एक विशेष प्रशिक्षण',
        },
        {
            title: 'विकास विश्लेषण',
            description: 'हर महीने आपकी वृद्धि के बारे में अंतर्दृष्टि प्राप्त करने के लिए एक समर्पित टीम',
        },
        {
            title: 'इनाम',
            description: 'हर महीने सबसे अधिक प्रदर्शन करने वाले खाते के लिए एक विशेष इनाम',
        },
    ],
    fAQs: 'पूछे जाने वाले प्रश्न',
    frequentlyAskedQuestions: 'अक्सर पूछे जाने वाले प्रश्न',
    someBaTypeOfQuestions: 'यहाँ हमारे ग्राहकों के लिए कुछ सामान्य प्रकार के प्रश्न दिए गए हैं',
    fAQArr: [
        {
            question: 'मैं अपनी कंपनी को कैसे साइन अप कर सकता हूँ?',
            ans: 'आप Naavy पर 3 आसान चरणों में साइन अप कर सकते हैं हमारी मोबाइल ऐप या वेब ऐप के जरिए। बस अपना नाम, ईमेल और जीएसटी नंबर जोड़ें और आप तैयार हैं।',
        },
        {
            question: 'लॉगिन को प्रमाणित करने के लिए मेरे पास कौन-कौन से विकल्प हैं?',
            ans: 'Naavy आपको कई Clock In विकल्प प्रदान करता है, उदाहरण के लिए हमारे पास डिफ़ॉल्ट मोबाइल लॉगिन विकल्प, सेल्फी आधारित विकल्प और सबसे सुरक्षित AI आधारित फेस ऑथेंटिकेशन है।',
        },
        {
            question: 'हाजिरी कैसे दर्ज करें?',
            ans: 'उपयोगकर्ता केवल तभी हाजिरी दर्ज कर सकता है जब वह उस भौगोलिक क्षेत्र के भीतर हो जो उसे सौंपा गया है। इसके अलावा, प्रॉक्सी से बचाने के लिए हमारे पास विभिन्न प्रमाणीकरण विधियाँ हैं।',
        },
        {
            question: 'क्या हम स्थान को नकली बना सकते हैं या कार्य पूरा करने या हाजिरी दर्ज करने का समय बदल सकते हैं?',
            ans: 'Naavy किसी भी धोखाधड़ी या अवांछित गतिविधि का पता लगाता है, ऐप को निष्क्रिय कर देता है और उपयोगकर्ता को सूचित करता है।',
        },
        {
            question: 'क्या Naavy दूरस्थ कर्मचारियों का समर्थन करता है?',
            ans: 'Naavy अनुबंध आधारित कर्मचारियों का प्रति घंटे के आधार पर समर्थन करता है और निश्चित घंटे या लचीले घंटे की शिफ्ट के आधार पर वेतन की गणना करता है।',
        },
        {
            question: 'क्या मैं अपने वर्कफोर्स को मोबाइल से ट्रैक कर सकता हूँ?',
            ans: 'Naavy आपको मोबाइल और वेब दोनों के माध्यम से अपने कर्मचारियों को ट्रैक और प्रबंधित करने में मदद करता है और दिनभर का विस्तृत दृश्य प्रदान करता है।',
        },
        {
            question: 'HRMS के अलावा Naavy और कौन-कौन सी सेवाएँ प्रदान करता है?',
            ans: 'Naavy प्रबंधकों या व्यवसाय के मालिकों के काम को आसान बनाने और केवल आवश्यक सूचनाओं में मदद करने के लिए व्यापक सेवाओं की सूची प्रदान करता है।',
        },
    ],
};

export default pricingPage;
