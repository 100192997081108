const industries = {
    pharmaceutical: {
        industryHeading:
            'Monitorea los protocolos de seguridad, el seguimiento del tiempo, la asistencia y controla el acceso a áreas sensibles facilitando una gestión de proyectos efectiva, actividades fuera del sitio y evaluación del desempeño.',
        featureHeader: 'Farmacéutico',
        featureParagraph:
            'El software de gestión de asistencia y seguimiento de campo enfocado en la industria farmacéutica optimiza la gestión de la fuerza laboral para empresas farmacéuticas/de atención médica con características como el seguimiento del tiempo, accesibilidad móvil para empleados de campo, informes de cumplimiento de operaciones de campo e integración con diferentes sistemas para la generación de ventas y productividad.',
        keyPoints: [
            {
                header: 'Integra los sistemas de seguimiento de empleados con los sistemas de RRHH relacionados con registros de empleados, nómina e informes de cumplimiento.',
                paragraph: 'Monitorea las horas de trabajo basadas en diarias, semanales y mensuales.',
            },
            {
                header: 'Utiliza sistemas de gestión de turnos para optimizar la programación de la fuerza laboral.',
                paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
            },
            {
                header: 'Monitorea los horarios de áreas de fabricación y producción donde las operaciones continuas son cruciales.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Asegúrate de que todos los sistemas de seguimiento de empleados cumplan con las regulaciones y estándares relevantes en la industria farmacéutica.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
        ],
    },
    education: {
        industryHeading:
            'Un CRM de admisiones integral para una colaboración fluida de estudiantes y gestión de prospectos.',
        featureHeader: 'Educación',
        featureParagraph:
            'Reclutamiento y Admisiones de Estudiantes\nCompromiso y Retención de Estudiantes\nGestiona admisiones en múltiples ubicaciones\nSoporte Personalizado para Estudiantes',
        keyPoints: [
            {
                header: 'Rastrea y gestiona a los estudiantes potenciales desde el primer punto de contacto a través del proceso de solicitud.',
                paragraph: 'Monitorea las horas de trabajo basadas en diarias, semanales y mensuales.',
            },
            {
                header: 'Identifica y gestiona a los estudiantes potenciales que muestran interés en inscribirse. Rastrea la comunicación y el progreso para convertir consultas en estudiantes inscritos.',
                paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
            },
            {
                header: 'Visualiza y gestiona las diferentes etapas del proceso de inscripción de estudiantes. Mueve a los solicitantes a través de etapas como Consulta, Solicitud, Entrevista, Aceptación e Inscripción.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Simplifica el proceso de solicitud con formularios en línea personalizables. Recoge la información necesaria de los estudiantes potenciales de manera eficiente.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'Optimiza el rendimiento de los equipos de ventas en movimiento mediante la habilitación del seguimiento de ubicación en tiempo real, la planificación eficiente de rutas y los compromisos oportunos con los clientes.',
        featureHeader: 'Ventas en Campo',
        featureParagraph:
            'Acelera el crecimiento y potencia tu fuerza de campo con las herramientas adecuadas para Planificar, Convertir y Ejecutar. Naavy simplifica el seguimiento de ventas en campo y la gestión de leads y CRM.',
        keyPoints: [
            {
                header: 'Asistencia en Tiempo Real',
                paragraph:
                    'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área del Geofence o sucursal.',
            },
            {
                header: 'Cálculo Total de Horas de Trabajo',
                paragraph: 'Monitorea las horas de trabajo basadas en diarias, semanales y mensuales.',
            },
            {
                header: 'Informes de Asistencia Remotos',
                paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
            },
            {
                header: 'Gestión Centralizada de Asistencia',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'Métricas de rendimiento, gestión de gastos y seguimiento de cumplimiento efectivos para la gestión de la fuerza de ventas, mejorando la participación del cliente con características de comunicación y colaboración.',
        featureParagraph:
            'El seguimiento de asistencia en tiempo real permite ajustes rápidos en los niveles de personal en función de la demanda del mercado, mejorando la agilidad de las operaciones de FMCG.',
        keyPoints: [
            {
                header: 'El seguimiento preciso de asistencia ayuda',
                paragraph:
                    'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área del Geofence o sucursal.',
            },
            {
                header: 'Procesamiento de nómina preciso, reduciendo errores y minimizando discrepancias financieras.',
                paragraph: 'Monitorea las horas de trabajo basadas en diarias, semanales y mensuales.',
            },
            {
                header: 'Asegurar el cumplimiento de las leyes laborales y regulaciones.',
                paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
            },
            {
                header: 'Facilitar una gestión efectiva de licencias, evitando problemas de subpersonal.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Garantizar la producción y distribución continuas.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Garantizar procesos de reembolso precisos.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Rastreo de pedidos en movimiento y finalización.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
        ],
    },
    services: {
        industryHeading:
            'Optimiza la gestión de proyectos y tareas, asigna recursos de manera eficiente asegurando registros precisos de tiempo y asistencia, ayudando en el procesamiento de nómina y cumplimiento.',
        featureHeader: 'Servicios',
        featureParagraph:
            'Las características probadas en campo potencian la productividad, minimizan la complejidad y automatizan tareas, ahorrando tiempo y recursos para tu negocio.',
        keyPoints: [
            {
                header: 'Seguimiento de GPS en tiempo real para el personal de campo',
                paragraph:
                    'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área del Geofence o sucursal.',
            },
            {
                header: 'Formularios Personalizados y Fotos Geoetiquetadas',
                paragraph: 'Monitorea las horas de trabajo basadas en diarias, semanales y mensuales.',
            },
            {
                header: 'Tablero intuitivo e informes descargables',
                paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
            },
            {
                header: 'Gestionar y monitorear colecciones',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
        ],
    },
    fieldOperations: {
        industryHeading:
            'Facilita decisiones basadas en datos y mejora la comunicación entre los equipos de campo y la sede central.',
        featureHeader: 'Operaciones de Campo',
        featureParagraph:
            'Di adiós a las operaciones de campo ineficientes y a la gestión de servicios con la solución todo-en-uno de Naavy. Optimiza la gestión de tareas, rastrea la asistencia en movimiento, y optimiza rutas basadas en IA para aumentar la productividad y los ingresos por ventas.',
        keyPoints: [
            {
                header: 'Programar y monitorear tareas',
                paragraph:
                    'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área del Geofence o sucursal.',
            },
            {
                header: 'Rastrea la ubicación en tiempo real de todos los empleados de campo',
                paragraph: 'Monitorea las horas de trabajo basadas en diarias, semanales y mensuales.',
            },
            {
                header: 'Validar y procesar reclamaciones de gastos',
                paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
            },
            {
                header: 'Establecer objetivos SLA y monitorear retrasos',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Tablero intuitivo e informes descargables',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Finalización de tareas verificada por OTP',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'Facilita decisiones basadas en datos y mejora la comunicación entre los equipos de campo y la sede central.',
        featureHeader: 'Logística y Transporte',
        featureParagraph:
            'Simplifica y organiza la logística y la entrega de manera más simple. Gestiona las operaciones logísticas de una gran fuerza laboral con sus características robustas y avanzadas.',
        keyPoints: [
            {
                header: 'Obtén rastreo en tiempo real de la ubicación del repartidor.',
                paragraph:
                    'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área del Geofence o sucursal.',
            },
            {
                header: 'Mejora la eficiencia operativa monitoreando actividades y flujos de trabajo.',
                paragraph: 'Monitorea las horas de trabajo basadas en diarias, semanales y mensuales.',
            },
            {
                header: 'Simplifica la gestión de tareas de la fuerza laboral.',
                paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
            },
            {
                header: 'Analiza datos históricos e información de tráfico en tiempo real para optimizar las rutas de entrega.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Procesamiento automatizado de reembolsos y nómina.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
            {
                header: 'Mejor servicio al cliente con trabajos de campo automatizados.',
                paragraph:
                    'Consolidación de datos relacionados con la asistencia, procesos y sistemas en una plataforma o sistema centralizado.',
            },
        ],
    },
};

export default industries;
