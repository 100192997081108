const joinUsPage = {
    joinUsString: 'Rejoignez-nous',
    joinUsHeroPara: `Venez faire partie de notre équipe chez Naavy ! Découvrez des opportunités passionnantes où le travail d’équipe et la créativité sont à l’honneur. Découvrez comment vous pouvez contribuer à notre mission d'autonomiser les entreprises grâce à des solutions logicielles intuitives. Développez votre carrière dans un environnement de soutien qui valorise vos talents et idées uniques.`,
    ourRecruitmentProcess: 'Notre Processus de Recrutement',
    ourBenifitsString: 'Nos Avantages',
    ourRecruitmentProcessObjects: [
        {
            title: 'Entretien Technique',
            description:
                'Cet entretien est conçu pour évaluer vos compétences techniques, vos capacités de résolution de problèmes et comment vous appliquez vos connaissances à des scénarios réels. Nous voulons comprendre votre maîtrise technique, vos capacités en codage et votre approche des défis. Ce tour se fait via une visioconférence (meet/skype).',
        },
        {
            title: 'Test de Pratique (Aptitude)',
            description:
                'Nous évaluons votre logique de codage et l’optimisation du code en vous assignant une tâche qui sera envoyée par e-mail après votre premier tour en fonction de votre entretien. L’assignation aura un délai précis dans lequel le candidat devra la compléter.',
        },
        {
            title: 'Entretien de Management',
            description:
                'Discussion rapide avec notre management pour comprendre votre vision de carrière et votre adéquation culturelle avec notre organisation. Nous recherchons une personne avec une solide connaissance du sujet et une éthique de travail forte.',
        },
        {
            title: 'Sélection Finale',
            description:
                'La finalisation de l’offre sera effectuée avec l’équipe RH. Nous effectuons notre sélection finale après une consolidation approfondie des performances des candidats dans tous les tours d’entretien. En fonction de la décision mutuelle, les dates de début seront confirmées, et nous vous fournirons les meilleurs outils dès le premier jour.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'Travailler de N’importe Où !',
            description:
                'On revient au bureau ! Parce que votre bureau à domicile est un peu trop convivial pour les chiens et que votre "salle de réunion" est actuellement occupée par une sieste de chat. Nous n’offrons pas le travail à distance car nous aimons vous voir tous les jours.',
        },
        {
            name: 'Week-ends Alternés',
            description:
                'Lorsque vos week-ends sont aussi alternants que vos sautes d’humeur. Une semaine, vous planifiez une escapade amusante ou rattrapez le sommeil, et la semaine suivante, vous vous attaquez aux délais et répondez aux e-mails. Tout cela fait partie de l’aventure de l’équilibre travail-loisirs.',
        },
        {
            name: 'Assurance Santé',
            description:
                'Pour tous ces moments "je pourrais avoir besoin d’un médecin pour cela". Avec une couverture complète, vous n’aurez pas à vous soucier des "si"—concentrez-vous simplement sur le fait de vous rétablir et de revenir à votre meilleur. Parce que lorsque vous ne vous sentez pas bien, vous méritez les meilleurs soins à portée de main.',
        },
        {
            name: 'Prime de Référence',
            description:
                'Vous avez des amis ? Recevez des primes ! C’est un gagnant-gagnant. Imaginez ceci : Vous avez un cercle d’amis qui sont formidables dans ce qu’ils font et cherchent de nouvelles opportunités. En les référant à notre entreprise, vous les aidez non seulement à trouver un emploi fantastique, mais vous remplissez également vos poches avec une belle prime de référence.',
        },
        {
            name: 'Kit Employé',
            description:
                'Kit Employé - Tout ce dont vous avez besoin pour conquérir la journée de travail, sauf un clone de vous-même. Des fournitures de bureau essentielles aux goodies motivants, ce kit a tout ce qu’il faut. Besoin d’un stylo ? Check. Cahier pour toutes vos idées brillantes ? Check.',
        },
        {
            name: 'Horaires de Travail Flexibles',
            description:
                'Plus besoin de rentrer à la maison en courant pour regarder votre émission préférée ; maintenant, votre bureau peut être votre canapé, et votre temps de pause peut être du temps prime. Avec des horaires flexibles, vous pouvez passer facilement des appels en conférence aux séries télé, des tableurs aux soirées Netflix.',
        },
        {
            name: 'Congés Sabatiques',
            description: 'Congés Sabatiques',
        },
        {
            name: 'Congés de Paternité/Maternité',
            description: 'Congés de Paternité/Maternité',
        },
        {
            name: 'Équilibre Travail-Vie Personnelle',
            description: 'Équilibre Travail-Vie Personnelle',
        },
        {
            name: 'Célébrations au Bureau',
            description: 'Célébrations au Bureau',
        },
    ],
    jobsAtTraceForce: 'Postes chez Naavy',
};

export default joinUsPage;
