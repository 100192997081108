const joinUsPage = {
    joinUsString: 'எங்களுடன் சேருங்கள்',
    joinUsHeroPara:
        'Naavy இல் எங்களின் அணியில் சேருங்கள்! ஒத்துழைப்பு மற்றும் படைப்பாற்றல் வழிகாட்டும் உற்சாக வாய்ப்புகளை ஆராயுங்கள். நாங்கள் பயனுள்ளதாக மென்பொருள் தீர்வுகள் மூலம் தொழில்களை திறம்பட இயக்க உதவுவதற்கான நமது கோரிக்கைக்கு நீங்கள் எப்படி பங்களிக்க முடியும் என்பதைக் கண்டறியுங்கள். உங்கள் தனிப்பட்ட திறமைகள் மற்றும் கருத்துகளை மதிக்கும் ஆதரவு சூழலில் உங்கள் தொழில்நுட்பங்களை வளர்த்துக்கொள்ளுங்கள்.',
    ourRecruitmentProcess: 'நமது ஆட்கொடுப்பு செயல்முறை',
    ourBenifitsString: 'நமது நன்மைகள்',
    ourRecruitmentProcessObjects: [
        {
            title: 'தொழில்நுட்ப நேர்காணல்',
            description:
                'இந்த நேர்காணல் உங்கள் தொழில்நுட்ப திறன்கள், பிரச்சனை தீர்க்கும் திறன்கள் மற்றும் நிஜ உலக சூழ்நிலைகளில் உங்கள் அறிவைப் பயன்படுத்துவதில் எவ்வளவு சிறப்பாக செயல்படுகிறீர்கள் என்பதைக் மதிப்பீடு செய்ய வடிவமைக்கப்பட்டுள்ளது. உங்கள் தொழில்நுட்ப நிபுணத்துவம், குறியீட்டு திறன்கள் மற்றும் சவால்களை சமாளிக்கும் முறையைப் புரிந்துகொள்ள விரும்புகிறோம். இந்த சுற்று Meet/Skype அழைப்பின் மூலம் நடைபெறும்.',
        },
        {
            title: 'பயிற்சி சோதனை (அப்டிடூட்)',
            description:
                'உங்கள் குறியீட்டு விவகாரங்கள் மற்றும் குறியீட்டு மேம்பாட்டைப் மதிப்பீடு செய்வதற்காக ஒரு பணியை வழங்குவோம், இது உங்கள் முதல் சுற்றின் பின்னர் உங்கள் நேர்காணல் அடிப்படையில் மெயிலாக அனுப்பப்படும். நிச்சயமாக, உங்களுக்குக் கொடுக்கப்படும் பணிக்கு ஒரு குறிப்பிட்ட காலத்தைப் பொருத்தமாக, வேலையாளர் அதை நிறைவேற்ற வேண்டும்.',
        },
        {
            title: 'மேலாண்மை நேர்காணல்',
            description:
                'நமது மேலாண்மையுடன் விரைவாகப் பேசுவதன் மூலம், உங்கள் தொழில் பார்வை மற்றும் எங்கள் நிறுவனத்திற்கான கலாச்சார பொருத்தத்தைப் புரிந்து கொள்ளுங்கள். நாங்கள் மிகுந்த தலைமை நிபுணத்துவமும், நிலையான வேலை நெறிகளும் கொண்ட நபரைத் தேடுகிறோம்.',
        },
        {
            title: 'இறுதித் தேர்வு',
            description:
                'HR குழுவுடன் நிலைத்திருக்கும் ஆஃபரை முடிப்போம். நாங்கள் அனைத்து நேர்காணல் சுற்றுகளில் வேலையாளர் செயல்திறனை ஆழமாக ஒருங்கிணைத்து இறுதித் தேர்வைப் செய்வோம். பரஸ்பர முடிவின் அடிப்படையில் இணைவதற்கான தேதிகளை உறுதிப்படுத்துவோம், முதல் நாளில் சிறந்த கருவிகளை வழங்குவோம்.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'எங்கு வேண்டுமானாலும் வேலை செய்யலாம்!',
            description:
                "உங்கள் வீட்டில் உள்ள அலுவலகம் மிகவும் நாய்களுடன் நடந்து கொண்டதால் மற்றும் உங்கள் 'நிகழ்ச்சி அறை' தற்போதைய நிலையிடப்பட்ட பூனை நியாஸ் மூலம் அளிக்கப்படுகிறது! நாங்கள் எங்கு வேண்டுமானாலும் வேலை செய்ய அனுமதிக்கவில்லை, ஏனெனில் எங்களை தினமும் பார்க்க விரும்புகிறோம்.",
        },
        {
            name: 'மாற்றுபடுத்தப்பட்ட வாராந்திர விடுமுறைகள்',
            description:
                'உங்கள் வார இறுதிகள் உங்கள் மனநிலையில் மாற்றமடைகிறதுபோல. ஒரே வாரம், நீங்கள் ஒரு சுகாதாரத்தை திட்டமிடுகிறீர்கள் அல்லது தூக்கத்தைப் பின்வருகிறீர்கள், மற்றொரு வாரம், நீங்கள் இறுதி நேரங்கள் மற்றும் மின்னஞ்சல்களைப் பதிலளிக்கிறீர்கள். இது வேலை மற்றும் விளையாட்டைப் சமநிலைப்படுத்துவதில் ஒரு சவாலாகும்.',
        },
        {
            name: 'ஆரோக்கிய காப்பீடு',
            description:
                'எல்லா ‘நான் ஒரு டாக்டருக்கு இதை தேவைப்படும்’ தருணங்களுக்கு. பரந்த அளவிலான காப்பீடுடன், நீங்கள் ‘என்ன என்பதைப் பற்றி’ கவலைப்பட வேண்டியதில்லை—மற்றும் நீங்கள் மிகவும் சிறந்தவராக மீண்டும் திரும்புவதற்கு கவனம் செலுத்துங்கள். நீங்கள் சிறந்தவராக உணரவில்லை, ஆனால் நீங்கள் சிறந்த பராமரிப்பை அடைய வேண்டியதால்.',
        },
        {
            name: 'மேற்கொள்கை நன்மை',
            description:
                'நண்பர்களும் இருக்கிறார்கள்? நன்மைகள் பெறுங்கள்! இது ஒரு வெற்றி-வெற்றி நிலையாகும். இதை மனதில் வைப்பு: உங்கள் நண்பர்களின் ஒரு வட்டத்தில், அவர்கள் என்ன செய்யும் என எடுக்கக்கூடியவை மற்றும் புதிய வாய்ப்புகளைப் தேடுகிறார்கள். அவர்களை எங்கள் நிறுவனத்திற்கு பரிந்துரை செய்வதன் மூலம், நீங்கள் அவர்களை அருமையான வேலைப் பெற உதவுகிறீர்கள், ஆனால் நீங்கள் நன்மை பெறுவதுடன் இணைக்கிறீர்கள்.',
        },
        {
            name: 'ஊழியர் கிட்',
            description:
                'ஊழியர் கிட் - வேலை நாள்களைச் conquering செய்ய தேவையான அனைத்தும், ஒரு நகல் தவிர. முக்கிய அலுவலக சாதனங்கள் மற்றும் ஊக்கப்படுத்தும் நல்லங்கள் மூலம், இந்த கிட் அனைத்தையும் கொண்டுள்ளது. கையெழுத்து தேவை? சரி. உங்கள் அற்புதமான யோசனைகளுக்கான நோட்டுபுத்தகம்? சரி.',
        },
        {
            name: 'நெகிழ்வான வேலை நேரங்கள்',
            description:
                'உங்கள் பிடித்த நிகழ்ச்சியைப் பார்க்க வீட்டிற்கு விரைந்து செல்ல வேண்டாம்; இப்போது, உங்கள் அலுவலகம் உங்கள் தூக்கமான கூடையாக இருக்க முடியும், உங்கள் விடுப்பு நேரம் முக்கிய நேரமாக இருக்க முடியும். நெகிழ்வான நேரங்களுடன், நீங்கள் மாநாடு அழைப்புகளை முன்பே பார்க்கலாம், சில்பட்ஷாட் வரை நெகிழ்வுடன் நிறைவாக இருக்கலாம், அட்டவணைகளைப் புறக்கணிக்கலாம்.',
        },
        {
            name: 'முடிவு விடுப்பு',
            description: 'முடிவு விடுப்பு',
        },
        {
            name: 'அந்தரங்க/ தாய்மை விடுப்பு',
            description: 'அந்தரங்க/ தாய்மை விடுப்பு',
        },
        {
            name: 'வேலை-வாழ்க்கை சமநிலை',
            description: 'வேலை-வாழ்க்கை சமநிலை',
        },
        {
            name: 'அலுவலக கொண்டாட்டங்கள்',
            description: 'அலுவலக கொண்டாட்டங்கள்',
        },
    ],
    jobsAtTraceForce: 'Naavy இல் வேலைகள்',
};
export default joinUsPage;
