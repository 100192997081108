const industries = {
    pharmaceutical: {
        industryHeading:
            'راقب بروتوكولات السلامة، وتتبّع الوقت والحضور، وتحكم في الوصول إلى المناطق الحساسة من خلال تسهيل إدارة المشاريع الفعّالة، والأنشطة الخارجية وتقييم الأداء.',

        featureHeader: 'الصناعات الدوائية',
        featureParagraph:
            'يسهل برنامج إدارة الحضور والتتبع الميداني المخصص للصناعات الدوائية/الرعاية الصحية إدارة القوى العاملة، مع ميزات تتبع الوقت والوصول عبر الهاتف المحمول للموظفين الميدانيين، وتقديم تقارير الامتثال للعمليات الميدانية، والتكامل مع أنظمة مختلفة لتحفيز المبيعات وزيادة الإنتاجية.',
        keyPoints: [
            {
                header: 'دمج أنظمة تتبع الموظفين مع أنظمة الموارد البشرية المتعلقة بسجلات الموظفين والرواتب وتقارير الامتثال.',
                paragraph: 'راقب ساعات العمل بناءً على يومي وأسبوعي وشهري',
            },
            {
                header: 'استخدام أنظمة إدارة الورديات لتحسين جدولة القوى العاملة',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'مراقبة جداول مناطق الإنتاج حيث تعتبر العمليات المستمرة أمراً حيوياً.',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'ضمان أن جميع أنظمة تتبع الموظفين تتوافق مع اللوائح والمعايير المعنية في صناعة الأدوية.',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
        ],
    },
    education: {
        industryHeading: 'نظام CRM شامل للقبول لتعزيز التعاون مع الطلاب وإدارة المتقدمين.',

        featureHeader: 'التعليم',
        featureParagraph:
            'توظيف الطلاب والقبول \n تفاعل الطلاب والاحتفاظ بهم \n إدارة القبول في مواقع متعددة \n دعم الطلاب المخصص',
        keyPoints: [
            {
                header: 'تتبع وإدارة الطلاب المحتملين من أول نقطة اتصال حتى عملية التقديم.',
                paragraph: 'راقب ساعات العمل بناءً على يومي وأسبوعي وشهري',
            },
            {
                header: 'تحديد وإدارة الطلاب المحتملين الذين يظهرون اهتماماً بالتسجيل. تتبع التواصل والتقدم لتحويل الاستفسارات إلى طلاب مسجلين.',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'تصور وإدارة مختلف مراحل عملية القبول. نقل المتقدمين من مراحل مثل الاستفسار، التقديم، المقابلة، القبول والتسجيل.',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'تبسيط عملية التقديم باستخدام نماذج عبر الإنترنت قابلة للتخصيص. جمع المعلومات اللازمة من الطلاب المحتملين بكفاءة.',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'تحسين أداء فرق المبيعات المتنقلة من خلال تمكين تتبع الموقع في الوقت الفعلي، وضمان تخطيط المسار الفعّال والتفاعل الفوري مع العملاء.',

        featureHeader: 'المبيعات الميدانية',
        featureParagraph:
            'تسريع النمو وتحفيز قوة المبيعات الميدانية باستخدام الأدوات الصحيحة للتخطيط، والتحويل، والتنفيذ. يبسط Naavy تتبع المبيعات الميدانية وإدارة العملاء المحتملين وCRM',
        keyPoints: [
            {
                header: 'الحضور في الوقت الفعلي',
                paragraph: 'معلومات في الوقت الفعلي حول حضور أو غياب الأفراد داخل منطقة الجغرافيا أو الفرع',
            },
            {
                header: 'حساب إجمالي ساعات العمل',
                paragraph: 'راقب ساعات العمل بناءً على يومي وأسبوعي وشهري',
            },
            {
                header: 'الوصول عن بُعد إلى تقارير الحضور',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'إدارة الحضور المركزية',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
        ],
    },
    fmcg: {
        featureHeader: 'السلع الاستهلاكية',
        industryHeading:
            'مقاييس الأداء وإدارة النفقات وتتبع الامتثال الفعّال لإدارة فرق المبيعات، وتعزيز تفاعل العملاء من خلال ميزات التواصل والتعاون',
        featureParagraph:
            'تمكن تقنيات تتبع الحضور في الوقت الفعلي من إجراء تعديلات سريعة على مستويات التوظيف بناءً على الطلب في السوق، مما يعزز من مرونة عمليات السلع الاستهلاكية.',
        keyPoints: [
            {
                header: 'يساعد تتبع الحضور الدقيق',
                paragraph: 'معلومات في الوقت الفعلي حول حضور أو غياب الأفراد داخل منطقة الجغرافيا أو الفرع',
            },
            {
                header: 'معالجة الرواتب بدقة، وتقليل الأخطاء وتقليل التباينات المالية.',
                paragraph: 'راقب ساعات العمل بناءً على يومي وأسبوعي وشهري',
            },
            {
                header: 'ضمان الامتثال لقوانين وأنظمة العمل.',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'تسهيل إدارة الإجازات الفعّالة، ومنع مشكلات نقص الموظفين',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'ضمان استمرار الإنتاج والتوزيع.',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'ضمان دقة عمليات استرداد المصروفات.',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'تتبع الطلبات أثناء التنقل والإنجازات.',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
        ],
    },
    services: {
        industryHeading:
            'تبسيط إدارة المشاريع والمهام، وتحسين تخصيص الموارد من خلال ضمان سجلات الوقت والحضور الدقيقة، مما يساعد في معالجة الرواتب والامتثال.',

        featureHeader: 'الخدمات',
        featureParagraph:
            'الميزات المجربة ميدانياً تعزز الإنتاجية، تقلل التعقيد، وتُؤتمت المهام، مما يوفر الوقت والموارد لشركتك.',
        keyPoints: [
            {
                header: 'تتبع GPS في الوقت الفعلي لموظفي الميدان',
                paragraph: 'معلومات في الوقت الفعلي حول حضور أو غياب الأفراد داخل منطقة الجغرافيا أو الفرع',
            },
            {
                header: 'نماذج مخصصة وصور مشفرة بالجغرافيا',
                paragraph: 'راقب ساعات العمل بناءً على يومي وأسبوعي وشهري',
            },
            {
                header: 'لوحة معلومات بديهية وتقارير قابلة للتنزيل',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'إدارة ومراقبة التحصيلات',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
        ],
    },
    fieldOperations: {
        industryHeading: 'تسهيل اتخاذ القرارات المبنية على البيانات وتعزيز التواصل بين فرق الميدان والمقر الرئيسي.',

        featureHeader: 'العمليات الميدانية',
        featureParagraph:
            'قل وداعاً للعمليات الميدانية غير الفعّالة وإدارة الخدمة مع الحل الشامل من Naavy. تحسين إدارة المهام، تتبع الحضور أثناء التنقل، وتحسين المسارات استناداً إلى الذكاء الاصطناعي لزيادة الإنتاجية وإيرادات المبيعات.',
        keyPoints: [
            {
                header: 'جدولة ومراقبة المهام',
                paragraph: 'معلومات في الوقت الفعلي حول حضور أو غياب الأفراد داخل منطقة الجغرافيا أو الفرع',
            },
            {
                header: 'تتبع الموقع في الوقت الفعلي لجميع موظفي الميدان',
                paragraph: 'راقب ساعات العمل بناءً على يومي وأسبوعي وشهري',
            },
            {
                header: 'التحقق من وتقديم مطالبات المصروفات',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'تحديد أهداف اتفاقيات مستوى الخدمة ومراقبة التأخير',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'لوحة معلومات بديهية وتقارير قابلة للتنزيل',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'إكمال المهام الموثقة برمز التحقق (OTP)',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'إدارة حركة البضائع والمركبات والسائقين بكفاءة من خلال تتبع الموقع في الوقت الفعلي وإدارة الحضور الموثوقة.',

        featureHeader: 'اللوجستيات والنقل',
        featureParagraph:
            'بفضل Naavy، يمكن لشركات اللوجستيات تتبع حركة السلع بشكل فوري وتحسين مسارات النقل، ما يؤدي إلى تسليم أسرع وتقليل التكاليف.',
        keyPoints: [
            {
                header: 'تتبع الموقع في الوقت الفعلي لجميع المركبات والبضائع',
                paragraph: 'معلومات في الوقت الفعلي حول حضور أو غياب الأفراد داخل منطقة الجغرافيا أو الفرع',
            },
            {
                header: 'تحسين المسارات بناءً على تحليلات البيانات',
                paragraph: 'راقب ساعات العمل بناءً على يومي وأسبوعي وشهري',
            },
            {
                header: 'تحديد وضمان الامتثال للوائح النقل',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'إدارة الحضور المركزية لفرق العمل المتنقلة',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'إدارة جداول الصيانة وتقارير الأداء',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
            {
                header: 'تحسين استخدام المركبات لتقليل تكاليف الوقود والصيانة',
                paragraph: 'دمج بيانات وإجراءات وأنظمة الحضور في منصة أو نظام مركزي واحد',
            },
        ],
    },
};
export default industries;
