const homePage = {
    heroHeader: 'বিক্রয় উৎকর্ষতা এবং কার্যক্রম দক্ষতা চালান',
    heroHeaderTypist: ['উপস্থিতি ব্যবস্থাপনা', 'ফিল্ড ট্র্যাকিং মনিটরিং', 'সিআরএম'],
    heroHeaderSubText:
        'বাস্তব-সময় ভিত্তিক অবস্থান উপস্থিতি, লাইভ ট্র্যাকিং, টাস্ক এবং অর্ডার ব্যবস্থাপনা, ডেটা অন্তর্দৃষ্টি এবং এপিআই পরিষেবা',
    moreProductivityLessExpenses: 'বেশি উৎপাদনশীলতা, কম ব্যয়',
    keyFeatures: [
        {
            title: 'লেন্স',
            description:
                'AI সহায়তায় টাচলেস ফেসিয়াল উপস্থিতি ক্লক ইন এবং ক্লক আউট। আপনার মোবাইল ক্যামেরা ব্যবহার করে উপস্থিতি চিহ্নিত করুন।',
        },
        {
            title: 'ওয়েব অ্যাপ',
            description:
                'ব্যবহারকারী-বান্ধব ড্যাশবোর্ড, HR এবং ব্যবস্থাপক টিম সহজেই বিস্তারিত রিপোর্ট অ্যাক্সেস করতে পারে, কর্মচারীর রেকর্ড পরিচালনা করতে পারে, কর্মক্ষমতা ট্র্যাক করতে পারে এবং পেরোল চেক করতে পারে।',
        },
        {
            title: 'হায়ারার্কি ভিত্তিক নিয়ন্ত্রণ',
            description:
                'বিভিন্ন সাংগঠনিক স্তর যেমন প্রশাসনিক, HR ম্যানেজার, সুপারভাইজার এবং কর্মচারীদের জন্য হায়ারার্কি ভিত্তিক নিয়ন্ত্রণ ব্যবস্থা।',
        },
        {
            title: 'রুট অপটিমাইজেশন',
            description:
                'গতি বৃদ্ধি এবং কার্যক্রম ব্যয় কমানোর জন্য গতিশীল রুট গণনা, মাল্টি-স্টপ অপটিমাইজেশন, খরচের আনুমানিকতা এবং বাস্তব-সময় আপডেট।',
        },
    ],
    features: 'বৈশিষ্ট্য',
    ourFeatures:
        'আমাদের বৈশিষ্ট্যগুলি সহজ, শক্তিশালী এবং যেকোনো শিল্পের জন্য সহজেই উপযুক্ত, যা সংগঠনের উৎপাদনশীলতা বৃদ্ধি করে।',
    ourFeaturesObject: [
        {
            title: 'উপস্থিতি মনিটরিং',
            description: 'অবস্থান সহ উপস্থিতি চিহ্নিতকরণ এবং কাজের ঘন্টা ট্র্যাকিং',
        },
        {
            title: 'খরচ ব্যবস্থাপনা',
            description: 'খরচ সঠিকভাবে ট্র্যাক এবং রেকর্ড করা হয়',
        },
        {
            title: 'ওয়ার্কফোর্স ট্র্যাকিং',
            description: 'কর্মচারীদের কার্যকলাপ, উৎপাদনশীলতা মনিটরিং এবং রেকর্ডিং',
        },
        {
            title: 'পেরোল ব্যবস্থাপনা',
            description: 'পেরোল প্রসেসিং অপ্টিমাইজ করুন এবং সঠিক পেআউট নিশ্চিত করুন',
        },
        {
            title: 'অর্ডার ব্যবস্থাপনা',
            description: 'বিভিন্ন কাস্টমাইজেবল কৌশল দ্বারা অর্ডার/সংগ্রহ ব্যবস্থাপনা',
        },
        {
            title: 'রুট অপটিমাইজেশন',
            description: 'সময়, জ্বালানি এবং সম্পদ বাঁচানোর জন্য সবচেয়ে দক্ষ পথ খুঁজে বের করা।',
        },
    ],
    whatWeDoQuestion: 'Naavy কি করে?',
    whatWeDoCards: [
        {
            title: 'বাস্তব সময়ে কর্মচারীদের কার্যকলাপ বিশ্লেষণ করুন',
            description: 'প্রতিদিন বা প্রতি ঘন্টায় দলের কার্যকলাপের বিস্তারিত বিশ্লেষণ পান।',
        },
        {
            title: 'জিওফেন্স ভিত্তিক উপস্থিতি এবং চেক-ইন রিপোর্ট',
            description: 'স্ট্যান্ডার্ড অপারেটিভ উপস্থিতি এবং ছুটির ব্যবস্থাপনার মাধ্যমে দলের দক্ষতা বৃদ্ধি করুন।',
        },
        {
            title: 'কার্যকরভাবে অর্ডার/সংগ্রহ পরিচালনা করুন',
            description:
                'বুদ্ধিমান অর্ডার কৌশল এবং সময়মতো সংগ্রহের মাধ্যমে ত্রুটিগুলি কমান এবং উৎপাদনশীলতা, গ্রাহক সন্তুষ্টি বাড়ান।',
        },
        {
            title: 'এন্ড টু এন্ড টাস্ক ম্যানেজমেন্ট',
            description:
                'প্রকল্প পরিকল্পনাকে সরল করুন, সহজে টাস্ক বরাদ্দ করুন এবং আপনার দলের সদস্যদের মধ্যে বাস্তব-সময় সহযোগিতা উত্সাহিত করুন।',
        },
        {
            title: 'অটোমেটেড রিপোর্টের ভিত্তিতে অন্তর্দৃষ্টি',
            description: 'কর্মচারী সম্পৃক্ততা চিত্রিত করুন এবং নিখুঁত দলের বিশ্লেষণ করুন।',
        },
        {
            title: 'অলওয়েন্স ভিত্তিক খরচ পরিচালনা করুন',
            description: 'উপস্থিতি শীটে রেকর্ড অনুযায়ী অনলাইন পেআউট বিতরণের জন্য HR সিস্টেম পরিচালনা করুন।',
        },
    ],
    whatWeDoList: [
        'উপস্থিতি লগ',
        'জিও লোকেশন ট্র্যাকার',
        'ক্লাউড স্টোরেজ',
        'অ্যালার্ট এবং নোটিফিকেশন',
        'প্রকল্প ব্যবস্থাপনা',
        'বিক্রয় ফানেল ব্যবস্থাপনা',
    ],
    featureTitle: 'বৈশিষ্ট্য',
    featureSubTitle:
        'আমাদের বৈশিষ্ট্যগুলি সহজ, শক্তিশালী এবং যেকোনো শিল্পের জন্য সহজেই উপযুক্ত, যা সংগঠনের উৎপাদনশীলতা বৃদ্ধি করে।',
    featureCards: [
        {
            title: 'উপস্থিতি মনিটরিং',
            description: 'অবস্থান সহ উপস্থিতি চিহ্নিতকরণ এবং কাজের ঘন্টা ট্র্যাকিং',
        },
        {
            title: 'খরচ ব্যবস্থাপনা',
            description: 'খরচ সঠিকভাবে ট্র্যাক এবং রেকর্ড করা হয়',
        },
        {
            title: 'ওয়ার্কফোর্স ট্র্যাকিং',
            description: 'কর্মচারীদের কার্যকলাপ, উৎপাদনশীলতা মনিটরিং এবং রেকর্ডিং',
        },
        {
            title: 'ফিল্ড সেলস অ্যানালিটিক্স',
            description: 'বিক্রয় ডেটা সংগ্রহ করুন এবং বিক্রয় কর্মক্ষমতা মূল্যায়ন করুন',
        },
        {
            title: 'অর্ডার ব্যবস্থাপনা',
            description: 'বিভিন্ন কাস্টমাইজেবল কৌশল দ্বারা অর্ডার/সংগ্রহ ব্যবস্থাপনা',
        },
        {
            title: 'লাইভ রিপোর্টিং',
            description: 'আপনার মাঠের কর্মচারীরা কোথায় আছেন এবং তারা কী কাজ করছে জানুন',
        },
    ],
    integrationTitle: 'শিল্প',
    integrationSubTitle: 'প্রতিটি শিল্পের জন্য কর্মশক্তি ব্যবস্থাপনা এবং উৎপাদনশীলতার সেতুবন্ধন',
    orderManagementSubtitle: 'অর্ডার ব্যবস্থাপনা সিস্টেমের চেয়ে বেশি',
    orderManagementTitle:
        'আপনার বিক্রয় দলের জন্য সঠিক অর্ডার এবং সংগ্রহ ব্যবস্থাপনার মাধ্যমে অটোমেট, ট্র্যাক এবং বিতরণ করুন।',
    orderManagementAccordian: [
        {
            title: 'ইলেকট্রনিক অর্ডার এন্ট্রি',
            points: [
                'অর্ডার স্থাপন থেকে বিতরণ পর্যন্ত অর্ডার স্ট্যাটাসের বাস্তব-সময় ট্র্যাকিং।',
                'অর্ডার ক্যাপচার করে এবং ম্যানুয়াল ত্রুটি কমিয়ে প্রক্রিয়াটি ডিজিটাইজ করুন।',
            ],
        },
        {
            title: 'অটোমেটেড ইনভয়সিং',
            points: [
                'অর্ডার বিবরণ অনুযায়ী অটোমেটিক্যালি ইনভয়স তৈরি করুন।',
                'দ্রুত প্রসেসিং এবং পেমেন্টের জন্য ইলেকট্রনিক্যালি ইনভয়স পাঠান।',
                'ইনভয়সিংয়ের বিরুদ্ধে পেমেন্ট যাচাইকরণের জন্য OTP ভিত্তিক নিশ্চিতকরণ।',
            ],
        },
        {
            title: 'বিক্রয় লক্ষ্য',
            points: [
                'বিস্তারিত রিপোর্ট এবং অ্যানালিটিক্সের সাথে লক্ষ্যগুলির বিরুদ্ধে অগ্রগতি ট্র্যাক করুন।',
                'ভূমিকা এবং দায়িত্বের ভিত্তিতে দলের জন্য বিক্রয় লক্ষ্য নির্ধারণ করুন এবং পর্যবেক্ষণ করুন।',
            ],
        },
        {
            title: 'বাস্তব সময় ইনভেন্টরি ট্র্যাকিং',
            points: [
                'প্রতি সময়ে সঠিক স্টক স্তরের সংখ্যা রাখুন এবং অর্ডার তৈরি এবং সম্পন্ন করুন।',
                'নিম্ন স্টকহোল্ডে স্বয়ংক্রিয়ভাবে ক্রয় আদেশ তৈরি করার জন্য রিওর্ডার পয়েন্ট সেট করুন।',
            ],
        },
        {
            title: 'অর্ডারের বিতরণ',
            points: [
                'গুদাম থেকে গ্রাহকের কাছে পণ্যের চলাচল পর্যবেক্ষণ করুন, জবাবদিহিতা এবং স্বচ্ছতা নিশ্চিত করুন।',
                'বিক্রয় দলের মধ্যে অগ্রাধিকার এবং অবস্থানের ভিত্তিতে বিতরণ প্রক্রিয়া অপ্টিমাইজ করুন।',
                'তাড়াতাড়ি বিতরণ এবং খরচ অপ্টিমাইজেশনের জন্য AI ভিত্তিক রুট অপটিমাইজেশন।',
            ],
        },
    ],
    whyChooseTraceforce: 'কেন Naavy বেছে নেবেন',
    whyChooseTraceforceSubtitle:
        'প্রশাসনিক কাজ অটোমেট করার, প্রক্রিয়াগুলি সরল করার এবং কর্মচারীদের অংশগ্রহণ বাড়ানোর মাধ্যমে মানব সম্পদ ব্যবস্থাপনায় বিপ্লব।',
    whyChooseTraceForceList: [
        'উত্পাদনশীলতা উন্নতি',
        'সময় দক্ষতা',
        '正確তা সহ দাবি',
        'বাস্তব সময় মনিটরিং',
        'সম্পূর্ণ প্রতিক্রিয়া',
        'AI ভিত্তিক অপ্টিমাইজেশন',
        'মানদণ্ড ভিত্তিক কাস্টমাইজেশন',
        'বহু শাখার সহায়তা',
        '২৪x৭ সহায়তা পরিষেবা',
    ],
    calculatorTitle: 'ক্যালকুলেটর',
    calculatorSubtitle:
        'আমাদের বৈশিষ্ট্যগুলি সহজ, শক্তিশালী এবং যেকোনো শিল্পের জন্য সহজেই উপযুক্ত, যা সংগঠনের উৎপাদনশীলতা বৃদ্ধি করে।',
    workingHours: 'কর্মঘণ্টা',
    uncountedHours: 'গণনা করা হয়নি ঘন্টা',
    dailySavings: 'প্রতিদিনের সঞ্চয়',
    monthlySavings: 'মাসিক সঞ্চয়',
    productivityGrowth: 'উৎপাদনশীলতার বৃদ্ধি',
    numberOfEmployees: 'কর্মচারীর সংখ্যা',
    faqs: 'প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী',
    frequentlyAskedQuestions: 'প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী',
    faqLine: 'আমাদের গ্রাহকদের জন্য কিছু মৌলিক প্রশ্ন এখানে দেওয়া হয়েছে',
    faqAccordian: [
        {
            question: 'আমি কীভাবে আমার প্রতিষ্ঠান সাইন আপ করতে পারি?',
            ans: 'আপনি আমাদের মোবাইল অ্যাপ বা ওয়েব অ্যাপের মাধ্যমে Naavy এ ৩টি সহজ পদক্ষেপে সাইন আপ করতে পারেন। শুধু আপনার নাম, ইমেইল এবং GST নম্বর যোগ করুন এবং আপনি প্রস্তুত।',
        },
        {
            question: 'আমি কীভাবে আমার লগইন প্রমাণীকরণ করতে পারি?',
            ans: 'Naavy আপনাকে অনেক Clock In অপশন প্রদান করে। উদাহরণস্বরূপ, আমাদের ডিফল্ট মোবাইল লগইন অপশন, সেলফি ভিত্তিক অপশন এবং সবচেয়ে নিরাপদ AI ভিত্তিক ফেস প্রমাণীকরণ।',
        },
        {
            question: 'উপস্থিতি কীভাবে চিহ্নিত করবেন?',
            ans: 'ব্যবহারকারী শুধুমাত্র সেই জিওফেন্সের মধ্যে উপস্থিত থাকলে উপস্থিতি চিহ্নিত করতে পারবেন যা তাকে বরাদ্দ করা হয়েছে। এছাড়াও, প্রক্সি প্রতিরোধ করতে আমরা বিভিন্ন প্রমাণীকরণ পদ্ধতি স্থাপন করেছি।',
        },
        {
            question: 'আমরা কি স্থান নকল করতে পারি বা টাস্ক সম্পন্ন বা উপস্থিতি চিহ্নিত করার সময় পরিবর্তন করতে পারি?',
            ans: 'Naavy যেকোনো স্প্যাম বা অননুমোদিত কার্যকলাপ সনাক্ত করে এবং অ্যাপ্লিকেশন নিষ্ক্রিয় করবে এবং ব্যবহারকারীকে নোটিফিকেশন পাঠাবে।',
        },
        {
            question: 'Naavy কি দূরবর্তী কর্মচারীদের সমর্থন করে?',
            ans: 'Naavy ঘণ্টাভিত্তিক চুক্তি কর্মচারীদের সমর্থন করে এবং তাই নির্ধারিত ঘণ্টা বা নমনীয় ঘণ্টার শিফটের ভিত্তিতে পেরোল হিসাব করে।',
        },
        {
            question: 'আমি কি আমার কর্মশক্তি মোবাইলের মাধ্যমে ট্র্যাক করতে পারি?',
            ans: 'Naavy আপনাকে মোবাইল এবং ওয়েব উভয়ের মাধ্যমে আপনার কর্মশক্তি ট্র্যাক এবং পরিচালনা করতে সহায়তা করে, তাই আপনাকে একটি বিস্তারিত দিনের দৃষ্টিভঙ্গি প্রদান করে।',
        },
        {
            question: 'HRMS ছাড়া Naavy কি অন্যান্য পরিষেবা অফার করে?',
            ans: 'Naavy একটি খুব বিস্তৃত পরিষেবার তালিকা প্রদান করে যা একটি ব্যবস্থাপক বা ব্যবসার মালিকের কাজ সহজ করে এবং শুধুমাত্র প্রয়োজনীয় বিজ্ঞপ্তিগুলি সহায়তা করে।',
        },
    ],

    referAndGet: 'রেফার করুন এবং পান',
    referAndGetH2: 'Naavy বার্ষিক প্যাকেজে ৮% ছাড় পান!',
    referAndGetp:
        'কাউকে Naavy-এ রেফার করুন এবং তাদের যোগদানের পর আপনার বার্ষিক প্যাকেজে ৮% ছাড় পান! সুবিধাগুলি ভাগ করুন এবং একসাথে সঞ্চয় করুন!',
};
export default homePage;
