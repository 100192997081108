const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Гибкие планы, которые растут вместе с вами',
    subFlexiblePlansThatGrowsWithYou:
        'Выберите план ценообразования, который соответствует потребностям вашего бизнеса. Naavy предлагает ценовые пакеты по оптимальным ценам, но с превосходной ценностью.',
    pricingPlans: 'Ценовые планы',
    pricingThatWorksForEveryone: 'Ценообразование, которое подходит всем',
    monthly: 'Ежемесячно',
    annualy: 'Ежегодно',
    international: 'Международный',
    domestic: 'Внутренний',
    month: 'Месяц',
    benifits: 'Преимущества',
    allPlanIncludeTheseBenifits: 'Все планы включают эти преимущества',
    someBenfitsOfMonthlyYearlySubscription: 'Некоторые преимущества ежемесячной/ежегодной подписки',
    benifitsArr: [
        {
            title: 'Техническая поддержка',
            description: 'Наша профессиональная техническая поддержка поможет вам на каждом этапе.',
        },
        {
            title: 'Технологии',
            description: 'Специальное обучение для начала работы с платформой от профессионалов.',
        },
        {
            title: 'Анализ роста',
            description: 'Посвященная команда предоставит вам инсайты о вашем ежемесячном росте.',
        },
        {
            title: 'Награда',
            description: 'Особая награда для лучшего аккаунта каждый месяц.',
        },
    ],
    fAQs: 'Часто задаваемые вопросы',
    frequentlyAskedQuestions: 'Часто задаваемые вопросы',
    someBaTypeOfQuestions: 'Вот некоторые типичные вопросы от наших клиентов',
    fAQArr: [
        {
            question: 'Как я могу зарегистрировать свою компанию?',
            ans: 'Вы можете зарегистрироваться в Naavy за 3 простых шага через наше мобильное приложение или веб-приложение. Просто добавьте свое имя, email и номер GST, и вы готовы начать.',
        },
        {
            question: 'Какие способы аутентификации моего входа доступны?',
            ans: 'Naavy предоставляет несколько вариантов входа, например, у нас есть стандартный мобильный вход, вход с использованием селфи и, наконец, самый безопасный способ — аутентификация по лицу с использованием ИИ.',
        },
        {
            question: 'Как отметить присутствие?',
            ans: 'Пользователь может отметить присутствие только тогда, когда он находится в назначенной геозоне. Кроме того, для предотвращения прокси мы используем различные методы аутентификации.',
        },
        {
            question:
                'Можем ли мы подделать местоположение или изменить время для завершенной задачи или отмеченного присутствия?',
            ans: 'Naavy обнаруживает любую спам-активность или несанкционированные действия и отключает приложение, а также уведомляет пользователя.',
        },
        {
            question: 'Поддерживает ли Naavy удаленных сотрудников?',
            ans: 'Naavy поддерживает сотрудников по контракту на почасовой основе и рассчитывает заработную плату на основе фиксированных или гибких часов.',
        },
        {
            question: 'Могу ли я отслеживать свою рабочую силу через мобильный телефон?',
            ans: 'Naavy помогает вам отслеживать и управлять своей рабочей силой через мобильный телефон и веб, показывая подробное отображение дня в виде таймлайна.',
        },
        {
            question: 'Какие другие услуги предоставляет Naavy, помимо HRMS?',
            ans: 'Naavy предлагает широкий спектр услуг, которые облегчают работу менеджеров или владельцев бизнеса и помогают им получать только необходимые уведомления.',
        },
    ],
};
export default pricingPage;
