const products = {
    attendanceManagement: {
        productName: 'Gerenciamento de Presença',
        titleDescription: 'Configuração Fácil, Registro Automático de Entrada e Saída, Relatórios',
        productCards: [
            {
                title: 'Equilíbrio Entre Trabalho e Vida',
                data: 'Cuide do equilíbrio entre trabalho e vida dos seus funcionários com os lembretes do Naavy sobre vida profissional e pessoal.',
            },
            {
                title: 'Tipos de Membros',
                data: 'Simplifique a gestão do espaço de trabalho com papéis de membros como gerente executivo, gerente de projeto, gerente de equipe e visualizadores de projeto.',
            },
            {
                title: 'Ajuda a Rastrear',
                data: 'As planilhas de horas inteligentes do Naavy tornam o gerenciamento de tarefas mais simples, permitindo que você visualize horas ativas, horas ociosas e produtividade em uma folha detalhada.',
            },
        ],
        whyChooseSubCaption:
            'Transição para um Ambiente de Trabalho Altamente Eficiente e Experimente Produtividade e Colaboração Aprimoradas. Centralize os dados nos cartões.',
        headerCaption: 'Por que escolher o Gerenciamento de Presença Naavy',
        headerT1: 'Recursos de Rastreamento de Presença que Fazem Muito Mais',
        subCaptionT1:
            'Experimente o rastreamento de funcionários, monitoramento de trabalho remoto, monitoramento de produtividade, gerenciamento de presença, planilhas de horas e mais — tudo com uma camada de funcionalidades avançadas.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'Monitoramento de Presença',
                featureDescription:
                    'Informações em tempo real sobre o registro de entrada/saída e a presença ou ausência de indivíduos dentro da área do Geofence ou Filial.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área do Geofence ou Filial',
                    'Monitoramento das horas de trabalho com base em Diário, Semanal e Mensal',
                    'Acesso remoto a relatórios de presença de várias filiais',
                    'Consolidação de dados, processos e sistemas relacionados à presença em uma única plataforma ou sistema centralizado',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Presença em Tempo Real',
                        paragraph:
                            'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área do Geofence ou Filial',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'Cálculo Total das Horas de Trabalho',
                        paragraph: 'Monitoramento das horas de trabalho com base em Diário, Semanal e Mensal',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Acesso Remoto aos Relatórios de Presença',
                        paragraph: 'Acesso remoto a relatórios de presença de várias filiais',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoramento de Presença Um',
                        description: [
                            'Ganhe Transparência sobre a Pontualidade dos Funcionários',
                            'Economize tempo e organize a presença dos funcionários sem ações adicionais.',
                            'Monitore facilmente se seus funcionários chegaram ao local de trabalho a tempo e concluíram suas horas de trabalho.',
                            'Mantenha suas operações no local de trabalho mais organizadas e tenha um ambiente de trabalho altamente responsável.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoramento de Presença Dois',
                        description: [
                            'Análise Inteligente dos Dados de Presença',
                            'Filtros para saída antecipada, chegada tardia e menos rastreamento que você pode selecionar na lista suspensa.',
                            'Isso ajudará você a gerar instantaneamente as informações desejadas sem ter que procurá-las manualmente.',
                            'Você pode rastrear a presença diariamente ou escolher um intervalo no calendário.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Monitoramento de Presença Três',
                        description: [
                            'Uma maneira segura e transparente de monitorar e rastrear a presença dos funcionários em tempo real com um sistema totalmente sem contato.',
                            'Configuração Fácil',
                            'REGISTROS AUTOMATIZADOS DE ENTRADA E SAÍDA, RELATÓRIOS DETALHADOS',
                            'CAPTURAS DE TELA COM TEMPO DE RASTREAMENTO',
                            'REGISTRO DE NÍVEL DE ATIVIDADE',
                            'MONITORAMENTO DE USO DE APLICATIVOS E SITE',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'Gerenciamento de Turnos',
                featureDescription:
                    'Organize e agende as horas de trabalho que otimizam a produtividade, atendem às necessidades operacionais e garantem cobertura em diferentes períodos de tempo.',
                fIIPoints: [
                    'Desenvolva um cronograma de turnos claro e bem organizado. Defina diferentes tipos de turnos com base na natureza do seu negócio.',
                    'Analise a carga de trabalho e as tarefas para determinar o número de funcionários necessários durante diferentes turnos.',
                    'Plataforma centralizada para que os funcionários acessem seus cronogramas e estabeleçam um canal de comunicação confiável para quaisquer atualizações ou mudanças.',
                    'Supervisão e controle centralizados dos cronogramas dos funcionários, permitindo que os gerentes aloque eficientemente turnos, rastreiem a presença e gerenciem recursos de força de trabalho em várias localizações a partir de uma única plataforma.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'Gerenciamento Baseado em Geofence',
                featureDescription:
                    'Tecnologia baseada em localização para monitorar e verificar a presença dos funcionários dentro de limites geográficos pré-definidos.',
                fIIPoints: [
                    'Registros precisos de presença para garantir que os funcionários estejam presentes em seus locais de trabalho designados.',
                    'Implemente rastreamento em tempo real para monitorar as localizações dos funcionários dentro do geofence.',
                    'Estabeleça limites geográficos específicos, conhecidos como geofences, ao redor do local de trabalho ou locais de presença designados.',
                    'Configure alertas ou notificações para informar gerentes ou pessoal de RH quando os funcionários entram ou saem dos limites do geofence.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'Opções Múltiplas de Registro de Entrada',
                featureDescription:
                    'Ofereça flexibilidade aos funcionários para registrar suas horas de trabalho usando scanners biométricos, aplicativos móveis, relógios de ponto e plataformas baseadas na web.',
                fIIPoints: [
                    'Adequado para organizações com várias localizações. Opções flexíveis de registro de entrada para gerenciamento de presença em campo e fora de campo de diferentes filiais.',
                    'Ofereça aos funcionários acesso a um portal de autoatendimento para registros de presença e gerencie suas entradas e saídas dentro do geofence.',
                    'As opções de registro de entrada flexíveis incluem digitalizações biométricas, aplicativos móveis, cartões RFID e reconhecimento facial, garantindo rastreamento de tempo contínuo em vários ambientes de trabalho.',
                    'Registro automático de entrada para funcionários dentro do alcance das instalações do escritório com notificação de alerta.',
                ],
            },
            attendanceReport: {
                featureHeader: 'Relatórios de Presença',
                featureDescription:
                    'Resuma os dados de presença dos funcionários, horas trabalhadas, ausências, chegadas tardias e saídas antecipadas dentro de um período específico.',
                fIIPoints: [
                    'Forneça um resumo geral da presença, incluindo o número total de dias trabalhados, horas totais trabalhadas e quaisquer horas extras.',
                    'Acesse remotamente os relatórios de presença de várias filiais com categorias personalizáveis.',
                    'Exiba os saldos de licença de cada funcionário, incluindo dias de férias, licença médica e quaisquer outros tipos de licença acumulados e utilizados.',
                    'Permita que os usuários personalizem relatórios com base em critérios específicos, como departamento, equipe ou funcionário individual.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'Gerenciamento de Força de Campo',
        titleDescription: 'Operações de Vendas e Serviços de Campo com Relatórios ao Vivo e Roteiro de Visitas',
        productCards: [
            {
                title: 'Custos Reduzidos',
                data: 'Desfrute de custos operacionais reduzidos ao automatizar os horários dos funcionários. Acompanhe o tempo, as tarefas e as despesas dos funcionários para identificar áreas onde você pode economizar.',
            },
            {
                title: 'Grande Valor para o Cliente',
                data: 'Defina garantias de cronograma e serviço eficiente. Acompanhe as interações e o feedback dos clientes para identificar facilmente lacunas e áreas de melhoria.',
            },
            {
                title: 'Análise de Desempenho',
                data: 'Gere relatórios abrangentes em painéis unificados, fornecendo informações valiosas sobre o desempenho da sua força de trabalho, cronograma de trabalho, satisfação do cliente e áreas para melhoria.',
            },
        ],
        whyChooseSubCaption:
            'Eficiência Máxima com o Software de Gerenciamento de Força de Campo\nSubtítulo - Acompanhe seus funcionários de campo em tempo real, monitore o progresso das tarefas e obtenha insights sobre sua localização, atividade e desempenho. Melhore a responsabilidade e utilize a tomada de decisões baseada em dados.',
        headerCaption: 'Por que escolher o Gerenciamento de Força de Campo Naavy',
        headerT1: 'Destaques dos Sistemas de Gerenciamento de Força de Campo',
        subCaptionT1:
            'Aproveite o rastreamento em tempo real da localização dos funcionários combinado com otimização baseada em IA para agilizar as operações, relatórios ao vivo para insights imediatos e utilize a análise de vendas de campo para melhorar o desempenho. Garanta alocação eficiente de recursos, produtividade aprimorada e tomada de decisões baseada em dados.',
        features: {
            workforceTracking: {
                featureHeader: 'Rastreamento da Força de Trabalho',
                featureDescription:
                    'Monitoramento e gerenciamento sistemático das atividades, produtividade e presença dos funcionários.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Monitore eventos como entrada e saída, além de obter insights sobre as operações e métricas de desempenho dos funcionários.',
                    'Armazene dados para reuniões e tarefas para rastrear tendências e tomar decisões baseadas em dados para atas de reunião.',
                    'Gerenciamento de clientes e leads para rastrear a produtividade dos funcionários e indicadores-chave de desempenho com base na quantidade de trabalho concluído.',
                    'Rastreie com precisão a distância percorrida pelos funcionários para trabalho de campo com base em diário, semanal e mensal e reembolse despesas conforme a distância e locais percorridos.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Rastreamento da Presença Baseado em Linha do Tempo',
                        paragraph:
                            'Presença e movimentação monitoram eventos como entrada e saída, além de obter insights sobre as operações e métricas de desempenho dos funcionários.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulários Personalizados para Gestão de Dados',
                        paragraph: 'Monitore as horas de trabalho com base em Diário, Semanal e Mensal.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Acesso Remoto aos Relatórios de Presença',
                        paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoramento de Presença Um',
                        description: [
                            'Ganhe Transparência sobre a Pontualidade dos Funcionários',
                            'Economize tempo e organize a presença dos funcionários sem ações adicionais.',
                            'Monitore facilmente se seus funcionários chegaram ao local de trabalho a tempo e concluíram suas horas de trabalho.',
                            'Mantenha suas operações no local de trabalho mais organizadas e tenha um ambiente de trabalho altamente responsável.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoramento de Presença Dois',
                        description: [
                            'Análise Inteligente dos Dados de Presença',
                            'Filtros para saída antecipada, chegada tardia e menos rastreamento que você pode selecionar na lista suspensa.',
                            'Isso ajudará você a gerar instantaneamente as informações desejadas sem ter que procurá-las manualmente.',
                            'Você pode rastrear a presença diariamente ou escolher um intervalo no calendário.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'Monitoramento de Presença Três',
                        description: [
                            'Uma maneira segura e transparente de monitorar e rastrear a presença dos funcionários em tempo real com um sistema totalmente sem contato.',
                            'Configuração Fácil',
                            'REGISTROS AUTOMATIZADOS DE ENTRADA E SAÍDA, RELATÓRIOS DETALHADOS',
                            'CAPTURAS DE TELA COM TEMPO DE RASTREAMENTO',
                            'REGISTRO DE NÍVEL DE ATIVIDADE',
                            'MONITORAMENTO DE USO DE APLICATIVOS E SITE',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'Análise de Vendas de Campo',
                featureDescription:
                    'Rastreie e analise métricas-chave da equipe de vendas em campo, como receita de vendas, interações com clientes e tendências geográficas.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Indicadores-chave de desempenho podem ajudar na tomada de decisões baseadas em dados, o que pode ajudar na decisão produtiva para a próxima vez ou repaginar a estratégia.',
                    'Relatórios podem ser acessados diariamente, semanalmente ou mensalmente sobre Vendas, Leads e Aquisição de Clientes, o que pode ajudar na produtividade e eficiência da pessoa.',
                    'Valide a visita com check-ins baseados em Geofence para precisão. Coordenadas da localização podem ser anexadas a uma foto da câmera ao compartilhar.',
                    'O Roteiro de Visitas pode ser usado para planejar visitas ou planos de visitas para funcionários a clientes. Integre seu plano de visitas diretamente no Maps para otimizar a navegação.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'Presença Baseada em Localização',
                featureDescription:
                    'Presença baseada em geofence para garantir que os funcionários possam registrar entrada ou saída apenas quando estiverem dentro dos limites geográficos predefinidos.',
                fIIPoints: [
                    'Implemente limites virtuais geofenciados ao redor de locais específicos das instalações do escritório. Ajudando a automatizar a presença com base na entrada ou saída de uma área designada.',
                    'Métodos de autenticação para garantir a precisão e segurança dos dados de presença usando PINs, biometria ou presença facial.',
                    'Garanta que os registros de presença sejam atualizados prontamente, permitindo decisões de gestão oportunas.',
                    'Considere métodos alternativos para rastreamento de presença para funcionários remotos.',
                ],
            },
            liveReporting: {
                featureHeader: 'Relatórios ao Vivo',
                featureDescription:
                    'Rastreamento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
                fIIPoints: [
                    'O administrador ou gerente pode se inscrever ou optar pelas notificações que deseja receber. Isso ajudará a focar nas notificações que realmente deseja receber.',
                    'Fique Notificado sobre Presença e Licença. Mantenha-se um passo à frente e conheça o número de funcionários antes, para que o planejamento do dia possa ser mais produtivo e eficiente.',
                    'Acompanhe todas as Aprovações. Acompanhe todas as aprovações, desde reembolsos até licenças, e visualize-as posteriormente.',
                    'Rastreamento Remoto por meio de Formulários e Atas de Reunião. Formulários personalizados podem ajudar a obter dados específicos para uma reunião que pode ser acessada remotamente.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'Otimização Baseada em IA',
                featureDescription:
                    'Otimizar e automatizar o planejamento de rotas para representantes de campo ou pessoal de vendas com base no roteiro de visitas baseado em IA.',
                fIIPoints: [
                    'Fontes de dados em tempo real, como atualizações de tráfego ao vivo, condições climáticas e fechamentos de estradas, para ajustar dinamicamente as rotas com base nas circunstâncias atuais, ajudando no planejamento diário eficiente.',
                    'Visualize rotas otimizadas e faça quaisquer ajustes necessários. Isso pode fazer parte de um aplicativo móvel ou de uma plataforma baseada na web.',
                    'Sistema de IA para considerar as características únicas de diferentes rotas ou percursos.',
                    'Utilize serviços de mapeamento e dados geoespaciais para entender a rede de estradas, condições de tráfego e outras informações relacionadas à localização.',
                ],
            },
            taskManagement: {
                featureHeader: 'Gestão de Tarefas',
                featureDescription:
                    'Organização, priorização e execução eficiente das atividades para alcançar objetivos ou metas específicas.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Monitore o progresso em tempo real visualizando quadros de tarefas, verificando o status das tarefas e rastreando o tempo gasto.',
                    'Recursos de colaboração na gestão de tarefas para se comunicar com os membros da equipe em tempo real. Comente em tarefas, faça perguntas e forneça atualizações para manter todos informados.',
                    'Gerenciamento de clientes e leads para rastrear a produtividade dos funcionários e indicadores-chave de desempenho com base na quantidade de trabalho concluído.',
                    'Estrutura hierárquica para organizar objetivos. Metas do projeto, objetivos departamentais, metas de desempenho individual, iniciativas estratégicas, etc.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'Gestão de Folha de Pagamento e Licença',
        titleDescription: 'Otimize o processamento da folha de pagamento e a administração de licenças',
        headerCaption: 'Por que escolher o CRM de Gestão de Leads Naavy',
        productCards: [
            {
                title: 'Facilidade de Gestão',
                data: 'Simplifique a gestão centralizando informações dos clientes, automatizando tarefas rotineiras e agilizando a comunicação.',
            },
            {
                title: 'Personalização',
                data: 'Personalização',
            },
            {
                title: 'Integrações',
                data: 'Habilite integrações para compartilhamento de dados, automação de tarefas e melhoria da colaboração entre equipes, levando a uma implementação de CRM mais eficiente e eficaz.',
            },
        ],
        whyChooseSubCaption:
            'Acompanhe e nutra leads de forma eficiente, garantindo que nenhuma oportunidade seja perdida e melhorando as taxas de conversão.',
        headerT1: 'Benefícios do Sistema de Gestão de Leads Naavy',
        subCaptionT1:
            'Automação e Distribuição de Captura de Leads\nIntegração e Relatórios de Dados em Tempo Real\nAutomação de Ferramentas de Vendas e Marketing',
        features: {
            leadManagement: {
                featureHeader: 'Processamento de Folha de Pagamento',
                featureDescription:
                    'Acompanhamentos pontuais, interações personalizadas e taxas de conversão maximizadas.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Aprimore o seu processo de gestão de leads com base no tempo e na localização da força de vendas, facilitando a conquista de metas em um período mais curto.',
                    'Use os históricos de contatos para criar segmentos com base em visitas ao site, interações por e-mail e mais. Segmente a força de vendas com base nesses dados para gerenciar leads.',
                    'Utilize pontuações de leads para atribuir prospects à sua equipe de vendas ou configure fluxos de trabalho para notificar representantes quando seus prospects estiverem prontos para conversão.',
                    'Acesse facilmente o histórico de cada lead, incluindo informações da empresa e um cronograma de cada ponto de contato entre o contato e sua empresa.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Rastreamento da Presença Baseado em Linha do Tempo',
                        paragraph:
                            'Presença e movimentação monitoram eventos como entrada e saída, além de obter insights sobre as operações e métricas de desempenho dos funcionários.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulários Personalizados para Gestão de Dados',
                        paragraph: 'Monitore as horas de trabalho com base em Diário, Semanal e Mensal.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Acesso Remoto aos Relatórios de Presença',
                        paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoramento de Presença Um',
                        description: [
                            'Ganhe Transparência sobre a Pontualidade dos Funcionários',
                            'Economize tempo e organize a presença dos funcionários sem ações adicionais.',
                            'Monitore facilmente se seus funcionários chegaram ao local de trabalho a tempo e concluíram suas horas de trabalho.',
                            'Mantenha suas operações no local de trabalho mais organizadas e tenha um ambiente de trabalho altamente responsável.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoramento de Presença Dois',
                        description: [
                            'Análise Inteligente dos Dados de Presença',
                            'Filtros para saída antecipada, chegada tardia e menos rastreamento que você pode selecionar na lista suspensa.',
                            'Isso ajudará você a gerar instantaneamente as informações desejadas sem ter que procurá-las manualmente.',
                            'Você pode rastrear a presença diariamente ou escolher um intervalo no calendário.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Monitoramento de Presença Três',
                        description: [
                            'Uma maneira segura e transparente de monitorar e rastrear a presença dos funcionários em tempo real com um sistema totalmente sem contato.',
                            'Configuração Fácil',
                            'REGISTROS AUTOMATIZADOS DE ENTRADA E SAÍDA, RELATÓRIOS DETALHADOS',
                            'CAPTURAS DE TELA COM TEMPO DE RASTREAMENTO',
                            'REGISTRO DE NÍVEL DE ATIVIDADE',
                            'MONITORAMENTO DE USO DE APLICATIVOS E SITE',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'Gestão de Licenças',
                featureDescription:
                    'Gerencie e acompanhe eficientemente o número de funcionários, garantindo a documentação adequada, aderência às políticas da empresa e manutenção da continuidade do fluxo de trabalho.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Gerencie o acúmulo de férias, garantindo que os funcionários não excedam os valores permitidos e evitando saldos negativos de licença.',
                    'Configure políticas de licença flexíveis da organização configurando o Calendário de Licenças. Habilite a Configuração Hierárquica Paralela ou Sequencial para Aprovação ou Rejeição de Pedidos de Licença.',
                    'Mantenha-se informado sobre leis e regulamentos trabalhistas relacionados aos direitos de licença. Garanta que suas políticas de gestão de licença estejam em conformidade com as políticas da empresa.',
                    'Ofereça aos funcionários opções de autoatendimento, permitindo que eles verifiquem seus saldos de licença, enviem solicitações e visualizem o status de suas solicitações.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'Gestão de Pedidos',
        titleDescription: 'Simplifique as operações de pedidos/coleções com uma solução escalável e automatizada.',
        headerCaption: 'Por que escolher o CRM de Gestão de Leads Naavy',
        productCards: [
            {
                title: 'Facilidade de Gestão',
                data: 'Simplifique a gestão centralizando informações dos clientes, automatizando tarefas rotineiras e agilizando a comunicação.',
            },
            {
                title: 'Personalização',
                data: 'Personalização',
            },
            {
                title: 'Integrações',
                data: 'Habilite integrações para compartilhamento de dados, automação de tarefas e melhoria da colaboração entre equipes, levando a uma implementação de CRM mais eficiente e eficaz.',
            },
        ],
        whyChooseSubCaption:
            'Acompanhe e nutra leads de forma eficiente, garantindo que nenhuma oportunidade seja perdida e melhorando as taxas de conversão.',
        headerT1: 'Benefícios do Sistema de Gestão de Leads Naavy',
        subCaptionT1:
            'Automação e Distribuição de Captura de Leads\nIntegração e Relatórios de Dados em Tempo Real\nAutomação de Ferramentas de Vendas e Marketing',
        features: {
            digitizationOfOrder: {
                featureHeader: 'Digitalização de Pedidos',
                featureDescription: 'Otimize a gestão de pedidos de ponta a ponta e reduza erros manuais.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Gere relatórios detalhados e análises para monitorar o desempenho do processamento de pedidos e a produtividade dos funcionários.',
                    'Capture e valide automaticamente os detalhes dos pedidos inseridos por funcionários ou clientes.',
                    'Forneça atualizações em tempo real sobre o status dos pedidos e rastreamento de remessas para funcionários e clientes.',
                    'Automatize os processos de aprovação de pedidos e atribua tarefas aos funcionários apropriados.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Rastreamento da Presença Baseado em Linha do Tempo',
                        paragraph:
                            'Presença e movimentação monitoram eventos como entrada e saída, além de obter insights sobre as operações e métricas de desempenho dos funcionários.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulários Personalizados para Gestão de Dados',
                        paragraph: 'Monitore as horas de trabalho com base em Diário, Semanal e Mensal.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Acesso Remoto aos Relatórios de Presença',
                        paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoramento de Presença Um',
                        description: [
                            'Ganhe Transparência sobre a Pontualidade dos Funcionários',
                            'Economize tempo e organize a presença dos funcionários sem ações adicionais.',
                            'Monitore facilmente se seus funcionários chegaram ao local de trabalho a tempo e concluíram suas horas de trabalho.',
                            'Mantenha suas operações no local de trabalho mais organizadas e tenha um ambiente de trabalho altamente responsável.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoramento de Presença Dois',
                        description: [
                            'Análise Inteligente dos Dados de Presença',
                            'Filtros para saída antecipada, chegada tardia e menos rastreamento que você pode selecionar na lista suspensa.',
                            'Isso ajudará você a gerar instantaneamente as informações desejadas sem ter que procurá-las manualmente.',
                            'Você pode rastrear a presença diariamente ou escolher um intervalo no calendário.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Monitoramento de Presença Três',
                        description: [
                            'Uma maneira segura e transparente de monitorar e rastrear a presença dos funcionários em tempo real com um sistema totalmente sem contato.',
                            'Configuração Fácil',
                            'REGISTROS AUTOMATIZADOS DE ENTRADA E SAÍDA, RELATÓRIOS DETALHADOS',
                            'CAPTURAS DE TELA COM TEMPO DE RASTREAMENTO',
                            'REGISTRO DE NÍVEL DE ATIVIDADE',
                            'MONITORAMENTO DE USO DE APLICATIVOS E SITE',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'Gestão de Metas',
                featureDescription:
                    'Defina metas claras e alcançáveis para cada funcionário com base em seu papel e capacidades.',
                featureParagraph:
                    'Chega de cartões de ponto, papelada excessiva e documentos variados para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
                fIIPoints: [
                    'Alcance metas diárias, semanais e mensais e mantenha registros das conquistas dos funcionários com rastreamento de campo ao vivo.',
                    'Progresso em Tempo Real (número de metas alcançadas versus restantes), Horários de Check-in/Check-out, Número de horas (duração) gasto em cada tarefa e rastreamento de localização.',
                    'Métricas de Desempenho (taxa de conclusão, tempo gasto, pontuações de satisfação do cliente), Comparação com Metas (desempenho real versus meta), Dados Históricos (tendências de desempenho passado).',
                    'Envie notificações automatizadas para manter os funcionários informados sobre Alertas de Conquista de Metas, Lembretes de Desempenho, Avisos de Prazo.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'Gestão de Faturas',
                featureDescription:
                    'Crie e acompanhe o status das faturas em tempo real com alertas e notificações automatizadas.',
                fIIPoints: [
                    'Configure sistemas para rastrear o status das faturas e enviar lembretes e alertas automatizados para pagamentos devidos e atrasados.',
                    'Modelos de faturas padronizados que incluem todos os detalhes necessários, como informações do cliente, detalhes do pedido, impostos, descontos e termos de pagamento.',
                    'Colete assinaturas digitais e prova de entrega com base em OTP como parte do processo de fatura.',
                    'Sincronização Automática com Sistema Central (armazenamento em nuvem) e Atualizações em Tempo Real sobre o Status da Fatura.',
                ],
            },
            distributorOrder: {
                featureHeader: 'Pedido de Distribuição',
                featureDescription:
                    'Gerencie produtos/coleções e priorize tarefas com atualizações em tempo real sobre o inventário.',
                fIIPoints: [
                    'Crie e processe pedidos com todos os detalhes relevantes para cada distribuição e atribua tarefas com base na disponibilidade.',
                    'Integre com a gestão de inventário para garantir a alocação precisa de estoque e atualize os níveis de inventário em tempo real.',
                    'Configure o sistema para enviar notificações automatizadas em etapas-chave do processo de pedido.',
                    'Acesse o painel onde os funcionários podem verificar pedidos, rastrear progresso e baixar documentos necessários.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'Gestão de Inventário',
                featureDescription:
                    'Equilibre corretamente o estoque com atualizações e rastreamento em tempo real para dados precisos.',
                fIIPoints: [
                    'Garanta uma integração contínua entre os sistemas de inventário e gestão de pedidos para sincronizar os níveis de estoque com os pedidos de entrada e saída.',
                    'Rastreie o inventário em vários armazéns, lojas ou locais e acesse controle centralizado, otimize a distribuição de estoque e melhore a visibilidade.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'Gestão de Pedidos / Coleta',
                featureDescription:
                    'Simplifique as operações de pedidos/coleta com uma solução escalável e automatizada.',
                fIIPoints: [
                    'Um sistema centralizado acessível por funcionários no local e fora do local para gerenciar pedidos, rastrear progresso e atualizar status em tempo real.',
                    'Diferentes níveis de acesso e permissões com base em funções (por exemplo, gerentes, funcionários no local, funcionários fora do local) para garantir segurança dos dados e acesso apropriado.',
                    'Certifique-se de que todos os dados dos pedidos estejam sincronizados em tempo real em todos os dispositivos e locais, para que todos tenham acesso às informações mais recentes.',
                    'Facilite a comunicação entre funcionários no local e fora do local por meio de mensagens no aplicativo e notificações.',
                ],
            },
        },
    },
};
export default products;
