const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'તમારા સાથે વધતાં લવચીક યોજનાઓ',
    subFlexiblePlansThatGrowsWithYou:
        'તમારા વ્યવસાયની જરૂરિયાતોને પહોંચીવળતા પ્રાઇસિંગ પ્લાન પસંદ કરો. ટ્રેસફોર્સ ઑપ્ટિમલ કિંમતો પર ઉત્તમ મૂલ્ય સાથે પેકેજ ઓફર કરે છે.',
    pricingPlans: 'મૂલ્યયોજના',
    pricingThatWorksForEveryOne: 'પ્રતિશ્રુતિ એ તમામ માટે કાર્ય કરે છે',
    monthly: 'માસિક',
    annualy: 'વાર્ષિક',
    international: 'આંતરરાષ્ટ્રીય',
    domestic: 'ઘરે',
    month: 'માસ',
    benifits: 'લાભ',
    allPlanIncludeTheseBenifits: 'સઘળા યોજનાઓમાં આ લાભો સમાવેશ થાય છે',
    someBenfitsOfMonthlyYearlySubscription: 'માસિક/વાર્ષિક સબ્સ્ક્રિપ્શનના કેટલાક લાભો',
    benifitsArr: [
        {
            title: 'ટેકનિકલ સપોર્ટ',
            description: 'અમારી વ્યાવસાયિક ટેકનિકલ સપોર્ટ ટીમ તમારી દરેક પગલાંમાં મદદ કરશે.',
        },
        {
            title: 'ટેક્નોલોજી',
            description: 'પ્લેટફોર્મ પર શરૂ કરવા માટે વિશેષ તાલીમ.',
        },
        {
            title: 'વિકાસ વિશ્લેષણ',
            description: 'તમારા માસિક વિકાસને લઈને વ્યાપક ટીમ દ્વારા દૃષ્ટિ.',
        },
        {
            title: 'પ્રેમ',
            description: 'દરેક મહિને શ્રેષ્ઠ કામગીરી કરનારા ખાતા માટે વિશેષ ઇનામ.',
        },
    ],
    fAQs: 'સામાન્ય રીતે પૂછવામાં આવતા પ્રશ્નો',
    frequentlyAskedQuestions: 'સામાન્ય રીતે પૂછવામાં આવતા પ્રશ્નો',
    someBaTypeOfQuestions: 'અહીં અમારા ગ્રાહકો તરફથી કેટલીક સામાન્ય પ્રકારની પૂછપરછ છે',
    fAQArr: [
        {
            question: 'હું મારી કંપની કેવી રીતે સાઇન અપ કરી શકું?',
            ans: 'તમે Naavy પર 3 સરળ પગલાંમાં સાઇન અપ કરી શકો છો અમારી મોબાઇલ અથવા વેબ એપ્લિકેશન દ્વારા. તમારું નામ, ઇમેઇલ અને GST નંબર ઉમેરો, અને તમે તૈયાર છો.',
        },
        {
            question: 'મારી લોગિનને ઓથેન્ટિકેટ કરવાની અલગ અલગ રીતો શું છે?',
            ans: 'Naavy તમને ઘણી લોગિન વિકલ્પો આપે છે, જેમ કે ડિફૉલ્ટ મોબાઇલ લોગિન, સેલ્ફી આધારિત વિકલ્પ અને સૌથી સલામત AI આધારિત ફેસ ઓથેન્ટિકેશન.',
        },
        {
            question: 'હાજરી કેવી રીતે ચિહ્નિત કરવી?',
            ans: 'યૂઝર માત્ર ત્યારે જ હાજરી ચિહ્નિત કરી શકે છે જ્યારે તે વ્યક્તિ તે માટે નક્કી કરેલા ભૂમિકા વિસ્તારમાં હોય. વધુમાં, પ્રોક્સીથી બચવા માટે વિવિધ ઓથેન્ટિકેશન પદ્ધતિઓ પણ છે.',
        },
        {
            question:
                'શું અમે સ્થાન નકલી કરી શકીએ છીએ અથવા કામ પૂરું થયું અથવા હાજરી ચિહ્નિત કરવામાં સમય બદલી શકીએ છીએ?',
            ans: 'Naavy કોઈપણ સ્પામ અથવા બિનમંગેલ પ્રવૃત્તિને શોધી કાઢે છે, એપ્લિકેશનને અક્ષમ કરે છે અને વપરાશકર્તાને જાણ કરે છે.',
        },
        {
            question: 'Naavy શું દૂરસ્થ કર્મચારીઓને સપોર્ટ કરે છે?',
            ans: 'Naavy કલાકના આધારે કરાર આધારિત કર્મચારીઓને સપોર્ટ કરે છે અને ફિક્સ્ડ કલાક અથવા લવચીક કલાક شف્ટના આધારે પગારની ગણતરી કરે છે.',
        },
        {
            question: 'શું હું મારા વર્કફોર્સને મોબાઇલથી ટ્રેક કરી શકું?',
            ans: 'Naavy તમને મોબાઇલ અને વેબ બંને દ્વારા તમારા કર્મચારીઓનું ટ્રેકિંગ અને મેનેજિંગ કરવા માટે મદદ કરે છે અને સમયશ્રેણી સ્વરૂપે દિનચર્યા દર્શાવે છે.',
        },
        {
            question: 'HRMS સિવાય Naavy શું અન્ય સેવાઓ આપે છે?',
            ans: 'Naavy મેનેજર અથવા બિઝનેસના માલિકનું કામ સરળ બનાવવા માટે અને માત્ર જરૂરી સૂચનાઓમાં મદદ કરવા માટે વ્યાપક સેવાઓની યાદી આપે છે.',
        },
    ],
};
export default pricingPage;
