const industries = {
    pharmaceutical: {
        industryHeading:
            'নিরাপত্তা প্রোটোকল পর্যবেক্ষণ করুন, সময়, উপস্থিতি ট্র্যাক করুন এবং সংবেদনশীল এলাকায় প্রবেশ নিয়ন্ত্রণ করুন কার্যকর প্রকল্প ব্যবস্থাপনা, অফ সাইট কার্যক্রম এবং কর্মক্ষমতা মূল্যায়ন নিশ্চিত করুন।',

        featureHeader: 'ফার্মাসিউটিক্যালস',
        featureParagraph:
            'ফার্মাসিউটিক্যাল/হেলথকেয়ার কোম্পানিগুলির জন্য শ্রমশক্তি ব্যবস্থাপনাকে সহজ করে ফার্মাসিউটিক্যাল-ফোকাসড উপস্থিতি ব্যবস্থাপনা এবং মাঠ ট্র্যাকিং সফটওয়্যার সময় ট্র্যাকিং, মাঠ কর্মীদের জন্য মোবাইল অ্যাক্সেস, মাঠ অপারেশন কমপ্লায়েন্স রিপোর্টিং, এবং বিক্রয় উৎপাদনশীলতার জন্য বিভিন্ন সিস্টেমের সাথে ইন্টিগ্রেশন সরবরাহ করে।',
        keyPoints: [
            {
                header: 'কর্মচারী রেকর্ড, পেরোল এবং কমপ্লায়েন্স রিপোর্টিংয়ের সাথে সম্পর্কিত HR সিস্টেমের সাথে কর্মচারী ট্র্যাকিং সিস্টেম ইন্টিগ্রেট করুন।',
                paragraph: 'ডেইলি, উইকলি এবং মথলি ভিত্তিতে কাজের ঘণ্টা পর্যবেক্ষণ করুন।',
            },
            {
                header: 'শিফট ব্যবস্থাপনা সিস্টেমগুলি ব্যবহার করে শ্রমশক্তি সময়সূচী অপ্টিমাইজ করুন।',
                paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
            },
            {
                header: 'যেখানে ক্রমাগত অপারেশন গুরুত্বপূর্ণ, উত্পাদন এবং উৎপাদন এলাকার সময়সূচী পর্যবেক্ষণ করুন।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'সকল কর্মচারী ট্র্যাকিং সিস্টেম প্রাসঙ্গিক বিধিমালা এবং ফার্মাসিউটিক্যাল শিল্পের মানদণ্ড মেনে চলে তা নিশ্চিত করুন।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
        ],
    },
    education: {
        industryHeading: 'সার্বিক ভর্তি CRM সিস্টেম শিক্ষার্থীদের সহযোগিতা এবং সম্ভাবনার ব্যবস্থাপনার জন্য।',

        featureHeader: 'শিক্ষা',
        featureParagraph:
            'শিক্ষার্থী নিয়োগ এবং ভর্তি \n শিক্ষার্থী সম্পৃক্ততা এবং ধারণ \n একাধিক স্থানে ভর্তি পরিচালনা করুন \n ব্যক্তিগতকৃত শিক্ষার্থী সহায়তা',
        keyPoints: [
            {
                header: 'প্রথম যোগাযোগের বিন্দু থেকে আবেদন প্রক্রিয়া পর্যন্ত সম্ভাব্য শিক্ষার্থীদের ট্র্যাক এবং পরিচালনা করুন।',
                paragraph: 'ডেইলি, উইকলি এবং মথলি ভিত্তিতে কাজের ঘণ্টা পর্যবেক্ষণ করুন।',
            },
            {
                header: 'ভর্তি হওয়ার আগ্রহ প্রকাশকারী সম্ভাব্য শিক্ষার্থীদের চিহ্নিত করুন এবং পরিচালনা করুন। যোগাযোগ এবং অগ্রগতি ট্র্যাক করুন যাতে অনুসন্ধানগুলিকে ভর্তি করা শিক্ষার্থী হিসাবে রূপান্তরিত করা যায়।',
                paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
            },
            {
                header: 'শিক্ষার্থী ভর্তি প্রক্রিয়ার বিভিন্ন স্তর দৃশ্যমান এবং পরিচালনা করুন। অনুসন্ধান, আবেদন, সাক্ষাৎকার, গ্রহণ এবং ভর্তি মতো স্তরের মধ্য দিয়ে প্রার্থীদের সরান।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'কাস্টমাইজেবল অনলাইন ফর্মের মাধ্যমে আবেদন প্রক্রিয়া সহজ করুন। সম্ভাব্য শিক্ষার্থীদের কাছ থেকে প্রয়োজনীয় তথ্য দক্ষতার সাথে সংগ্রহ করুন।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'মুভিং সেলস টিমের কর্মক্ষমতা অপ্টিমাইজ করুন, বাস্তব সময়ের অবস্থান ট্র্যাকিং সক্ষম করুন, কার্যকর রুট পরিকল্পনা এবং সময়মতো গ্রাহক যোগাযোগ নিশ্চিত করুন।',

        featureHeader: 'ফিল্ড সেলস',
        featureParagraph:
            'সঠিক টুলগুলির মাধ্যমে আপনার মাঠের কর্মক্ষমতা ত্বরান্বিত করুন যাতে পরিকল্পনা, রূপান্তর এবং কার্যকরভাবে সম্পাদন করতে পারেন। Naavy মাঠের বিক্রয় ট্র্যাকিং এবং লিড ও CRM পরিচালনাকে সহজ করে তোলে।',
        keyPoints: [
            {
                header: 'বাস্তব সময়ের উপস্থিতি',
                paragraph:
                    'জিওফেন্স এলাকার মধ্যে বা শাখায় ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে বাস্তব সময়ের তথ্য।',
            },
            {
                header: 'মোট কাজের ঘণ্টার হিসাব',
                paragraph: 'ডেইলি, উইকলি এবং মথলি ভিত্তিতে কাজের ঘণ্টা পর্যবেক্ষণ করুন।',
            },
            {
                header: 'দূরবর্তীভাবে উপস্থিতি রিপোর্ট অ্যাক্সেস করুন',
                paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
            },
            {
                header: 'কেন্দ্রীভূত উপস্থিতি ব্যবস্থাপনা',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'পারফরম্যান্স মেট্রিক্স, খরচ ব্যবস্থাপনা এবং কমপ্লায়েন্স ট্র্যাকিং কার্যকর বিক্রয় শক্তি ব্যবস্থাপনার জন্য, যোগাযোগ এবং সহযোগিতা বৈশিষ্ট্য সহ গ্রাহক সম্পৃক্ততা বৃদ্ধি।',
        featureParagraph:
            'বাস্তব সময়ের উপস্থিতি ট্র্যাকিং বাজারের চাহিদার ভিত্তিতে স্টাফিং স্তরের দ্রুত সামঞ্জস্য করতে সক্ষম করে, FMCG অপারেশনগুলির চপলতা উন্নত করে।',
        keyPoints: [
            {
                header: 'সঠিক উপস্থিতি ট্র্যাকিং সাহায্য করে',
                paragraph:
                    'জিওফেন্স এলাকার মধ্যে বা শাখায় ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে বাস্তব সময়ের তথ্য।',
            },
            {
                header: 'সঠিক পেরোল প্রসেসিং, ত্রুটি হ্রাস এবং আর্থিক অসঙ্গতি কমানো।',
                paragraph: 'ডেইলি, উইকলি এবং মথলি ভিত্তিতে কাজের ঘণ্টা পর্যবেক্ষণ করুন।',
            },
            {
                header: 'শ্রম আইন এবং বিধিমালা মেনে চলা নিশ্চিত করা।',
                paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
            },
            {
                header: 'কার্যকর ছুটি ব্যবস্থাপনা সুবিধাজনকভাবে, আন্ডারস্টাফিং সমস্যা প্রতিরোধ।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'অবিচ্ছিন্ন উত্পাদন এবং বিতরণ নিশ্চিত করা।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'সঠিক পুনঃপ্রতিষ্ঠান প্রক্রিয়া নিশ্চিত করা।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'অর্ডার ট্র্যাকিং মোবাইলের মাধ্যমে এবং সম্পাদন।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
        ],
    },
    services: {
        industryHeading:
            'প্রকল্প এবং টাস্ক ব্যবস্থাপনা সহজ করুন, সঠিক সময় এবং উপস্থিতি রেকর্ড নিশ্চিত করুন, পেরোল প্রসেসিং এবং কমপ্লায়েন্স সহায়তা করুন।',
        featureHeader: 'সেবা',
        featureParagraph:
            'ফিল্ড-পরীক্ষিত বৈশিষ্ট্যগুলি উৎপাদনশীলতা বাড়ায়, জটিলতা কমায় এবং টাস্ক অটোমেট করে, আপনার ব্যবসার জন্য সময় এবং সম্পদ বাঁচায়।',
        keyPoints: [
            {
                header: 'বাস্তব সময়ের ফিল্ড স্টাফ GPS ট্র্যাকিং',
                paragraph:
                    'জিওফেন্স এলাকার মধ্যে বা শাখায় ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে বাস্তব সময়ের তথ্য।',
            },
            {
                header: 'কাস্টম ফর্ম এবং জিও-ট্যাগড ফটো',
                paragraph: 'ডেইলি, উইকলি এবং মথলি ভিত্তিতে কাজের ঘণ্টা পর্যবেক্ষণ করুন।',
            },
            {
                header: 'ইনটুইটিভ ড্যাশবোর্ড এবং ডাউনলোডযোগ্য রিপোর্ট',
                paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
            },
            {
                header: 'সংগ্রহ পরিচালনা এবং পর্যবেক্ষণ করুন',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
        ],
    },
    fieldOperations: {
        industryHeading: 'ডেটা চালিত সিদ্ধান্ত গ্রহণ সহজ করুন এবং মাঠ টিম এবং সদর দপ্তরের মধ্যে যোগাযোগ উন্নত করুন।',

        featureHeader: 'ফিল্ড অপারেশনস',
        featureParagraph:
            'অকার্যকর মাঠ অপারেশন এবং সেবা ব্যবস্থাপনাকে বিদায় জানান Naavy সব-একই সমাধানের সাথে। টাস্ক ব্যবস্থাপনা অপ্টিমাইজ করুন, চলমান সময়ে উপস্থিতি ট্র্যাক করুন, উৎপাদনশীলতা এবং বিক্রয় আয় বৃদ্ধি করতে AI ভিত্তিক রুট অপ্টিমাইজেশন করুন।',
        keyPoints: [
            {
                header: 'টাস্ক নির্ধারণ এবং পর্যবেক্ষণ করুন',
                paragraph:
                    'জিওফেন্স এলাকার মধ্যে বা শাখায় ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে বাস্তব সময়ের তথ্য।',
            },
            {
                header: 'মাঠ কর্মচারীদের সকলের বাস্তব সময়ের অবস্থান ট্র্যাক করুন',
                paragraph: 'ডেইলি, উইকলি এবং মথলি ভিত্তিতে কাজের ঘণ্টা পর্যবেক্ষণ করুন।',
            },
            {
                header: 'খরচ দাবিগুলি যাচাই এবং প্রক্রিয়া করুন',
                paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
            },
            {
                header: 'SLA টার্গেট সেট করুন এবং বিলম্ব পর্যবেক্ষণ করুন',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'ইনটুইটিভ ড্যাশবোর্ড এবং ডাউনলোডযোগ্য রিপোর্ট',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'OTP দ্বারা নিশ্চিতকৃত টাস্ক সম্পাদন',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading: 'ডেটা চালিত সিদ্ধান্ত গ্রহণ সহজ করুন এবং মাঠ টিম এবং সদর দপ্তরের মধ্যে যোগাযোগ উন্নত করুন।',

        featureHeader: 'লজিস্টিকস এবং পরিবহন',
        featureParagraph:
            'লজিস্টিকস এবং বিতরণকে আরও সহজভাবে সংগঠিত এবং সহজ করুন। শক্তিশালী এবং উন্নত বৈশিষ্ট্যগুলির সাথে বৃহৎ শ্রমশক্তির লজিস্টিকস পরিচালনা করুন।',
        keyPoints: [
            {
                header: 'ডেলিভারি কর্মীর অবস্থানের বাস্তব সময় ট্র্যাকিং পান।',
                paragraph:
                    'জিওফেন্স এলাকার মধ্যে বা শাখায় ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে বাস্তব সময়ের তথ্য।',
            },
            {
                header: 'কার্যক্রম এবং কর্মপ্রবাহ পর্যবেক্ষণের মাধ্যমে অপারেশনাল দক্ষতা উন্নত করুন।',
                paragraph: 'ডেইলি, উইকলি এবং মথলি ভিত্তিতে কাজের ঘণ্টা পর্যবেক্ষণ করুন।',
            },
            {
                header: 'শ্রমশক্তির টাস্ক ব্যবস্থাপনা সহজ করুন।',
                paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
            },
            {
                header: 'বিতরণ রুট অপ্টিমাইজ করার জন্য ঐতিহাসিক ডেটা এবং বাস্তব সময়ের ট্রাফিক তথ্য বিশ্লেষণ করুন।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'স্বয়ংক্রিয় পুনঃপ্রতিষ্ঠান এবং পেরোল প্রসেসিং।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
            {
                header: 'স্বয়ংক্রিয় মাঠের কাজের মাধ্যমে উন্নত গ্রাহক সেবা।',
                paragraph:
                    'একটি একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির সংহতি।',
            },
        ],
    },
};
export default industries;
