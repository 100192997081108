const homePage = {
    heroHeader: 'Drive sales excellence and operational efficiency through',
    heroHeaderTypist: ['Attendance Management', 'Field Tracking Monitoring', 'CRM'],
    heroHeaderSubText:
        'Real-time Location based Attendance, Live Tracking, Task and Order Management, Data Insights and API services',
    moreProductivityLessExpenses: 'More Productivity, Less Expenses',
    keyFeatures: [
        {
            title: 'Lens',
            description:
                'Touchless Facial Attendance Clockin and Clock out with AI Assistance. Mark attendance using your mobile camera.',
        },
        {
            title: 'Web App',
            description:
                'User friendly dashboard , HR and manager teams can easily access detailed reports, manage employee records, track performance and check on payroll',
        },
        {
            title: 'Hierarchy based control',
            description:
                'Hierarchy based control system to accommodate various organisational levels including administrative,hr managers, supervisors and employees',
        },
        {
            title: 'Route Optimization ',
            description:
                'Enhance efficiency and reduce operational costs with dynamic route calculations,multi-stop optimization, cost estimation and real time updates',
        },
    ],
    features: 'Features',
    ourFeatures:
        'Our features are simple, powerful and an easy fit to any industry to increase the organization’s productivity.',
    ourFeaturesObject: [
        {
            title: 'Attendance Monitoring',
            description: 'Attendance marking with location & track working hours',
        },
        {
            title: 'Expense Management',
            description: 'Expenses are tracked and recorded in an accurate manner',
        },

        {
            title: 'Workforce Tracking',
            description: 'Monitoring and recording employee activities, productivity',
        },
        {
            title: 'Payroll management',
            description: 'Optimize payroll processing and accurate payouts',
        },
        {
            title: 'Order Management',
            description: 'Process of managing orders/collections through multiple customisable strategies',
        },
        {
            title: 'Route optimization',
            description: 'Finding the most efficient path to save time, fuel, and resources.',
        },
    ],
    whatWeDoQuestion: 'What Does Naavy do?',
    whatWeDoCards: [
        {
            title: 'Analyse Employees Activities in Real Time',
            description: 'Get a detailed analysis of the teams’ activity on a daily or hourly basis.',
        },
        {
            title: 'GeoFence based Attendance & Checkin Reports',
            description: 'Strengthen team’s efficiency with standard operative attendance & leave management.',
        },
        {
            title: 'Effectively Manage Orders/Collections',
            description:
                'Minimize errors while boosting productivity, customer satisfaction with smart order strategies and timely collections.',
        },
        {
            title: 'End to End Task Management',
            description:
                'Streamline project planning, assign tasks effortlessly, and foster real-time collaboration among your team members.',
        },
        {
            title: 'Insights Based on Automated Reports',
            description: 'Visualize employee engagement and perform a flawless team analysis',
        },
        {
            title: 'Manage Expenses Based on Allowance',
            description:
                'Manage HR systems for distributing online payslips as per the records in the attendance sheets.',
        },
    ],
    whatWeDoList: [
        'Attendance Logs',
        'Geo Location Tracker',
        'Cloud Storage',
        'Alerts and Notification',
        'Project Management',
        'Sales Funnel Management',
    ],
    featureTitle: 'Features',
    featureSubTitle:
        'Our features are simple, powerful and an easy fit to any industry to increase the organization’s productivity.',
    featureCards: [
        {
            title: 'Attendance Monitoring',
            description: 'Attendance marking with location & track working hours',
        },
        {
            title: 'Expense Management',
            description: 'Expenses are tracked and recorded in an accurate manner',
        },
        {
            title: 'Workforce Tracking',
            description: 'Monitoring and recording employee activities, productivity',
        },
        {
            title: 'Field Sales Analytics',
            description: 'Gather sales data and gauge sales performance',
        },
        {
            title: 'Order Management',
            description: 'Process of managing orders/collections through multiple customisable strategies',
        },
        {
            title: 'Live Reporting',
            description: 'Know where your field employees are and what work they are engaged',
        },
    ],
    integrationTitle: 'INDUSTRIES',
    integrationSubTitle: 'Bridging Workforce Management and Productivity for Every Industries',
    orderManagementSubtitle: 'More than just an order management system',

    orderManagementTitle:
        'Automate,track and deliver with precise order and collection management for your sales team.',
    orderManagementAccordian: [
        {
            title: 'Electronic Order Entry',
            points: [
                'Real-time tracking of order status from placement to delivery.',
                ' Digitize process by capturing orders and reducing manual errors.',
            ],
        },
        {
            title: 'Automated Invoicing',
            points: [
                'Generate invoices automatically on the go based on order details.',
                'Send invoices electronically for faster processing and payment.',
                'OTP based verification of payment done against invoicing',
            ],
        },
        {
            title: 'Sales target',
            points: [
                'Track progress against targets with detailed reports and analytics.',
                'Set and monitor sales targets for teams based on the roles and responsibilities.',
            ],
        },
        {
            title: 'Real time inventory tracking',
            points: [
                'Keep an accurate count of stock levels every time and order is generated and completed.',
                'Set reorder points to automatically generate purchase orders on low stockhold.',
            ],
        },
        {
            title: 'Distribution of Order',
            points: [
                'Monitor the movement of goods from warehouse to customer, ensuring accountability and transparency',
                'Optimize the distribution process between sales team based upon priority and location',
                'AI based route optimization for faster delivery and cost optimization',
            ],
        },
    ],
    whyChooseTraceforce: 'Why Choose Naavy',
    whyChooseTraceforceSubtitle:
        'Revolutionising Human Resources management by automating administrative tasks, streamlining processes, and enhancing employee engagement.',
    whyChooseTraceForceList: [
        'Productivity Improvement',
        'Time Efficiency',
        'Claims with precision',
        'Real Time Monitoring',
        'Fully Responsive',
        'AI based Optimization',
        'Criteria Based Customization',
        'Multiple Branch Support',
        '24x7 Support Service',
    ],
    calculatorTitle: 'Calculator',
    calculatorSubtitle:
        'Our features are simple, powerful, and an easy fit to any industry to increase the organization’s productivity.',
    workingHours: 'Working Hours',
    uncountedHours: 'Uncounted Hours',
    dailySavings: 'daily Savings',
    monthlySavings: 'Monthly Savings',
    productivityGrowth: 'Productivity Growth',
    numberOfEmployees: 'Number Of Employees',
    faqs: 'FAQs',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    faqLine: 'Here are some of the basic types of questions for our customers',
    faqAccordian: [
        {
            question: 'How can I sign up my firm ?',
            ans: 'You can sign up on Naavy in 3 easy steps on our Mobile app or Web app. Just add your name, email and GST number and you are good to go.',
        },
        {
            question: 'What are the different ways by which I can authenticate my Log in?',
            ans: 'Naavy provides you with multiple Clock In option For example we have default mobile Log in option, Selfie based option and last but not least the most secure AI based Face authentication.',
        },
        {
            question: 'How to mark Attendance?',
            ans: 'User can mark attendance only when the person is in the allocated Geofence assigned to him. Also to safeguard proxy we have different authentication methods in place',
        },
        {
            question: 'Can we mock Location or change time for Task completed or attendance marked?',
            ans: 'Naavy detects any spam or unsolicited activity in place and would disable the application and also notify the user.',
        },
        {
            question: 'Does Naavy support remote employees?',
            ans: 'Naavy does support on Contract employees on Hourly basis and hence calculation payroll on the basis of Fixed Hour or Flexible Hour shift.',
        },
        {
            question: 'Can I track my workforce through mobile?',
            ans: 'Naavy helps you in tracking and managing your workforce through mobile and web both in Timeline manner. Hence giving you a comprehensive details day’s view.',
        },
        {
            question: 'What are the other service Naavy has to offer except from HRMS?',
            ans: 'Naavy offers a very comprehensive list of services to make a manager or business owners job easy and help them with only the required notifications.',
        },
    ],
    referAndGet: 'Refer and Get',
    referAndGetH2: 'Get 8% OFF on Naavy Annual Packages!',
    referAndGetp:
        'Refer a friend or client to Naavy and get 8% OFF your annual package once they join us! Share the benefits and save together!',
};
export default homePage;
