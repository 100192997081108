const joinUsPage = {
    joinUsString: 'Присоединяйтесь к нам',
    joinUsHeroPara:
        'Станьте частью нашей команды в Naavy! Исследуйте захватывающие возможности, где командная работа и креативность ведут к успеху. Узнайте, как вы можете внести свой вклад в нашу миссию по Empowering бизнеса с помощью интуитивно понятных программных решений. Развивайте свою карьеру в поддерживающей среде, которая ценит ваши уникальные таланты и идеи.',
    ourRecruitmentProcess: 'Наш процесс набора',
    ourBenifitsString: 'Наши преимущества',
    ourRecruitmentProcessObjects: [
        {
            title: 'Техническое интервью',
            description:
                'Это интервью предназначено для оценки ваших технических навыков, способностей к решению проблем и того, как хорошо вы применяете свои знания к реальным сценариям. Мы хотим получить представление о вашей технической компетенции, кодировании и подходе к решению задач. Этот этап проводится через встречу/видеозвонок в Skype.',
        },
        {
            title: 'Практический тест (аптитюд)',
            description:
                'Мы оцениваем вашу кодировочную логику и оптимизацию кода, присваивая задачу, которая будет отправлена по электронной почте после вашего первого раунда в зависимости от вашего интервью. Задание будет иметь определенный срок выполнения, в течение которого кандидат должен его завершить.',
        },
        {
            title: 'Интервью с руководством',
            description:
                'Краткий разговор с нашим руководством для понимания вашего карьерного видения и культурной совместимости с нашей организацией. Мы ищем человека с твердыми знаниями в своей области и сильной трудовой этикой.',
        },
        {
            title: 'Окончательный отбор',
            description:
                'Окончательное предложение будет сделано с HR-командой. Мы делаем окончательный выбор на основе глубокой оценки результатов кандидата на всех этапах интервью. На основе взаимного решения будут подтверждены даты начала работы, и мы предоставим вам лучшие инструменты с первого дня.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'Работа из любой точки!',
            description:
                "Похоже, что снова к столу! Потому что ваш домашний офис немного слишком дружелюбен к собакам, а ваша 'комната для встреч' в данный момент занята сном кота. Мы не предлагаем работу из любой точки, потому что нам нравится видеть вас каждый день.",
        },
        {
            name: 'Чередующиеся выходные',
            description:
                'Когда ваши выходные такие же чередующиеся, как и ваши настроения. На одной неделе вы планируете веселое путешествие или догоняете сон, а на следующей вы сталкиваетесь с дедлайнами и отвечаете на электронные письма. Это все часть приключения в балансе между работой и отдыхом.',
        },
        {
            name: 'Медицинская страховка',
            description:
                "Для всех тех моментов 'мне, возможно, нужен доктор'. С полным покрытием вам не придется беспокоиться о 'что если' — сосредоточьтесь на том, чтобы выздороветь и вернуться в свою лучшую форму. Потому что, когда вы не чувствуете себя на высоте, вы заслуживаете лучшего ухода под рукой.",
        },
        {
            name: 'Бонус за рекомендации',
            description:
                'Есть друзья? Получите бонусы! Это выигрыш для всех. Представьте себе: у вас есть круг друзей, которые великолепны в своей работе и ищут новые возможности. Рекомендуя их в нашу компанию, вы не только помогаете им найти отличную работу, но и пополняете свой карман сладким бонусом за рекомендацию.',
        },
        {
            name: 'Комплект сотрудника',
            description:
                'Комплект сотрудника - Все, что вам нужно для покорения рабочего дня, кроме клона себя. От необходимых офисных принадлежностей до мотивационных подарков, этот комплект содержит все. Нужна ручка? Проверьте. Блокнот для всех ваших гениальных идей? Проверьте.',
        },
        {
            name: 'Гибкий график работы',
            description:
                'Больше не нужно спешить домой, чтобы успеть на любимое шоу; теперь ваш офис может быть вашим диваном, а ваше время перерыва - это лучшее время. С гибким графиком вы можете легко переходить от конференц-звонков к захватывающим моментам, от таблиц Excel к просмотру сериалов на Netflix.',
        },
        {
            name: 'Саббатический отпуск',
            description: 'Саббатический отпуск',
        },
        {
            name: 'Отпуск по уходу за ребенком (отцовский/материнский)',
            description: 'Отпуск по уходу за ребенком (отцовский/материнский)',
        },
        {
            name: 'Баланс между работой и личной жизнью',
            description: 'Баланс между работой и личной жизнью',
        },
        {
            name: 'Офисные праздники',
            description: 'Офисные праздники',
        },
    ],
    jobsAtTraceForce: 'Работа в Naavy',
};
export default joinUsPage;
