const products = {
    attendanceManagement: {
        productName: 'Gestion des Présences',
        titleDescription: 'Configuration Facile, Enregistrement Automatisé, Rapports',
        productCards: [
            {
                title: 'Équilibre Travail-Vie',
                data: "Prenez soin de l'équilibre travail-vie de vos employés avec les rappels de Naavy concernant le travail et la vie personnelle.",
            },
            {
                title: 'Types de Membres',
                data: "Simplifiez la gestion des espaces de travail avec des rôles de membres tels que responsable exécutif, chef de projet, responsable d'équipe et spectateurs de projet.",
            },
            {
                title: 'Aide au Suivi',
                data: "Les feuilles de temps intelligentes de Naavy rendent la gestion des tâches plus simple en vous permettant de voir les heures actives, les heures d'inactivité et la répartition des heures productives dans un format détaillé.",
            },
        ],
        whyChooseSubCaption:
            'Passez à un environnement de travail hautement efficace et découvrez une productivité et une collaboration améliorées. Centralisez les données dans les cartes.',
        headerCaption: 'Pourquoi Choisir Naavy Gestion des Présences',
        headerT1: 'Fonctionnalités de Suivi des Présences Qui Font Beaucoup Plus',
        subCaptionT1:
            'Découvrez le suivi des employés, la surveillance du travail à distance, la gestion de la productivité, la gestion des présences, les feuilles de temps et plus encore — le tout avec une couche de fonctionnalités avancées.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'Suivi des Présences',
                featureDescription:
                    "Informations en temps réel sur les heures d'entrée/sortie concernant la présence ou l'absence des individus dans la zone de géorepérage ou la succursale.",
                featureParagraph:
                    'Fini les cartes de temps, les documents excessifs et les différents documents pour vérifier les présences des employés. Automatisez le processus de suivi des présences avec WebWork Time Tracker et voyez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    "Informations en temps réel sur la présence ou l'absence des individus dans la zone de géorepérage ou la succursale",
                    'Surveillez les heures de travail en fonction des jours, semaines et mois',
                    'Accédez aux rapports de présence de plusieurs succursales à distance',
                    'Consolidation des données, processus et systèmes liés aux présences en une seule plateforme centralisée',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Présence en Temps Réel',
                        paragraph:
                            "Informations en temps réel sur la présence ou l'absence des individus dans la zone de géorepérage ou la succursale",
                    },
                    totalWorkingHoursCalculation: {
                        header: 'Calcul des Heures de Travail Totales',
                        paragraph: 'Surveillez les heures de travail en fonction des jours, semaines et mois',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Accès à Distance aux Rapports de Présence',
                        paragraph: 'Accédez aux rapports de présence de plusieurs succursales à distance',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Suivi des Présences Un',
                        description: [
                            'Obtenez une Transparence sur la Ponctualité des Employés',
                            'Gagnez du temps et organisez la présence des employés sans aucune action supplémentaire.',
                            "Surveillez facilement si vos employés sont arrivés au travail à l'heure et ont complété leurs heures de travail ou non.",
                            'Gardez vos opérations de travail plus organisées et ayez un environnement de travail hautement responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Suivi des Présences Deux',
                        description: [
                            'Analyse Intelligente des Données de Présence',
                            'Filtrez les départs précoces, les arrivées tardives et les suivis insuffisants que vous pouvez sélectionner à partir de la liste déroulante.',
                            'Cela vous aidera à générer instantanément les informations souhaitées sans avoir à les rechercher manuellement.',
                            'Vous pouvez suivre les présences quotidiennement ou choisir un intervalle à partir du calendrier.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Suivi des Présences Trois',
                        description: [
                            'Méthode sécurisée et transparente pour surveiller et suivre la présence des employés en temps réel avec un système 100% sans contact.',
                            'Configuration Facile',
                            'Enregistrements Automatisés des Entrées et Sorties Rapports Détaillés',
                            "Captures d'Écran avec Temps de Suivi",
                            "Enregistrement du Niveau d'Activité",
                            "Surveillance des Applications et de l'Utilisation du Site Web",
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'Gestion des Quartiers',
                featureDescription:
                    'Organisez et planifiez les heures de travail pour optimiser la productivité, répondre aux besoins opérationnels et garantir une couverture pendant différentes périodes.',
                fIIPoints: [
                    'Développez un planning de quart clair et bien organisé. Définissez différents types de quarts en fonction de la nature de votre entreprise.',
                    "Analysez la charge de travail et les tâches pour déterminer le nombre d'employés nécessaires pendant les différents quarts.",
                    "Plateforme centralisée permettant aux employés d'accéder à leurs horaires et établissez un canal de communication fiable pour toute mise à jour ou modification.",
                    "Supervision et contrôle centralisés des horaires des employés, permettant aux gestionnaires d'attribuer les quarts, de suivre les présences et de gérer les ressources humaines à travers plusieurs sites depuis une seule plateforme.",
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'Présence Basée sur le Géorepérage',
                featureDescription:
                    'Technologie basée sur la localisation pour surveiller et vérifier la présence des employés dans des limites géographiques prédéfinies.',
                fIIPoints: [
                    'Enregistrements de présence précis pour garantir que les employés sont présents à leurs lieux de travail désignés.',
                    'Mettez en œuvre un suivi en temps réel pour surveiller les emplacements des employés dans le géorepérage.',
                    'Établissez des limites géographiques spécifiques, connues sous le nom de géorepérages, autour du lieu de travail ou des lieux de présence désignés.',
                    'Configurez des alertes ou des notifications pour informer les gestionnaires ou le personnel RH lorsque les employés entrent ou sortent des limites définies du géorepérage.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'Options Multiples de Pointage',
                featureDescription:
                    'Offrez une flexibilité aux employés pour enregistrer leurs heures de travail en utilisant des lecteurs biométriques, des applications mobiles, des horloges de temps et des plateformes web.',
                fIIPoints: [
                    'Convient aux Organisations avec Plusieurs Sites Options de pointage flexibles pour la gestion des présences sur le terrain et hors terrain des différentes succursales',
                    'Offrez aux employés un accès à un portail libre-service des enregistrements de présence et gérez leurs entrées et sorties dans le géorepérage.',
                    'Les options de pointage flexibles incluent les scans biométriques, les applications mobiles, les cartes RFID et la reconnaissance faciale assurant un suivi du temps sans faille dans divers environnements de travail.',
                    "Marquage automatique de la présence pour les employés dans la zone des locaux avec notification d'alerte.",
                ],
            },
            attendanceReport: {
                featureHeader: 'Rapports de Présence',
                featureDescription:
                    'Résume les données de présence des employés, les heures de travail, les absences, les arrivées tardives et les départs anticipés dans un délai spécifique.',
                fIIPoints: [
                    'Fournit un résumé général de la présence, y compris le nombre total de jours travaillés, le total des heures travaillées et toutes les heures supplémentaires.',
                    'Accédez à distance aux rapports de présence de plusieurs succursales avec des catégories personnalisables.',
                    'Affiche les soldes de congés pour chaque employé, y compris les jours de vacances, les congés maladie et tout autre type de congé accumulé et utilisé.',
                    "Permet aux utilisateurs de personnaliser les rapports en fonction de critères spécifiques, tels que le département, l'équipe ou l'employé individuel.",
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'Gestion de la Force de Terrain',
        titleDescription: 'Opérations de Vente et de Services sur le Terrain avec Reporting en Direct et Plan de Route',
        productCards: [
            {
                title: 'Réduction des Coûts',
                data: "Profitez de coûts opérationnels réduits en automatisant les emplois du temps des employés. Suivez le temps, les tâches et les dépenses des employés pour identifier les domaines où vous pouvez économiser de l'argent.",
            },
            {
                title: 'Grande Valeur Client',
                data: 'Établissez une garantie de planning et un service efficace. Suivez les interactions et les retours des clients pour identifier facilement les lacunes et les domaines à améliorer.',
            },
            {
                title: 'Analyse de Performance',
                data: `Générez facilement des rapports complets sur des tableaux de bord unifiés, fournissant des informations précieuses sur la performance de votre main-d'œuvre, le calendrier des tâches, la satisfaction client et les domaines à améliorer.`,
            },
        ],
        whyChooseSubCaption:
            "Efficacité Maximale avec le Logiciel de Gestion de la Force de Terrain\nSous-titre - Suivez vos employés de terrain en temps réel, surveillez l'avancement des tâches et obtenez des informations sur leur emplacement, leurs activités et leurs performances. Améliorez la responsabilité et utilisez la prise de décision basée sur les données.",
        headerCaption: 'Pourquoi Choisir Naavy Gestion de la Force de Terrain',
        headerT1: 'Points Forts des Systèmes de Gestion de la Force de Terrain',
        subCaptionT1:
            "Exploitez le suivi en temps réel de l'emplacement des employés combiné à l'optimisation basée sur l'IA pour rationaliser les opérations, le reporting en direct pour des informations immédiates, et utilisez les analyses de ventes sur le terrain pour améliorer la performance. Assurez une allocation efficace des ressources, une productivité accrue et une prise de décision basée sur les données.",

        features: {
            workforceTracking: {
                featureHeader: "Suivi de la Main-d'Œuvre",
                featureDescription:
                    'Suivi systématique et gestion des activités, de la productivité et de la présence des employés.',
                featureParagraph:
                    'Fini les cartes de temps, les documents excessifs et les différents documents pour vérifier la présence des employés. Automatisez le processus de suivi des présences avec WebWork Time Tracker et visualisez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    'Surveillez les événements tels que les entrées et les sorties, ainsi que les aperçus des employés sur leurs opérations, leurs indicateurs de performance ou leurs événements.',
                    'Stockez les données pour les réunions et les tâches afin de suivre les tendances et prendre des décisions basées sur les données pour les procès-verbaux de réunion.',
                    'Gestion des clients et des prospects pour suivre la productivité des employés. Les indicateurs de performance clés peuvent être mesurés en fonction de la quantité de travail accompli par les employés.',
                    'Suivez la distance exacte parcourue par les employés pour le travail sur le terrain sur une base quotidienne, hebdomadaire et mensuelle, et remboursez les dépenses en fonction des distances et des lieux parcourus.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Suivi de la Présence Basé sur le Calendrier',
                        paragraph:
                            'Surveillance de la présence et des mouvements, tels que les entrées et les sorties, ainsi que des aperçus des employés sur leurs opérations, leurs indicateurs de performance ou leurs événements.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulaires Personnalisés pour la Gestion des Données',
                        paragraph:
                            'Surveillez les heures de travail sur une base quotidienne, hebdomadaire et mensuelle.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Accès à Distance aux Rapports de Présence',
                        paragraph: 'Accédez aux rapports de présence de plusieurs succursales à distance.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Suivi des Présences Un',
                        description: [
                            'Obtenez une transparence sur la ponctualité des employés.',
                            'Gagnez du temps et organisez la présence des employés sans action supplémentaire.',
                            "Surveillez facilement si vos employés sont arrivés à l'heure au travail et ont complété leurs heures de travail.",
                            'Gardez vos opérations de travail plus organisées et créez un environnement de travail hautement responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Suivi des Présences Deux',
                        description: [
                            'Analyse intelligente des données de présence.',
                            'Filtres pour départs précoces, arrivées tardives et suivis insuffisants que vous pouvez sélectionner à partir de la liste déroulante.',
                            'Cela vous aidera à générer instantanément les informations souhaitées sans avoir à les rechercher manuellement.',
                            'Vous pouvez suivre les présences quotidiennement ou choisir un intervalle à partir du calendrier.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'Suivi des Présences Trois',
                        description: [
                            'Méthode sécurisée et transparente pour surveiller et suivre la présence des employés en temps réel avec un système 100% sans contact.',
                            'Configuration facile',
                            'ENREGISTREMENTS AUTOMATISÉS DES ENTRÉES ET SORTIES RAPPORTS DÉTAILLÉS',
                            "CAPTURES D'ÉCRAN AVEC TEMPS DE SUIVI",
                            "ENREGISTREMENT DU NIVEAU D'ACTIVITÉ",
                            "SURVEILLANCE DES APPLICATIONS ET DE L'UTILISATION DU SITE WEB",
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'Analyse des Ventes sur le Terrain',
                featureDescription:
                    "Suivez et analysez les indicateurs clés de l'équipe de vente sur le terrain, tels que les revenus de vente, les interactions avec les clients et les tendances géographiques.",
                featureParagraph:
                    'Fini les cartes de temps, les documents excessifs et les différents documents pour vérifier la présence des employés. Automatisez le processus de suivi des présences avec WebWork Time Tracker et visualisez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    'Les indicateurs clés de performance peuvent aider à prendre des décisions basées sur les données, ce qui peut aider à prendre des décisions productives pour la prochaine fois ou à réviser la stratégie.',
                    "Les rapports peuvent être consultés quotidiennement, hebdomadairement ou mensuellement sur les ventes, les prospects et l'acquisition de clients. Cela pourrait aider à la productivité et à l'efficacité des personnes.",
                    "Validez la visite avec des enregistrements basés sur des géofences pour garantir l'exactitude. Les coordonnées du lieu peuvent être attachées à une photo de caméra lors du partage.",
                    'Le Plan de Route peut être utilisé pour planifier les visites ou le plan de route des employés pour les visites clients. Intégrez directement votre plan de route dans les cartes pour optimiser la navigation.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'Présence Basée sur la Localisation',
                featureDescription:
                    "Présence basée sur des géofences pour garantir que les employés peuvent uniquement pointer leur entrée ou sortie lorsqu'ils se trouvent dans les limites géographiques prédéfinies.",
                fIIPoints: [
                    "Mettez en place des limites virtuelles géofencées autour de lieux spécifiques dans les locaux de l'entreprise. Cela aide à automatiser la présence en fonction de l'entrée ou de la sortie d'un employé dans une zone désignée.",
                    "Méthodes d'authentification pour garantir l'exactitude et la sécurité des données de présence en utilisant des PINs, des méthodes biométriques ou la reconnaissance faciale.",
                    'Assurez-vous que les enregistrements de présence sont mis à jour rapidement, permettant ainsi des décisions de gestion opportunes.',
                    'Envisagez des méthodes alternatives pour le suivi de la présence des employés à distance.',
                ],
            },
            liveReporting: {
                featureHeader: 'Reporting en Temps Réel',
                featureDescription:
                    "Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches complètes ou l'avancement des projets.",
                fIIPoints: [
                    "Les administrateurs ou les managers peuvent s'abonner ou choisir les notifications qu'ils souhaitent recevoir. Cela aidera à se concentrer sur les notifications vraiment importantes.",
                    'Restez informé sur la présence et les congés. Soyez proactif et connaissez le nombre de personnes présentes avant pour que la planification de la journée soit plus productive et efficace.',
                    'Suivez toutes les approbations, de la demande de remboursement aux congés, et consultez-les ultérieurement.',
                    'Suivi à distance via des formulaires et des comptes rendus de réunion. Les formulaires personnalisés peuvent aider à extraire des données spécifiques pour une réunion, accessibles à distance.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: "Optimisation Basée sur l'IA",

                featureDescription:
                    "Optimisez et automatisez la planification des itinéraires pour les représentants sur le terrain ou le personnel de vente en fonction des itinéraires optimisés par l'IA.",
                fIIPoints: [
                    'Sources de données en temps réel, telles que les mises à jour du trafic, les conditions météorologiques et les fermetures de routes, pour ajuster dynamiquement les itinéraires en fonction des circonstances actuelles, ce qui aide à une planification quotidienne efficace.',
                    "Consultez les itinéraires optimisés et apportez les ajustements nécessaires. Cela peut faire partie d'une application mobile ou d'une plateforme web.",
                    "Système d'IA prenant en compte les caractéristiques uniques des différents itinéraires ou parcours.",
                    "Utilisez les services de cartographie et les données géospatiales pour comprendre le réseau routier, les conditions de circulation et d'autres informations liées aux lieux.",
                ],
            },
            taskManagement: {
                featureHeader: 'Gestion des Tâches',
                featureDescription:
                    'Organiser, prioriser et exécuter efficacement les activités pour atteindre des objectifs spécifiques.',
                featureParagraph:
                    'Fini les cartes de temps, la paperasse excessive et les documents divers pour vérifier la présence des employés. Automatisez le processus de suivi de présence avec WebWork Time Tracker et voyez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    "Surveillez les progrès en temps réel en consultant les tableaux de tâches, en vérifiant l'état des tâches et en suivant le temps passé.",
                    "Fonctionnalités de collaboration en gestion des tâches pour communiquer avec les membres de l'équipe en temps réel. Commentez les tâches, posez des questions et fournissez des mises à jour pour tenir tout le monde informé.",
                    'Gestion des clients et des prospects pour suivre la productivité des employés. Les indicateurs clés de performance peuvent être mesurés en fonction de la quantité de travail complétée par les employés.',
                    'Structure hiérarchique pour organiser les objectifs : objectifs de projet, objectifs départementaux, objectifs de performance individuels, initiatives stratégiques, etc.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'Gestion de la Paie et des Congés',
        titleDescription: "Optimisez le traitement de la paie et l'administration des congés",
        headerCaption: 'Pourquoi choisir le CRM Naavy pour la gestion des leads',
        productCards: [
            {
                title: 'Facilité de Gestion',
                data: 'Simplifiez la gestion en centralisant les informations clients, en automatisant les tâches routinières et en rationalisant la communication.',
            },
            {
                title: 'Personnalisabilité',
                data: 'Personnalisabilité',
            },
            {
                title: 'Intégrations',
                data: "Activez les intégrations pour le partage de données, l'automatisation des tâches et une meilleure collaboration entre les équipes, ce qui conduit à une mise en œuvre du CRM plus efficace et efficace.",
            },
        ],
        whyChooseSubCaption:
            'Suivez et développez les leads de manière efficace, en veillant à ne manquer aucune opportunité et en améliorant les taux de conversion.',
        headerT1: 'Avantages du Système de Gestion des Leads Naavy',
        subCaptionT1:
            'Automatisation et Répartition des Leads\nIntégration et Rapports de Données en Temps Réel\nAutomatisation des Outils de Vente et de Marketing',

        features: {
            leadManagement: {
                featureHeader: 'Traitement de la Paie',
                featureDescription:
                    'Suivi opportun, interactions personnalisées et maximisation des taux de conversion.',
                featureParagraph:
                    'Fini les cartes de temps, la paperasse excessive et les documents divers pour vérifier la présence des employés. Automatisez le processus de suivi de présence avec WebWork Time Tracker et voyez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    "Rationalisez votre processus de gestion des leads en fonction du temps et de l'emplacement de la force de vente, ce qui facilite l'atteinte des objectifs en un temps plus court.",
                    'Utilisez les historiques des contacts pour créer des segments basés sur les visites sur le site web, les interactions par email, etc. Segmentez la force de vente en fonction de ces données pour gérer les leads.',
                    'Utilisez les scores des leads pour attribuer les prospects à votre équipe de vente ou configurez des workflows pour notifier les représentants lorsque leurs prospects sont prêts à être convertis.',
                    "Accédez facilement à l'historique de chaque lead, y compris les informations sur l'entreprise et un calendrier de chaque point de contact entre le contact et votre entreprise.",
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Suivi de la présence basé sur le calendrier',
                        paragraph:
                            'Surveillez la présence et les mouvements, tels que les heures de pointage et de sortie, ainsi que les informations sur les opérations, les métriques de performance ou les événements des employés.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulaires personnalisés pour la gestion des données',
                        paragraph: 'Surveillez les heures de travail en fonction des jours, des semaines et des mois.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Accès à distance aux rapports de présence',
                        paragraph: 'Accédez aux rapports de présence de plusieurs branches à distance.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Surveillance de la Présence Un',
                        description: [
                            'Obtenez de la transparence sur la ponctualité des employés',
                            'Gagnez du temps et organisez la présence des employés sans action supplémentaire.',
                            "Surveillez facilement si vos employés sont arrivés à l'heure au lieu de travail et ont complété leurs heures de travail.",
                            'Gardez vos opérations de travail plus organisées et assurez un environnement de travail très responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Surveillance de la Présence Deux',
                        description: [
                            'Analyse intelligente des données de présence',
                            'Filtres pour les départs précoces, les arrivées tardives et le suivi insuffisant que vous pouvez sélectionner dans la liste déroulante.',
                            'Cela vous aidera à générer instantanément les informations souhaitées sans avoir à les rechercher manuellement.',
                            'Vous pouvez suivre la présence sur une base quotidienne ou choisir un intervalle à partir du calendrier.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Surveillance de la Présence Trois',
                        description: [
                            'Méthode sécurisée et transparente pour surveiller et suivre la présence des employés en temps réel avec un système 100% sans contact.',
                            'Configuration facile',
                            'POINTAGES AUTOMATISÉS AVEC RAPPORTS DÉTAILLÉS',
                            'CAPTURES D’ÉCRAN AVEC TEMPS DE SUIVI',
                            "ENREGISTREMENT DU NIVEAU D'ACTIVITÉ",
                            'SURVEILLANCE DES APPLICATIONS ET DES SITES WEB',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'Gestion des Congés',
                featureDescription:
                    "Gérez et suivez efficacement les effectifs, en veillant à une documentation appropriée, au respect des politiques de l'entreprise et au maintien de la continuité du flux de travail.",
                featureParagraph:
                    'Fini les cartes de temps, la paperasse excessive et les documents divers pour vérifier la présence des employés. Automatisez le processus de suivi de présence avec WebWork Time Tracker et voyez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    'Gérez les accruals de vacances, en veillant à ce que les employés ne dépassent pas les montants alloués et à prévenir les soldes de congés négatifs.',
                    "Configurez les politiques de congés flexibles de l'organisation en définissant le calendrier des congés. Activez la configuration hiérarchique parallèle ou séquentielle pour l'approbation ou le rejet des demandes de congés.",
                    "Restez informé des lois et réglementations du travail relatives aux droits aux congés. Assurez-vous que vos politiques de gestion des congés sont conformes aux politiques de l'entreprise.",
                    "Offrez aux employés des options d'auto-service, leur permettant de vérifier leurs soldes de congés, de soumettre des demandes et de consulter l'état de leurs demandes.",
                ],
            },
        },
    },
    orderManagement: {
        productName: 'Gestion des Commandes',
        titleDescription:
            'Simplifiez les opérations de commandes/collectes avec une solution évolutive et automatisée.',
        headerCaption: 'Pourquoi choisir le CRM Naavy pour la gestion des leads',
        productCards: [
            {
                title: 'Facilité de Gestion',
                data: 'Simplifiez la gestion en centralisant les informations clients, en automatisant les tâches routinières et en rationalisant la communication.',
            },
            {
                title: 'Personnalisabilité',
                data: 'Personnalisabilité',
            },
            {
                title: 'Intégrations',
                data: "Activez les intégrations pour le partage de données, l'automatisation des tâches et une meilleure collaboration entre les équipes, ce qui conduit à une mise en œuvre du CRM plus efficace et efficiente.",
            },
        ],
        whyChooseSubCaption:
            'Suivez et développez les leads de manière efficace, en veillant à ne manquer aucune opportunité et en améliorant les taux de conversion.',
        headerT1: 'Avantages du Système de Gestion des Leads Naavy',
        subCaptionT1:
            'Automatisation et Répartition des Leads\nIntégration et Rapports de Données en Temps Réel\nAutomatisation des Outils de Vente et de Marketing',
        features: {
            digitizationOfOrder: {
                featureHeader: 'Numérisation des commandes',
                featureDescription:
                    'Rationalisez la gestion des commandes de bout en bout et réduisez les erreurs manuelles',
                featureParagraph:
                    'Fini les cartes de temps, les documents papier excessifs et les différents documents pour vérifier la présence des employés. Automatisez le processus de suivi de la présence avec WebWork Time Tracker et visualisez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    'Générez des rapports détaillés et des analyses pour surveiller la performance du traitement des commandes et la productivité des employés.',
                    'Capturez et validez automatiquement les détails des commandes saisies par les employés ou les clients.',
                    'Fournissez des mises à jour en temps réel sur le statut des commandes et le suivi des expéditions aux employés et aux clients.',
                    "Automatisez les processus d'approbation des commandes et assignez des tâches aux employés appropriés.",
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Suivi de présence basé sur la chronologie',
                        paragraph:
                            'Suivi de la présence et des mouvements tels que le pointage des entrées et sorties ainsi que les informations sur les opérations et les performances des employés.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formulaires personnalisés pour la gestion des données',
                        paragraph:
                            'Surveillez les heures de travail sur une base quotidienne, hebdomadaire et mensuelle.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Accès à distance aux rapports de présence',
                        paragraph: 'Accédez aux rapports de présence de plusieurs succursales à distance.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Suivi de la présence un',
                        description: [
                            'Obtenez une transparence sur la ponctualité des employés',
                            'Gagnez du temps et organisez la présence des employés sans action supplémentaire.',
                            "Surveillez facilement si vos employés sont arrivés à l'heure au lieu de travail et ont terminé leurs heures de travail.",
                            'Gardez vos opérations de travail plus organisées et créez un environnement de travail très responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Suivi de la présence deux',
                        description: [
                            'Analyse intelligente des données de présence',
                            'Filtres pour les départs anticipés, les arrivées tardives et le suivi insuffisant que vous pouvez sélectionner à partir de la liste déroulante.',
                            'Cela vous aidera à générer instantanément les informations souhaitées sans avoir à les rechercher manuellement.',
                            'Vous pouvez suivre la présence quotidiennement ou choisir un intervalle depuis le calendrier.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Suivi de la présence trois',
                        description: [
                            'Méthode sécurisée et transparente pour surveiller et suivre la présence des employés en temps réel avec un système 100% sans contact.',
                            'Configuration facile',
                            'Enregistrements automatiques des entrées et sorties, rapports détaillés',
                            "Captures d'écran avec suivi du temps",
                            "Enregistrement du niveau d'activité",
                            "Suivi de l'utilisation des applications et des sites web",
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'Gestion des objectifs',
                featureDescription:
                    'Fixez des objectifs clairs et réalisables pour chaque employé en fonction de son rôle et de ses capacités.',
                featureParagraph:
                    'Fini les cartes de temps, les documents papier excessifs et les différents documents pour vérifier la présence des employés. Automatisez le processus de suivi de la présence avec WebWork Time Tracker et visualisez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
                fIIPoints: [
                    'Atteignez les objectifs quotidiens, hebdomadaires et mensuels et gardez une trace des réalisations des employés avec un suivi en temps réel sur le terrain.',
                    "Suivi en temps réel (nombre d'objectifs atteints par rapport aux restants), horaires de pointage, nombre d'heures (durée) passée sur chaque tâche, et suivi de localisation.",
                    'Métriques de performance (taux de réalisation, temps pris, scores de satisfaction client), comparaison par rapport aux objectifs (performance réelle par rapport aux objectifs), données historiques (tendances de performance passées).',
                    'Envoyez des notifications automatiques pour informer les employés des alertes de réalisation des objectifs, des rappels de performance, et des avertissements de délai.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'Gestion des factures',
                featureDescription:
                    'Créez, suivez le statut des factures en temps réel et le traitement avec des alertes et des notifications automatisées.',
                fIIPoints: [
                    'Configurez des systèmes pour suivre le statut des factures et envoyez des rappels et alertes automatisés pour les paiements dus et en retard.',
                    'Modèles de factures standardisés incluant tous les détails nécessaires tels que les informations sur le client, les détails de la commande, les taxes, les remises, et les conditions de paiement.',
                    'Collectez des signatures numériques et des preuves de livraison basées sur OTP dans le cadre du processus de facturation.',
                    'Synchronisation automatique avec le système central (stockage basé sur le cloud) et mises à jour en temps réel sur le statut des factures.',
                ],
            },
            distributorOrder: {
                featureHeader: 'Commande de distribution',
                featureDescription:
                    "Gérez les produits/collections et gérez la priorisation des tâches avec des mises à jour en temps réel sur l'inventaire.",
                fIIPoints: [
                    'Créez et traitez les commandes avec tous les détails pertinents pour chaque distribution et assignez des tâches en fonction de la disponibilité.',
                    'Intégrez avec la gestion des stocks pour garantir une allocation précise des stocks et mettre à jour les niveaux de stock en temps réel.',
                    'Configurez le système pour envoyer des notifications automatisées aux étapes clés du processus de commande.',
                    'Accédez à un tableau de bord où les employés peuvent vérifier les commandes, suivre les progrès, et télécharger les documents nécessaires.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'Gestion des stocks',
                featureDescription:
                    "Équilibrez correctement le stock avec des mises à jour en temps réel et un suivi de l'inventaire pour des données précises.",
                fIIPoints: [
                    "Assurez-vous que l'intégration entre les systèmes de gestion des stocks et des commandes est fluide pour synchroniser les niveaux de stock avec les commandes entrantes et sortantes.",
                    "Suivez l'inventaire à travers plusieurs entrepôts, magasins ou emplacements et accédez à un contrôle centralisé, optimisez la distribution des stocks et améliorez la visibilité.",
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'Gestion des commandes / Collecte',
                featureDescription:
                    'Simplifiez les opérations de commandes/collecte avec une solution évolutive et automatisée.',
                fIIPoints: [
                    'Un système centralisé accessible par les employés sur site et hors site pour gérer les commandes, suivre les progrès, et mettre à jour les statuts en temps réel.',
                    "Différents niveaux d'accès et permissions en fonction des rôles (par exemple, managers, employés sur site, employés hors site) pour garantir la sécurité des données et un accès approprié.",
                    'Assurez-vous que toutes les données de commande sont synchronisées en temps réel sur tous les appareils et emplacements, de sorte que tout le monde ait accès aux dernières informations.',
                    "Facilitez la communication entre les employés sur site et hors site grâce à la messagerie et aux notifications dans l'application.",
                ],
            },
        },
    },
};
export default products;
