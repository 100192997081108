const industries = {
    pharmaceutical: {
        industryHeading:
            'Monitore protocolos de segurança, rastreamento de tempo, presença e controle de acesso a áreas sensíveis facilitando a gestão de projetos eficaz, atividades externas e avaliação de desempenho.',
        featureHeader: 'Farmacêutico',
        featureParagraph:
            'O software de gestão de presença e rastreamento de campo focado em farmacêuticos simplifica a gestão da força de trabalho para empresas farmacêuticas/saúde com recursos de rastreamento de tempo, acessibilidade móvel para funcionários de campo, relatórios de conformidade de operações de campo e integração com diferentes sistemas para geração de vendas e produtividade.',
        keyPoints: [
            {
                header: 'Integre sistemas de rastreamento de funcionários com sistemas de RH relacionados a registros de funcionários, folha de pagamento e relatórios de conformidade.',
                paragraph: 'Monitore as horas trabalhadas com base em Diariamente, Semanalmente e Mensalmente.',
            },
            {
                header: 'Utilize sistemas de gestão de turnos para otimizar a programação da força de trabalho.',
                paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
            },
            {
                header: 'Monitore horários de fabricação e áreas de produção onde operações contínuas são cruciais.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Garanta que todos os sistemas de rastreamento de funcionários estejam em conformidade com regulamentações e padrões relevantes na indústria farmacêutica.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
        ],
    },
    education: {
        industryHeading:
            'Um CRM de Admissões Abrangente para Colaboração Estudantil e Gestão de Prospectos Sem Costura',
        featureHeader: 'Educação',
        featureParagraph:
            'Recrutamento e Admissões de Estudantes\nEngajamento e Retenção de Estudantes\nGerencie admissões em múltiplas localidades\nSuporte Personalizado aos Estudantes',
        keyPoints: [
            {
                header: 'Acompanhe e gerencie estudantes potenciais desde o primeiro ponto de contato até o processo de aplicação.',
                paragraph: 'Monitore as horas trabalhadas com base em Diariamente, Semanalmente e Mensalmente.',
            },
            {
                header: 'Identifique e gerencie estudantes potenciais que mostram interesse em se matricular. Acompanhe a comunicação e o progresso para converter consultas em estudantes matriculados.',
                paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
            },
            {
                header: 'Visualize e gerencie as diferentes etapas do processo de matrícula dos estudantes. Faça os candidatos passarem por etapas como Consulta, Aplicação, Entrevista, Aceitação e Matrícula.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Simplifique o processo de aplicação com formulários online personalizáveis. Coleta informações necessárias dos estudantes potenciais de forma eficiente.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'Otimizar o desempenho das equipes de vendas em movimento, permitindo rastreamento de localização em tempo real, garantindo planejamento eficiente de rotas e engajamentos oportunos com clientes.',
        featureHeader: 'Vendas de Campo',
        featureParagraph:
            'Acelere o crescimento e turbocharge sua força de campo com as ferramentas certas para Planejar, Converter e Executar. Naavy simplifica o rastreamento de vendas de campo e gerenciamento de leads e CRM.',
        keyPoints: [
            {
                header: 'Presença em Tempo Real',
                paragraph:
                    'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área de Geofence ou Filial.',
            },
            {
                header: 'Cálculo Total de Horas Trabalhadas',
                paragraph: 'Monitore as horas trabalhadas com base em Diariamente, Semanalmente e Mensalmente.',
            },
            {
                header: 'Relatórios de Presença Acessíveis Remotamente',
                paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
            },
            {
                header: 'Gestão de Presença Centralizada',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'Métricas de desempenho, gestão de despesas e rastreamento de conformidade eficazes para gestão da força de vendas, aprimorando o engajamento com clientes com recursos de comunicação e colaboração.',
        featureParagraph:
            'O rastreamento de presença em tempo real permite ajustes rápidos nos níveis de pessoal com base na demanda de mercado, aumentando a agilidade das operações FMCG.',
        keyPoints: [
            {
                header: 'O rastreamento preciso da presença ajuda',
                paragraph:
                    'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área de Geofence ou Filial.',
            },
            {
                header: 'Processamento preciso da folha de pagamento, reduzindo erros e minimizando discrepâncias financeiras.',
                paragraph: 'Monitore as horas trabalhadas com base em Diariamente, Semanalmente e Mensalmente.',
            },
            {
                header: 'Garantindo adesão às leis e regulamentos trabalhistas.',
                paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
            },
            {
                header: 'Facilitando a gestão eficaz de ausências, prevenindo problemas de falta de pessoal.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Garantindo produção e distribuição contínuas.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Garantindo processos de reembolso precisos.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Rastreamento de pedidos em movimento e conclusões.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
        ],
    },
    services: {
        industryHeading:
            'Simplifique a gestão de projetos e tarefas, otimize a alocação de recursos garantindo registros precisos de tempo e presença, auxiliando no processamento da folha de pagamento e conformidade.',
        featureHeader: 'Serviços',
        featureParagraph:
            'Recursos testados em campo aumentam a produtividade, minimizam a complexidade e automatizam tarefas, economizando tempo e recursos para o seu negócio.',
        keyPoints: [
            {
                header: 'Rastreamento GPS em tempo real da equipe de campo',
                paragraph:
                    'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área de Geofence ou Filial.',
            },
            {
                header: 'Formulários Personalizados e Fotos Geo-tageadas',
                paragraph: 'Monitore as horas trabalhadas com base em Diariamente, Semanalmente e Mensalmente.',
            },
            {
                header: 'Painel intuitivo e relatórios baixáveis',
                paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
            },
            {
                header: 'Gerencie e monitore coleções',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
        ],
    },
    fieldOperations: {
        industryHeading:
            'Facilite decisões baseadas em dados e melhore a comunicação entre as equipes de campo e a sede.',
        featureHeader: 'Operações de Campo',
        featureParagraph:
            'Diga adeus às operações de campo ineficientes e à gestão de serviços com a solução all-in-one da Naavy. Otimize a gestão de tarefas, rastreie a presença em movimento, otimize rotas com base em IA para aumentar a produtividade e a receita de vendas.',
        keyPoints: [
            {
                header: 'Agende e monitore tarefas',
                paragraph:
                    'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área de Geofence ou Filial.',
            },
            {
                header: 'Rastreie a localização em tempo real de todos os funcionários de campo',
                paragraph: 'Monitore as horas trabalhadas com base em Diariamente, Semanalmente e Mensalmente.',
            },
            {
                header: 'Valide e processe reivindicações de despesas',
                paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
            },
            {
                header: 'Defina Metas de SLA e Monitore Atrasos',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Painel intuitivo e relatórios baixáveis',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Conclusão de Tarefas Verificada por OTP',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'Facilite decisões baseadas em dados e melhore a comunicação entre as equipes de campo e a sede.',
        featureHeader: 'Logística e Transporte',
        featureParagraph:
            'Organize e simplifique a logística e a entrega de maneira mais simples. Gerencie operações logísticas de uma grande força de trabalho com seus recursos robustos e avançados.',
        keyPoints: [
            {
                header: 'Obtenha rastreamento em tempo real da localização do entregador.',
                paragraph:
                    'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área de Geofence ou Filial.',
            },
            {
                header: 'Melhore a eficiência operacional monitorando atividades e fluxos de trabalho.',
                paragraph: 'Monitore as horas trabalhadas com base em Diariamente, Semanalmente e Mensalmente.',
            },
            {
                header: 'Simplifique a gestão das tarefas da força de trabalho.',
                paragraph: 'Acesse relatórios de presença de várias filiais remotamente.',
            },
            {
                header: 'Analise dados históricos e informações de tráfego em tempo real para otimizar rotas de entrega.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Processamento automatizado de reembolsos e folha de pagamento.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
            {
                header: 'Melhor atendimento ao cliente com trabalhos de campo automatizados.',
                paragraph:
                    'Consolidação de dados relacionados à presença, processos e sistemas em uma única plataforma ou sistema centralizado.',
            },
        ],
    },
};
export default industries;
