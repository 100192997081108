const homePage = {
    heroHeader: 'Impulsa la excelencia en ventas y la eficiencia operativa a través de',
    heroHeaderTypist: ['Gestión de Asistencia', 'Seguimiento y Monitoreo de Campo', 'CRM'],
    heroHeaderSubText:
        'Asistencia basada en ubicación en tiempo real, Seguimiento en vivo, Gestión de tareas y pedidos, Información de datos y servicios API',
    moreProductivityLessExpenses: 'Más Productividad, Menos Gastos',
    keyFeatures: [
        {
            title: 'Lens',
            description:
                'Registro de asistencia facial sin contacto con asistencia de IA. Marca la asistencia usando la cámara de tu móvil.',
        },
        {
            title: 'Aplicación Web',
            description:
                'Interfaz amigable, los equipos de RRHH y gerentes pueden acceder fácilmente a informes detallados, gestionar registros de empleados, rastrear el rendimiento y revisar la nómina.',
        },
        {
            title: 'Control basado en jerarquía',
            description:
                'Sistema de control basado en jerarquía para acomodar varios niveles organizacionales, incluidos administrativos, gerentes de RRHH, supervisores y empleados.',
        },
        {
            title: 'Optimización de Rutas',
            description:
                'Mejora la eficiencia y reduce los costos operativos con cálculos dinámicos de rutas, optimización de múltiples paradas, estimación de costos y actualizaciones en tiempo real.',
        },
    ],
    features: 'Características',
    ourFeatures:
        'Nuestras características son simples, poderosas y se adaptan fácilmente a cualquier industria para aumentar la productividad de la organización.',
    ourFeaturesObject: [
        {
            title: 'Monitoreo de Asistencia',
            description: 'Registro de asistencia con ubicación y seguimiento de horas trabajadas.',
        },
        {
            title: 'Gestión de Gastos',
            description: 'Los gastos se rastrean y registran de manera precisa.',
        },
        {
            title: 'Seguimiento de la Fuerza Laboral',
            description: 'Monitoreo y registro de actividades y productividad de los empleados.',
        },
        {
            title: 'Gestión de Nómina',
            description: 'Optimiza el procesamiento de nómina y los pagos precisos.',
        },
        {
            title: 'Gestión de Pedidos',
            description: 'Proceso de gestión de pedidos/colecciones a través de múltiples estrategias personalizables.',
        },
        {
            title: 'Optimización de Rutas',
            description: 'Encontrar el camino más eficiente para ahorrar tiempo, combustible y recursos.',
        },
    ],
    whatWeDoQuestion: '¿Qué hace Naavy?',
    whatWeDoCards: [
        {
            title: 'Analiza las Actividades de los Empleados en Tiempo Real',
            description: 'Obtén un análisis detallado de la actividad del equipo diariamente o por hora.',
        },
        {
            title: 'Asistencia Basada en GeoFence e Informes de Check-in',
            description: 'Fortalece la eficiencia del equipo con una gestión estándar de asistencia y permisos.',
        },
        {
            title: 'Gestiona Efectivamente Pedidos/Colecciones',
            description:
                'Minimiza errores mientras aumentas la productividad y la satisfacción del cliente con estrategias inteligentes de pedidos y cobros oportunos.',
        },
        {
            title: 'Gestión de Tareas de Inicio a Fin',
            description:
                'Optimiza la planificación de proyectos, asigna tareas sin esfuerzo y fomenta la colaboración en tiempo real entre los miembros del equipo.',
        },
        {
            title: 'Información Basada en Informes Automatizados',
            description: 'Visualiza el compromiso de los empleados y realiza un análisis impecable del equipo.',
        },
        {
            title: 'Gestiona Gastos Basados en Asignaciones',
            description:
                'Gestiona los sistemas de RRHH para distribuir recibos de pago en línea según los registros en las hojas de asistencia.',
        },
    ],
    whatWeDoList: [
        'Registros de Asistencia',
        'Rastreador de Ubicación Geo',
        'Almacenamiento en la Nube',
        'Alertas y Notificaciones',
        'Gestión de Proyectos',
        'Gestión del Embudo de Ventas',
    ],
    featureTitle: 'Características',
    featureSubTitle:
        'Nuestras características son simples, poderosas y se adaptan fácilmente a cualquier industria para aumentar la productividad de la organización.',
    featureCards: [
        {
            title: 'Monitoreo de Asistencia',
            description: 'Registro de asistencia con ubicación y seguimiento de horas trabajadas.',
        },
        {
            title: 'Gestión de Gastos',
            description: 'Los gastos se rastrean y registran de manera precisa.',
        },
        {
            title: 'Seguimiento de la Fuerza Laboral',
            description: 'Monitoreo y registro de actividades y productividad de los empleados.',
        },
        {
            title: 'Análisis de Ventas en Campo',
            description: 'Recopila datos de ventas y evalúa el rendimiento de ventas.',
        },
        {
            title: 'Gestión de Pedidos',
            description: 'Proceso de gestión de pedidos/colecciones a través de múltiples estrategias personalizables.',
        },
        {
            title: 'Informes en Vivo',
            description: 'Conoce dónde están tus empleados de campo y en qué trabajo están involucrados.',
        },
    ],
    integrationTitle: 'INDUSTRIAS',
    integrationSubTitle: 'Conectando la Gestión de la Fuerza Laboral y la Productividad para Todas las Industrias',
    orderManagementSubtitle: 'Más que un sistema de gestión de pedidos',
    orderManagementTitle:
        'Automatiza, rastrea y entrega con una gestión precisa de pedidos y colecciones para tu equipo de ventas.',
    orderManagementAccordian: [
        {
            title: 'Entrada Electrónica de Pedidos',
            points: [
                'Seguimiento en tiempo real del estado del pedido desde la colocación hasta la entrega.',
                'Digitaliza el proceso capturando pedidos y reduciendo errores manuales.',
            ],
        },
        {
            title: 'Facturación Automatizada',
            points: [
                'Genera facturas automáticamente sobre la marcha basadas en los detalles del pedido.',
                'Envía facturas electrónicamente para un procesamiento y pago más rápidos.',
                'Verificación de pago basada en OTP contra facturación.',
            ],
        },
        {
            title: 'Objetivo de Ventas',
            points: [
                'Rastrea el progreso contra los objetivos con informes detallados y análisis.',
                'Establece y supervisa los objetivos de ventas para los equipos basados en roles y responsabilidades.',
            ],
        },
        {
            title: 'Seguimiento en Tiempo Real del Inventario',
            points: [
                'Mantén un recuento preciso de los niveles de stock cada vez que se genere y complete un pedido.',
                'Establece puntos de reorden para generar automáticamente órdenes de compra en caso de bajo stock.',
            ],
        },
        {
            title: 'Distribución de Pedidos',
            points: [
                'Monitorea el movimiento de bienes desde el almacén hasta el cliente, asegurando responsabilidad y transparencia.',
                'Optimiza el proceso de distribución entre el equipo de ventas según prioridad y ubicación.',
                'Optimización de rutas basada en IA para una entrega más rápida y optimización de costos.',
            ],
        },
    ],
    whyChooseTraceforce: 'Por qué Elegir Naavy',
    whyChooseTraceforceSubtitle:
        'Revolucionando la gestión de Recursos Humanos mediante la automatización de tareas administrativas, la optimización de procesos y el aumento del compromiso de los empleados.',
    whyChooseTraceforceList: [
        'Mejora de la Productividad',
        'Eficiencia en el Tiempo',
        'Reclamaciones con Precisión',
        'Monitoreo en Tiempo Real',
        'Totalmente Responsivo',
        'Optimización Basada en IA',
        'Personalización Basada en Criterios',
        'Soporte para Múltiples Sucursales',
        'Servicio de Soporte 24x7',
    ],
    calculatorTitle: 'Calculadora',
    calculatorSubtitle:
        'Nuestras características son simples, poderosas y se adaptan fácilmente a cualquier industria para aumentar la productividad de la organización.',
    workingHours: 'Horas de Trabajo',
    uncountedHours: 'Horas No Contabilizadas',
    dailySavings: 'Ahorros Diarios',
    monthlySavings: 'Ahorros Mensuales',
    productivityGrowth: 'Crecimiento de la Productividad',
    numberOfEmployees: 'Número de Empleados',
    faqs: 'Preguntas Frecuentes',
    frequentlyAskedQuestions: 'Preguntas Frecuentes',
    faqLine: 'Aquí están algunos de los tipos básicos de preguntas para nuestros clientes',
    faqAccordian: [
        {
            question: '¿Cómo puedo registrar mi empresa?',
            ans: 'Puedes registrarte en Naavy en 3 sencillos pasos en nuestra aplicación móvil o web. Solo agrega tu nombre, correo electrónico y número de GST, y estarás listo para comenzar.',
        },

        {
            question: '¿Cuáles son las diferentes formas en las que puedo autenticar mi inicio de sesión?',
            ans: 'Naavy te proporciona múltiples opciones de inicio de sesión. Por ejemplo, tenemos la opción predeterminada de inicio de sesión móvil, la opción basada en selfies y, por último, pero no menos importante, la autenticación facial basada en IA, la más segura.',
        },
        {
            question: '¿Cómo marcar la asistencia?',
            ans: 'El usuario solo puede marcar la asistencia cuando esté en el Geofence asignado. Además, para proteger contra el proxy, tenemos diferentes métodos de autenticación en su lugar.',
        },
        {
            question:
                '¿Podemos simular la ubicación o cambiar la hora para las tareas completadas o la asistencia marcada?',
            ans: 'Naavy detecta cualquier actividad sospechosa o no solicitada y desactivará la aplicación, además de notificar al usuario.',
        },
        {
            question: '¿Naavy soporta empleados remotos?',
            ans: 'Naavy admite empleados contratados por horas y calcula la nómina en base a turnos de horas fijas o flexibles.',
        },
        {
            question: '¿Puedo rastrear mi fuerza laboral a través del móvil?',
            ans: 'Naavy te ayuda a rastrear y gestionar tu fuerza laboral tanto a través del móvil como de la web, en un formato cronológico, brindándote una visión detallada del día.',
        },
        {
            question: '¿Qué otros servicios ofrece Naavy además del HRMS?',
            ans: 'Naavy ofrece una lista muy completa de servicios para facilitar el trabajo de los gerentes o propietarios de negocios, ayudándoles solo con las notificaciones necesarias.',
        },
    ],

    referAndGet: 'Recomienda y Obtén',
    referAndGetH2: '¡Obtén un 8% de DESCUENTO en los paquetes anuales de Naavy!',
    referAndGetp:
        'Recomienda a un amigo o cliente a Naavy y obtén un 8% de descuento en tu paquete anual una vez que se unan a nosotros. ¡Comparte los beneficios y ahorra juntos!',
};
export default homePage;
