const products = {
    attendanceManagement: {
        productName: 'Управление посещаемостью',
        titleDescription: 'Легкая настройка, автоматическая регистрация входа и выхода, отчеты',
        productCards: [
            {
                title: 'Баланс между работой и личной жизнью',
                data: 'Заботьтесь о балансе работы и личной жизни ваших сотрудников с помощью напоминаний Naavy о рабочей и личной жизни.',
            },
            {
                title: 'Типы сотрудников',
                data: 'Упростите управление рабочим пространством с ролями сотрудников, такими как исполнительный директор, проектный менеджер, менеджер команды и зрители проекта.',
            },
            {
                title: 'Помогает отслеживать',
                data: 'Умные табели времени от Naavy упрощают управление задачами, позволяя вам просматривать активные часы, неактивные часы и продуктивные часы в подробном табличном формате.',
            },
        ],
        whyChooseSubCaption:
            'Переходите к высокоэффективной рабочей среде и испытайте повышенную продуктивность и сотрудничество. Централизуйте данные в карточках.',
        headerCaption: 'Почему стоит выбрать управление посещаемостью Naavy',
        headerT1: 'Функции отслеживания посещаемости, которые делают намного больше',
        subCaptionT1:
            'Испытайте отслеживание сотрудников, мониторинг удаленной работы, мониторинг продуктивности, управление посещаемостью, табели времени и многое другое — все это с дополнительным уровнем расширенных функций.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'Мониторинг посещаемости',
                featureDescription:
                    'Информация о регистрации входа/выхода в реальном времени о присутствии или отсутствии сотрудников в пределах геозоны или филиала.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и разных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Информация в реальном времени о присутствии или отсутствии сотрудников в пределах геозоны или филиала',
                    'Мониторинг рабочего времени по дням, неделям и месяцам',
                    'Удаленный доступ к отчетам по посещаемости нескольких филиалов',
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в единую централизованную платформу или систему',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Посещаемость в реальном времени',
                        paragraph:
                            'Информация в реальном времени о присутствии или отсутствии сотрудников в пределах геозоны или филиала',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'Расчет общего рабочего времени',
                        paragraph: 'Мониторинг рабочего времени по дням, неделям и месяцам',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Удаленный доступ к отчетам по посещаемости',
                        paragraph: 'Удаленный доступ к отчетам по посещаемости нескольких филиалов',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Мониторинг посещаемости Один',
                        description: [
                            'Получите прозрачность пунктуальности сотрудников',
                            'Сэкономьте время и организуйте посещаемость сотрудников без дополнительных действий.',
                            'Легко контролируйте, пришли ли ваши сотрудники вовремя на рабочее место и выполнили ли они свои рабочие часы.',
                            'Сделайте работу вашего рабочего места более организованной и создайте высокоответственное рабочее окружение.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Мониторинг посещаемости Два',
                        description: [
                            'Интеллектуальный анализ данных о посещаемости',
                            'Фильтруйте ранние ухода, поздние приходы и недостаточное отслеживание, которые вы можете выбрать из раскрывающегося списка.',
                            'Это поможет вам мгновенно получить нужную информацию без необходимости искать ее вручную.',
                            'Вы можете отслеживать посещаемость ежедневно или выбрать интервал из календаря.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Мониторинг посещаемости Три',
                        description: [
                            'Безопасный и прозрачный способ мониторинга и отслеживания посещаемости сотрудников в реальном времени с помощью полностью бесконтактной системы.',
                            'Легкая настройка',
                            'АВТОМАТИЧЕСКАЯ РЕГИСТРАЦИЯ ВХОДА И ВЫХОДА ПОДРОБНЫЕ ОТЧЕТЫ',
                            'СКРИНШОТЫ С ВРЕМЕНЕМ ОТСЛЕЖИВАНИЯ',
                            'ЗАПИСЬ УРОВНЯ ДЕЯТЕЛЬНОСТИ',
                            'МОНиторинг ИСПОЛЬЗОВАНИЯ ПРИЛОЖЕНИЙ И ВЕБ-САЙТОВ',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'Управление сменами',
                featureDescription:
                    'Организуйте и планируйте рабочие часы для оптимизации продуктивности, удовлетворения операционных потребностей и обеспечения покрытия в разные периоды времени.',
                fIIPoints: [
                    'Разработайте четкий и хорошо организованный график смен. Определите разные типы смен в зависимости от характера вашего бизнеса.',
                    'Анализируйте рабочую нагрузку и задачи, чтобы определить количество сотрудников, необходимых в разные смены',
                    'Централизованная платформа для доступа сотрудников к своим графикам и установления надежного канала связи для любых обновлений или изменений.',
                    'Централизованное наблюдение и контроль графиков сотрудников, позволяющее менеджерам эффективно распределять смены, отслеживать посещаемость и управлять ресурсами рабочей силы по нескольким местоположениям с одной платформы.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'Управление на основе геозоны',
                featureDescription:
                    'Технология на основе местоположения для мониторинга и проверки присутствия сотрудников в пределах предопределенных географических границ.',
                fIIPoints: [
                    'Точные записи посещаемости для обеспечения присутствия сотрудников на назначенных рабочих местах.',
                    'Реализация отслеживания в реальном времени для мониторинга местоположений сотрудников в пределах геозоны.',
                    'Установите конкретные географические границы, известные как геозоны, вокруг рабочего места или назначенных мест посещения.',
                    'Настройте оповещения или уведомления, чтобы информировать менеджеров или HR-персонал, когда сотрудники входят или покидают определенные границы геозоны.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'Несколько вариантов регистрации времени',
                featureDescription:
                    'Предоставьте сотрудникам гибкость в записи своих рабочих часов с использованием биометрических сканеров, мобильных приложений, временных часов и веб-платформ.',
                fIIPoints: [
                    'Подходит для организаций с несколькими локациями. Гибкие варианты регистрации времени для управления посещаемостью на местах и вне их для разных филиалов.',
                    'Предоставьте сотрудникам доступ к порталу самообслуживания для посещаемости и управления регистрацией входа и выхода в пределах геозоны.',
                    'Гибкие варианты регистрации времени включают биометрические сканеры, мобильные приложения, RFID-карты и распознавание лиц, обеспечивая бесшовное отслеживание времени в различных рабочих средах.',
                    'Автоматическая регистрация посещаемости для сотрудников в пределах офисных помещений с уведомлением.',
                ],
            },
            attendanceReport: {
                featureHeader: 'Отчеты по посещаемости',
                featureDescription:
                    'Сводите данные о посещаемости сотрудников, рабочие часы, отсутствие, опоздания и ранние уходы в течение конкретного времени.',
                fIIPoints: [
                    'Предоставление общего сводного отчета по посещаемости, включая общее количество рабочих дней, общее количество отработанных часов и любые сверхурочные часы.',
                    'Удаленный доступ к отчетам по посещаемости нескольких филиалов с настраиваемыми категориями.',
                    'Отображение остатков отпусков для каждого сотрудника, включая дни отпуска, больничные и другие виды отпуска, накопленные и использованные.',
                    'Возможность настройки отчетов по конкретным критериям, таким как отдел, команда или отдельный сотрудник.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'Управление полевыми силами',
        titleDescription: 'Операции по полевым продажам и услугам с живыми отчетами и маршрутом посещений',
        productCards: [
            {
                title: 'Сниженные накладные расходы',
                data: 'Получите снижение операционных затрат благодаря автоматизации расписаний сотрудников. Отслеживайте время сотрудников, задачи и расходы, чтобы определить области, где вы можете сэкономить деньги.',
            },
            {
                title: 'Отличная ценность для клиентов',
                data: 'Установите гарантии расписания и эффективного обслуживания. Отслеживайте взаимодействие с клиентами и отзывы, чтобы легко определить недостатки и области для улучшения.',
            },
            {
                title: 'Аналитика производительности',
                data: 'Без усилий создавайте комплексные отчеты на единой панели управления, предоставляющие ценную информацию о производительности вашей рабочей силы, сроках выполнения работы, удовлетворенности клиентов и областях для улучшения.',
            },
        ],
        whyChooseSubCaption:
            'Максимальная эффективность с программным обеспечением для управления полевыми силами. Подзаголовок - Отслеживайте своих полевых сотрудников в реальном времени, следите за прогрессом задач и получайте информацию о их местоположении, деятельности и производительности. Улучшите ответственность и используйте принятие решений на основе данных.',
        headerCaption: 'Почему стоит выбрать управление полевыми силами Naavy',
        headerT1: 'Основные моменты систем управления полевыми силами',
        subCaptionT1:
            'Используйте отслеживание местоположения сотрудников в реальном времени в сочетании с оптимизацией на основе ИИ для упрощения операций, живые отчеты для получения немедленных сведений и аналитику полевых продаж для повышения производительности. Обеспечьте эффективное распределение ресурсов, повышенную продуктивность и принятие решений на основе данных.',
        features: {
            workforceTracking: {
                featureHeader: 'Отслеживание рабочей силы',
                featureDescription:
                    'Систематическое мониторинг и управление деятельностью сотрудников, продуктивностью и посещаемостью.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и разных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Отслеживайте события, такие как регистрация входа и выхода, а также получите информацию о деятельности сотрудников, их производственных показателях или событиях.',
                    'Храните данные для встреч и задач для отслеживания тенденций и принятия решений на основе данных для протоколов встреч.',
                    'Управление клиентами и потенциальными клиентами для отслеживания продуктивности сотрудников, продуктивности и ключевых показателей эффективности, которые могут быть измерены на основе выполненной работы.',
                    'Отслеживайте точное расстояние, пройденное сотрудниками для полевой работы на ежедневной, еженедельной и ежемесячной основе, и возмещайте расходы в зависимости от расстояния и местоположения.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Отслеживание посещаемости по временной шкале',
                        paragraph:
                            'Отслеживание посещаемости и движения, мониторинг событий, таких как регистрация входа и выхода, а также получение информации о деятельности сотрудников, их производственных показателях или событиях.',
                    },
                    customFormsforDataManagement: {
                        header: 'Индивидуальные формы для управления данными',
                        paragraph: 'Мониторинг рабочего времени на основе дневного, недельного и месячного учета.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Удаленный доступ к отчетам по посещаемости',
                        paragraph: 'Удаленный доступ к отчетам по посещаемости нескольких филиалов.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Мониторинг посещаемости Один',
                        description: [
                            'Получите прозрачность пунктуальности сотрудников',
                            'Сэкономьте время и организуйте посещаемость сотрудников без дополнительных действий.',
                            'Легко отслеживайте, пришли ли ваши сотрудники вовремя на рабочее место и выполнили ли они свои рабочие часы.',
                            'Сделайте работу вашего рабочего места более организованной и создайте высокоответственное рабочее окружение.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Мониторинг посещаемости Два',
                        description: [
                            'Интеллектуальный анализ данных о посещаемости',
                            'Фильтры ранних уходов, поздних приходов и недостаточного отслеживания, которые вы можете выбрать из раскрывающегося списка.',
                            'Это поможет вам мгновенно получить нужную информацию без необходимости искать ее вручную.',
                            'Вы можете отслеживать посещаемость ежедневно или выбрать интервал из календаря.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'Мониторинг посещаемости Три',
                        description: [
                            'Безопасный и прозрачный способ мониторинга и отслеживания посещаемости сотрудников в реальном времени с помощью полностью бесконтактной системы.',
                            'Легкая настройка',
                            'АВТОМАТИЧЕСКАЯ РЕГИСТРАЦИЯ ВХОДА И ВЫХОДА ПОДРОБНЫЕ ОТЧЕТЫ',
                            'СКРИНШОТЫ С ВРЕМЕНЕМ ОТСЛЕЖИВАНИЯ',
                            'ЗАПИСЬ УРОВНЯ ДЕЯТЕЛЬНОСТИ',
                            'МОНиторинг ИСПОЛЬЗОВАНИЯ ПРИЛОЖЕНИЙ И ВЕБ-САЙТОВ',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'Аналитика полевых продаж',
                featureDescription:
                    'Отслеживайте и анализируйте ключевые метрики команды продаж на поле, такие как доход от продаж, взаимодействие с клиентами и географические тренды.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и разных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Ключевые показатели эффективности могут помочь в принятии решений на основе данных, что может помочь в разработке продуктивных решений для следующего времени или в ревизии стратегии.',
                    'Отчеты могут быть доступны на дневной, недельной или месячной основе по продажам, потенциальным клиентам и привлечению клиентов, что может помочь в повышении продуктивности и эффективности персонала.',
                    'Проверяйте визиты с помощью регистрации на основе геозоны для точности. Координаты местоположения могут быть прикреплены к фотографии камеры при передаче.',
                    'Используйте маршрут посещений для планирования визитов или маршрутов для сотрудников к клиентам. Интегрируйте ваш маршрут в карты для оптимизации навигации.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'Посещаемость на основе местоположения',
                featureDescription:
                    'Посещаемость на основе геозоны, чтобы убедиться, что сотрудники могут регистрироваться только в пределах предопределенных географических границ.',
                fIIPoints: [
                    'Реализуйте виртуальные границы на основе геозоны вокруг определенных мест офисных помещений, что поможет автоматизировать посещаемость на основе входа или выхода сотрудника из определенной области.',
                    'Методы аутентификации для обеспечения точности и безопасности данных о посещаемости с использованием PIN-кодов, биометрии или лицевой аутентификации.',
                    'Обеспечьте своевременное обновление записей о посещаемости, что позволит принять своевременные управленческие решения.',
                    'Рассмотрите альтернативные методы отслеживания посещаемости для удаленных сотрудников.',
                ],
            },
            liveReporting: {
                featureHeader: 'Живое Reporting',
                featureDescription:
                    'Отслеживание и анализ различных аспектов их работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проекта.',
                fIIPoints: [
                    'Администратор или менеджер могут подписаться на уведомления, которые они хотят получать. Это поможет сосредоточиться на уведомлениях, которые действительно важны.',
                    'Оставайтесь уведомленными о посещаемости и отпусках. Знайте количество сотрудников заранее, чтобы планирование дня было более продуктивным и эффективным.',
                    'Отслеживайте все одобрения, от возмещений до отпусков, и просматривайте их позже.',
                    'Удаленное отслеживание с помощью форм и протоколов встреч. Индивидуальные формы могут помочь получить конкретные данные для встречи, которые можно получить удаленно.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'Оптимизация на основе ИИ',
                featureDescription:
                    'Оптимизация и автоматизация планирования маршрутов для полевых представителей или торговых сотрудников на основе ИИ маршрута посещений.',
                fIIPoints: [
                    'Источники данных в реальном времени, такие как обновления о дорожном движении, погодные условия и закрытия дорог, для динамической корректировки маршрутов на основе текущих обстоятельств, что помогает в эффективном планировании дня.',
                    'Просматривайте оптимизированные маршруты и вносите необходимые корректировки. Это может быть частью мобильного приложения или веб-платформы.',
                    'Система ИИ для учета уникальных характеристик различных маршрутов или путей.',
                    'Используйте картографические службы и геопространственные данные для понимания дорожной сети, условий дорожного движения и другой информации о местоположении.',
                ],
            },
            taskManagement: {
                featureHeader: 'Управление задачами',
                featureDescription:
                    'Организация, приоритизация и эффективное выполнение задач для достижения конкретных целей или задач.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и разных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Отслеживайте прогресс в реальном времени, просматривая доски задач, проверяя статус задач и отслеживая затраченное время.',
                    'Функции совместного управления задачами для общения с членами команды в реальном времени. Комментируйте задачи, задавайте вопросы и предоставляйте обновления, чтобы все были в курсе.',
                    'Управление клиентами и потенциальными клиентами для отслеживания продуктивности сотрудников, продуктивности и ключевых показателей эффективности, которые могут быть измерены на основе выполненной работы.',
                    'Иерархическая структура для организации целей. Цели проектов, задачи отдела, индивидуальные производственные цели, стратегические инициативы и т.д.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'Управление расчетом заработной платы и отпусками',
        titleDescription: 'Оптимизация процесса расчета заработной платы и администрирования отпусков',
        headerCaption: 'Почему стоит выбрать CRM для управления лидами Naavy',
        productCards: [
            {
                title: 'Удобство управления',
                data: 'Упростите управление, централизовав информацию о клиентах, автоматизировав рутинные задачи и оптимизировав коммуникацию.',
            },
            {
                title: 'Настраиваемость',
                data: 'Настраиваемость',
            },
            {
                title: 'Интеграции',
                data: 'Возможность интеграции для обмена данными, автоматизации задач и улучшения сотрудничества между командами, что приведет к более эффективной и успешной реализации CRM',
            },
        ],
        whyChooseSubCaption:
            'Эффективно отслеживайте и развивайте лиды, обеспечивая, чтобы ни одна возможность не была упущена, и улучшайте коэффициенты конверсии.',
        headerT1: 'Преимущества системы управления лидами Naavy',
        subCaptionT1:
            'Автоматизация захвата и распределения лидов\nИнтеграция данных в реальном времени и отчеты\nАвтоматизация инструментов продаж и маркетинга',
        features: {
            leadManagement: {
                featureHeader: 'Обработка расчета заработной платы',
                featureDescription:
                    'Своевременные последующие действия, персонализированное взаимодействие и максимизация коэффициентов конверсии.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и различных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Оптимизируйте процесс управления лидами на основе времени и местоположения вашей торговой команды, что упростит достижение целей в более короткие сроки.',
                    'Используйте историю контактов для создания сегментов на основе посещений веб-сайта, взаимодействий по электронной почте и других данных. Сегментируйте торговую команду на основе этих данных для управления лидами.',
                    'Используйте баллы лидов для назначения перспектив вашим торговым представителям или настройте рабочие процессы, чтобы уведомлять представителей, когда их перспективы готовы к конверсии.',
                    'Легко получите доступ к истории каждого лида, включая информацию о компании и хронологию каждого контакта между клиентом и вашим бизнесом.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Отслеживание посещаемости по временной шкале',
                        paragraph:
                            'Отслеживание посещаемости и движения, мониторинг событий, таких как регистрация входа и выхода, а также получение информации о деятельности сотрудников, их производственных показателях или событиях.',
                    },
                    customFormsforDataManagement: {
                        header: 'Индивидуальные формы для управления данными',
                        paragraph: 'Мониторинг рабочего времени на основе дневного, недельного и месячного учета.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Удаленный доступ к отчетам по посещаемости',
                        paragraph: 'Удаленный доступ к отчетам по посещаемости нескольких филиалов.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Мониторинг посещаемости Один',
                        description: [
                            'Получите прозрачность пунктуальности сотрудников',
                            'Сэкономьте время и организуйте посещаемость сотрудников без дополнительных действий.',
                            'Легко отслеживайте, пришли ли ваши сотрудники вовремя на рабочее место и выполнили ли они свои рабочие часы.',
                            'Сделайте работу вашего рабочего места более организованной и создайте высокоответственное рабочее окружение.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Мониторинг посещаемости Два',
                        description: [
                            'Интеллектуальный анализ данных о посещаемости',
                            'Фильтры ранних уходов, поздних приходов и недостаточного отслеживания, которые вы можете выбрать из раскрывающегося списка.',
                            'Это поможет вам мгновенно получить нужную информацию без необходимости искать ее вручную.',
                            'Вы можете отслеживать посещаемость ежедневно или выбрать интервал из календаря.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Мониторинг посещаемости Три',
                        description: [
                            'Безопасный и прозрачный способ мониторинга и отслеживания посещаемости сотрудников в реальном времени с помощью полностью бесконтактной системы.',
                            'Легкая настройка',
                            'АВТОМАТИЧЕСКАЯ РЕГИСТРАЦИЯ ВХОДА И ВЫХОДА ПОДРОБНЫЕ ОТЧЕТЫ',
                            'СКРИНШОТЫ С ВРЕМЕНЕМ ОТСЛЕЖИВАНИЯ',
                            'ЗАПИСЬ УРОВНЯ ДЕЯТЕЛЬНОСТИ',
                            'МОНиторинг ИСПОЛЬЗОВАНИЯ ПРИЛОЖЕНИЙ И ВЕБ-САЙТОВ',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'Управление отпусками',
                featureDescription:
                    'Эффективное управление и отслеживание численности сотрудников, обеспечение надлежащей документации, соблюдение корпоративной политики и поддержание бесперебойного рабочего процесса.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и различных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Управляйте накоплением отпусков, чтобы сотрудники не превышали установленный лимит, и предотвратите отрицательные балансы отпусков.',
                    'Настройте гибкую политику отпусков организации, установив календарь отпусков. Включите параллельную или последовательную иерархию для одобрения или отклонения запросов на отпуска.',
                    'Будьте в курсе трудового законодательства и правил, касающихся прав на отпуск. Убедитесь, что ваша политика управления отпусками соответствует корпоративной политике.',
                    'Предоставьте сотрудникам возможность самообслуживания, позволяя им проверять свои балансы отпусков, подавать заявки и просматривать статус своих запросов.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'Управление заказами',
        titleDescription:
            'Упростите операции по заказам/сбору с помощью масштабируемого и автоматизированного решения.',
        headerCaption: 'Почему стоит выбрать CRM для управления лидами Naavy',
        productCards: [
            {
                title: 'Удобство управления',
                data: 'Упростите управление, централизовав информацию о клиентах, автоматизировав рутинные задачи и оптимизировав коммуникацию.',
            },
            {
                title: 'Настраиваемость',
                data: 'Настраиваемость',
            },
            {
                title: 'Интеграции',
                data: 'Возможность интеграции для обмена данными, автоматизации задач и улучшения сотрудничества между командами, что приведет к более эффективной и успешной реализации CRM.',
            },
        ],
        whyChooseSubCaption:
            'Эффективно отслеживайте и развивайте лиды, обеспечивая, чтобы ни одна возможность не была упущена, и улучшайте коэффициенты конверсии.',
        headerT1: 'Преимущества системы управления лидами Naavy',
        subCaptionT1:
            'Автоматизация захвата и распределения лидов\nИнтеграция данных в реальном времени и отчеты\nАвтоматизация инструментов продаж и маркетинга',
        features: {
            digitizationOfOrder: {
                featureHeader: 'Цифровизация заказов',
                featureDescription:
                    'Оптимизируйте управление заказами от начала до конца и уменьшите количество ошибок.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и различных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Создавайте подробные отчеты и аналитику для мониторинга эффективности обработки заказов и производительности сотрудников.',
                    'Автоматически захватывайте и проверяйте данные заказов, введенные сотрудниками или клиентами.',
                    'Предоставляйте обновления в реальном времени о статусе заказов и отслеживании отправлений сотрудникам и клиентам.',
                    'Автоматизируйте процессы утверждения заказов и назначайте задачи соответствующим сотрудникам.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Отслеживание посещаемости по временной шкале',
                        paragraph:
                            'Отслеживание посещаемости и движения, мониторинг событий, таких как регистрация входа и выхода, а также получение информации о деятельности сотрудников, их производственных показателях или событиях.',
                    },
                    customFormsforDataManagement: {
                        header: 'Индивидуальные формы для управления данными',
                        paragraph: 'Мониторинг рабочего времени на основе дневного, недельного и месячного учета.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Удаленный доступ к отчетам по посещаемости',
                        paragraph: 'Удаленный доступ к отчетам по посещаемости нескольких филиалов.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Мониторинг посещаемости Один',
                        description: [
                            'Получите прозрачность пунктуальности сотрудников',
                            'Сэкономьте время и организуйте посещаемость сотрудников без дополнительных действий.',
                            'Легко отслеживайте, пришли ли ваши сотрудники вовремя на рабочее место и выполнили ли они свои рабочие часы.',
                            'Сделайте работу вашего рабочего места более организованной и создайте высокоответственное рабочее окружение.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Мониторинг посещаемости Два',
                        description: [
                            'Интеллектуальный анализ данных о посещаемости',
                            'Фильтры ранних уходов, поздних приходов и недостаточного отслеживания, которые вы можете выбрать из раскрывающегося списка.',
                            'Это поможет вам мгновенно получить нужную информацию без необходимости искать ее вручную.',
                            'Вы можете отслеживать посещаемость ежедневно или выбрать интервал из календаря.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Мониторинг посещаемости Три',
                        description: [
                            'Безопасный и прозрачный способ мониторинга и отслеживания посещаемости сотрудников в реальном времени с помощью полностью бесконтактной системы.',
                            'Легкая настройка',
                            'Автоматическая регистрация входа и выхода, Подробные отчеты',
                            'Скриншоты с временем отслеживания',
                            'Запись уровня активности',
                            'Мониторинг использования приложений и веб-сайтов',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'Управление целями',
                featureDescription:
                    'Установите четкие и достижимые цели для каждого сотрудника на основе их роли и возможностей.',
                featureParagraph:
                    'Больше никаких карт времени, чрезмерной документации и различных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и смотрите начало и конец рабочего времени ваших сотрудников в хорошо структурированном формате.',
                fIIPoints: [
                    'Достигайте ежедневных, еженедельных, ежемесячных целей и ведите учет достижений сотрудников с помощью живого отслеживания на месте.',
                    'Прогресс в реальном времени (количество достигнутых целей против оставшихся), время входа/выхода, количество часов (продолжительность) на каждую задачу и отслеживание местоположения.',
                    'Показатели эффективности (уровень выполнения, затраченное время, оценки удовлетворенности клиентов), Сравнение с целями (фактическая производительность против цели), Исторические данные (тенденции прошлой производительности).',
                    'Отправляйте автоматические уведомления, чтобы информировать сотрудников о предупреждениях о достижении целей, напоминаниях о производительности, предупреждениях о сроках.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'Управление счетами',
                featureDescription:
                    'Создавайте, отслеживайте статус счетов в реальном времени и обрабатывайте их с помощью автоматизированных уведомлений и оповещений.',
                fIIPoints: [
                    'Настройте системы для отслеживания статуса счетов и отправки автоматических напоминаний и оповещений о предстоящих и просроченных платежах.',
                    'Стандартные шаблоны счетов, которые включают все необходимые данные, такие как информация о клиенте, данные заказа, налоги, скидки и условия оплаты.',
                    'Собирайте цифровые подписи и подтверждение доставки на основе OTP в рамках процесса выставления счетов.',
                    'Автоматическая синхронизация с центральной системой (облачное хранилище) и обновления статуса счета в реальном времени.',
                ],
            },
            distributorOrder: {
                featureHeader: 'Заказ дистрибьютора',
                featureDescription:
                    'Управляйте продуктами/сбором и расставляйте приоритеты задач с обновлениями в реальном времени по инвентарю.',
                fIIPoints: [
                    'Создавайте и обрабатывайте заказы со всеми необходимыми деталями для каждого распределения и назначайте задачи на основе доступности.',
                    'Интеграция с управлением инвентарем для обеспечения точного распределения запасов и обновления уровней инвентаря в реальном времени.',
                    'Настройте систему для отправки автоматических уведомлений на ключевых этапах процесса заказа.',
                    'Доступ к панели управления, где сотрудники могут проверять заказы, отслеживать их выполнение и загружать необходимые документы.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'Управление инвентарем',
                featureDescription:
                    'Правильный баланс запасов с обновлениями в реальном времени и отслеживание инвентаря для точных данных.',
                fIIPoints: [
                    'Обеспечьте бесшовную интеграцию между системами управления инвентарем и заказами для синхронизации уровней запасов с поступающими и исходящими заказами.',
                    'Отслеживайте инвентарь на нескольких складах, магазинах или локациях, получая централизованное управление, оптимизируйте распределение запасов и улучшайте видимость.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'Управление заказами/сбором',
                featureDescription:
                    'Упростите операции по заказам/сбору с помощью масштабируемого и автоматизированного решения.',
                fIIPoints: [
                    'Централизованная система, доступная как для сотрудников на месте, так и вне его, для управления заказами, отслеживания выполнения и обновления статусов в реальном времени.',
                    'Разные уровни доступа и разрешений в зависимости от ролей (например, менеджеры, сотрудники на месте, сотрудники вне офиса) для обеспечения безопасности данных и надлежащего доступа.',
                    'Убедитесь, что все данные о заказах синхронизируются в реальном времени на всех устройствах и везде, чтобы все имели доступ к самой актуальной информации.',
                    'Облегчите коммуникацию между сотрудниками на месте и вне его через встроенные сообщения и уведомления.',
                ],
            },
        },
    },
};
export default products;
