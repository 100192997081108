const products = {
    attendanceManagement: {
        productName: 'Attendance Management',
        titleDescription: 'Easy Setup, Automated Check In and Check Out, Reports',
        productCards: [
            {
                title: 'Work-Life Balance',
                data: 'Take care of your employees’ work-life balance with Naavy reminders about work and personal life.',
            },
            {
                title: 'Member Types',
                data: 'Simplify workspace management with member roles such as executive manager, project manager, team manager, and project viewers',
            },
            {
                title: 'Helps Track',
                data: 'Smart timesheets from Naavy make task management simpler by allowing you to view active hours, idle hours, and productive hours breakdown in a detailed sheet style.',
            },
        ],
        whyChooseSubCaption:
            'Transition Toward a Highly Efficient Workplace Environment and Experience Enhanced Productivity and Collaboration. Centralize data in the cards.',
        headerCaption: 'Why choose Naavy Attendance Management',
        headerT1: 'Attendance Tracking Features That Do Much More',
        subCaptionT1:
            'Experience employee tracking, remote work monitoring, productivity monitoring, attendance management, timesheets, and more — all with a layer of advanced functionalities.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'Attendance Monitoring',
                featureDescription:
                    'Real-time clock in/clock out information about the presence or absence of individuals within the Geofence area or Branch.',
                featureParagraph:
                    "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
                    'Monitor working hours based on Daily, Weekly and Monthly',
                    'Access attendance Reports of Multiple Branches remotely',
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Real Time Attendance',
                        paragraph:
                            'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'Total Working Hours Calculation',
                        paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Remote Access Attendance Reports',
                        paragraph: 'Access attendance Reports of Multiple Branches remotely',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Attendance Monitoring One',
                        description: [
                            "Gain Transparency of Employees' Punctuality",
                            'Save time and organize employee attendance without any additional action.',
                            'Easily monitor whether your employees have arrived at the workplace in time and completed their working hours or not.',
                            'Keep your workplace operations more organized and have a highly responsible working environment.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Attendance Monitoring Two',
                        description: [
                            'Intelligent Analysis of Attendance Data',
                            'Filters early leaving, late coming, and less tracking that you can select from the drop-down list.',
                            'This will help you to instantly generate your preferred information without having to search for it manually.',
                            'You can track attendance on a daily basis or you can choose an interval from the calendar.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Attendance Monitoring Three',
                        description: [
                            'Secure and transparent way to monitor and track employee attendance in real-time with a 100% touchless system.',
                            'Easy Setup',
                            'AUTOMATED CHICK IN AND OUTS DETAILED REPORTS',
                            'SCREENSHOTS WITH TRACKINGTIME',
                            'ACTIVITY LEVEL RECORDING',
                            'APPS AND WEBSITE USAGE MONITORING',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'Shift Management',
                featureDescription:
                    'Organize and schedule work hours that optimizes productivity, meets operational needs, ensures coverage across different time periods',
                fIIPoints: [
                    'Develop a clear and well-organized shift schedule. Define different types of shifts based on the nature of your business.',
                    'Analyze the workload and tasks to determine the number of employees needed during different shifts',
                    'Centralized platform for employees to access their schedules, and establish a reliable communication channel for any updates or changes.',
                    'Centralized oversight and control of employees schedules, allowing managers to efficiently allocate shifts, track attendance, and manage workforce resources across multiple locations from a single platform.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'Geo Fence Based Attendance',
                featureDescription:
                    'Location-based technology to monitor and verify employee presence within predefined geographical boundaries.',
                fIIPoints: [
                    'Accurate attendance records to ensure that employees are present at their designated work locations.',
                    "Implement real-time tracking to monitor employees' locations within the geo-fence.",
                    'Establish specific geographic boundaries, known as geo-fences, around the workplace or designated attendance locations.',
                    'Set up alerts or notifications to inform managers or HR personnel when employees enter or leave the defined geo-fence boundaries.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'Multiple Clock In Options',
                featureDescription:
                    'Provide flexibility to employees to record their work hours using biometric scanners, mobile apps, time clocks and web-based platforms.',
                fIIPoints: [
                    'Suitable for Organizations with Multiple Locations Flexible clock in options for on field and off field attendance management of different branches',
                    'Provide employees with access to a self-service portal of attendance records and manage their check-ins and check-outs within the geo-fence.',
                    'Flexible clock-in options include biometric scans, mobile apps, RFID cards and facial recognition ensuring seamless time tracking across various work environments.',
                    'Auto Clock in attendance marking for employees within the range of office premises with alert notification.',
                ],
            },
            attendanceReport: {
                featureHeader: 'Attendance Reports',
                featureDescription:
                    'Summarize employee attendance data, working hours, absences, late arrivals, and early departures within a specific timeframe.',
                fIIPoints: [
                    'Provide an overall summary of attendance, including the total number of working days, total hours worked, and any overtime hours.',
                    'Remotely access multiple branch attendance reports with customizable categories.',
                    'Display leave balances for each employee, including vacation days, sick leave, and any other types of leave accrued and utilized.',
                    'Allow users to customize reports based on specific criteria, such as department, team, or individual employee.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'Field Force Management',
        titleDescription: 'Field Sales and Services Operation with Live Reporting and Beat Route',
        productCards: [
            {
                title: 'Reduced Overheads',
                data: "Enjoy reduced operational costs by automating employee schedules. Track employees' time, tasks & expenses to identify areas where you can save money.",
            },
            {
                title: 'Great Customer Value',
                data: 'Set a schedule guarantee and efficient service. Track customer interactions and feedback, to easily identify gaps and areas for improvement.',
            },
            {
                title: 'Performance Analytics',
                data: 'Effortlessly generate comprehensive reports on unified dashboards, providing valuable insights into your workforce’s performance, job timeline, customer satisfaction & areas for improvement.',
            },
        ],
        whyChooseSubCaption:
            'Peak-Efficiency With Field Force Management Software\nSub caption - Track your field employees in real-time, monitor task progress, and gain insights into their location, activity, and performance. Improve accountability and utilize data-driven decision-making.',
        headerCaption: 'Why choose Naavy Field Force Management',
        headerT1: 'Highlights of Field Force Management Systems',
        subCaptionT1:
            'Leverage real-time employee location tracking combined with AI-based optimization to streamline operations, live reporting for immediate insights, and utilize field sales analytics to enhance performance. Ensure efficient resource allocation, improved productivity, and data-driven decision-making.',

        features: {
            workforceTracking: {
                featureHeader: 'Workforce Tracking',
                featureDescription:
                    "Systematic monitoring and management of employees' activities, productivity, and attendance.",
                featureParagraph:
                    "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    'Monitor Events like Clock in and Clock Out and also Employees insights into their operations, performance metrics, or events.',
                    'Store Data for Meetings and task to Track Trends and Data-driven decision-making for Minutes of Meeting',
                    'Customer and Lead Management to track Productivity Employees productivity and Key performance indicators could be measured based on the amount of work completed by Employees',
                    'Track Employees accurate distance travelled for field work based on daily, weekly, and monthly basis and reimburse expenses as per the distance and locations travelled.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Time Line based tracking of attendance',
                        paragraph:
                            'Attendance and Movement Monitor Events like Clock in and Clock Out and also Employees insights into their operations, performance metrics, or events.',
                    },
                    customFormsforDataManagement: {
                        header: 'Custom forms For Data Management',
                        paragraph: 'Monitor working hours based on Daily, Weekly, and Monthly',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Remote Access Attendance Reports',
                        paragraph: 'Access attendance Reports of Multiple Branches remotely',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Attendance Monitoring One',
                        description: [
                            "Gain Transparency of Employees' Punctuality",
                            'Save time and organize employee attendance without any additional action.',
                            'Easily monitor whether your employees have arrived at the workplace in time and completed their working hours or not.',
                            'Keep your workplace operations more organized and have a highly responsible working environment.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Attendance Monitoring Two',
                        description: [
                            'Intelligent Analysis of Attendance Data',
                            'Filters early leaving, late coming, and less tracking that you can select from the drop-down list.',
                            'This will help you to instantly generate your preferred information without having to search for it manually.',
                            'You can track attendance on a daily basis or you can choose an interval from the calendar.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'Attendance Monitoring Three',
                        description: [
                            'Secure and transparent way to monitor and track employee attendance in real-time with a 100% touchless system.',
                            'Easy Setup',
                            'AUTOMATED CHECK IN AND OUTS DETAILED REPORTS',
                            'SCREENSHOTS WITH TRACKINGTIME',
                            'ACTIVITY LEVEL RECORDING',
                            'APPS AND WEBSITE USAGE MONITORING',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'Field Sales Analytics',
                featureDescription:
                    'Track and analyze key metrics of sales team on field such as sales revenue, customer interactions, and geographical trends.',
                featureParagraph:
                    "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    'Key Performance indicator can help in making data driven decision which can help in making productive decision for next time or revamp the strategy',
                    'Reports can be accessed on daily, weekly or monthly basis on Sales, Lead and Customer Acquisition this could help in the person productivity and efficiency',
                    'Validate the visit with Geofence based check-ins for accuracy. Coordinates of the location could be attached to a camera pic while sharing',
                    'Beat Route could be used for planning visit or beat plan for employees to client visit. Integrate your beat plan in Maps directly to optimise navigation',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'Location Based Attendance',
                featureDescription:
                    'Geo fence based attendance to ensure that employees can only clock in or out when they are within the predefined geographic boundaries.',
                fIIPoints: [
                    'Implement geofenced virtual boundaries around specific locations of office premises. Helping in automating attendance based on an employee entering or leaving a designated area.',
                    'Authentication methods to ensure the accuracy and security of attendance data using PINs, biometric or facial attendance.',
                    'Ensure attendance records are promptly updated, allowing for timely management decisions.',
                    'Consider alternative methods for attendance tracking for remote employees',
                ],
            },
            liveReporting: {
                featureHeader: 'Live Reporting',
                featureDescription:
                    'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
                fIIPoints: [
                    'Admin or Manager can subscribe or opt to notifications they want to receive. This would help focusing in the notification they really want to receive.',
                    'Stay Notified about Attendance and Leave Stay a step ahead and know the headcount before so planning the day could be more productive and efficient',
                    'Keep a Track of all Approval Track all approval from reimbursement to leave and view them later',
                    'Remote Tracking through Forms and Minutes of Meeting Custom forms can help in fetching specific data for a meeting which could be accessed remotely.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'AI Based Optimization',

                featureDescription:
                    'Optimize and automate the planning of routes for field representatives or sales personnel based on AI beat route.',
                fIIPoints: [
                    'Real-time data sources, such as live traffic updates, weather conditions, and road closures, to dynamically adjust routes based on current circumstances helping in efficient daily planning.',
                    'View optimized routes, and make any necessary adjustments. This could be part of a mobile app or a web-based platform.',
                    'AI system to account for the unique characteristics of different beats or routes.',
                    'Utilize mapping services and geospatial data to understand the road network, traffic conditions, and other location-related information.',
                ],
            },
            taskManagement: {
                featureHeader: 'Task Management',
                featureDescription:
                    'Organizing, prioritizing, and efficiently executing activities to achieve specific goals or objectives.',
                featureParagraph:
                    "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    'Monitor progress in real time by viewing task boards, checking task status and tracking time spent',
                    'Task management collaboration features to communicate with team members in real-time. Comment on tasks, ask questions, and provide updates to keep everyone informed.',
                    'Customer and Lead Management to track Productivity Employees productivity and Key performance indicators could be measured based on the amount of work completed by Employees',
                    'Hierarchical structure for organizing goals. project goals, departmental objectives, individual performance goals, strategic initiatives, etc.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'Payroll And Leave Management',
        titleDescription: 'Optimize payroll processing and leave administration',
        headerCaption: 'Why choose Naavy Lead Management CRM',
        productCards: [
            {
                title: 'Ease of Management',
                data: 'Simplify management by centralizing customer information, automating routine tasks, and streamlining communication.',
            },
            {
                title: 'Customizability',
                data: 'Customizability',
            },
            {
                title: 'Integrations',
                data: 'Enable integrations for data sharing, automation of tasks, and improved collaboration across teams, leading to a more efficient and effective CRM implementation',
            },
        ],
        whyChooseSubCaption:
            'Track and nurture leads efficiently, ensuring no opportunity is lost and improving conversion rates.',
        headerT1: 'Benefits of Naavy Lead Management System',
        subCaptionT1:
            'Lead Capture Automation and Distribution\nReal-time Data Integration and Reports\nSales and Marketing Tools Automation',

        features: {
            leadManagement: {
                featureHeader: 'Payroll Processing',
                featureDescription: 'Timely follow-ups, personalized interactions, and maximized conversion rates.',
                featureParagraph:
                    "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    'Streamline your lead management process based on time and location of sales force making it easier to achieve goals in shorter span of time',
                    'Use contacts’ histories to create segments based on website visits, email interactions, and more. Segments sales force based on this data to manage leads',
                    'Use lead scores to assign prospects to your sales team, or set up workflows to notify reps when their prospects are primed for conversion',
                    'Easily access each lead’s history, including company information and a timeline of every touchpoint between the contact and your business.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Time Line based tracking of attendance',
                        paragraph:
                            'Attendance and Movement Monitor Events like Clock in and Clock Out and also Employees insights into their operations, performance metrics, or events.',
                    },
                    customFormsforDataManagement: {
                        header: 'Custom forms For Data Management',
                        paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Remote Access Attendance Reports',
                        paragraph: 'Access attendance Reports of Multiple Branches remotely',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Attendance Monitoring One',
                        description: [
                            "Gain Transparency of Employees' Punctuality",
                            'Save time and organize employee attendance without any additional action.',
                            'Easily monitor whether your employees have arrived at the workplace in time and completed their working hours or not.',
                            'Keep your workplace operations more organized and have a highly responsible working environment.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Attendance Monitoring Two',
                        description: [
                            'Intelligent Analysis of Attendance Data',
                            'Filters early leaving, late coming, and less tracking that you can select from the drop-down list.',
                            'This will help you to instantly generate your preferred information without having to search for it manually.',
                            'You can track attendance on a daily basis or you can choose an interval from the calendar.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Attendance Monitoring Three',
                        description: [
                            'Secure and transparent way to monitor and track employee attendance in real-time with a 100% touchless system.',
                            'Easy Setup',
                            'AUTOMATED CHECK IN AND OUTS DETAILED REPORTS',
                            'SCREENSHOTS WITH TRACKING TIME',
                            'ACTIVITY LEVEL RECORDING',
                            'APPS AND WEBSITE USAGE MONITORING',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'Leave Management',
                featureDescription:
                    'Efficiently handle and track headcounts, ensuring proper documentation, adherence to company policies, and maintaining smooth workflow continuity.',
                featureParagraph:
                    "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    "Manage vacation accruals, ensuring employees don't go over allotted amounts, and prevent negative leave balances.",
                    'Configure flexible leave policies of the organization by setting up the leave Calendar. Enable parallel or sequential Hierarchy Setup for Leave Requests Approval or Rejection.',
                    'Stay informed about labor laws and regulations related to leave entitlements. Ensure that your leave management policies are compliant with company policies.',
                    'Provide employees with self-service options, allowing them to check their leave balances, submit requests, and view the status of their requests.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'Order Management',
        titleDescription: 'Simplify orders/collection operations with a scalable and automated solution.',
        headerCaption: 'Why choose Naavy Lead Management CRM',
        productCards: [
            {
                title: 'Ease of Management',
                data: 'Simplify management by centralizing customer information, automating routine tasks, and streamlining communication.',
            },
            {
                title: 'Customizability',
                data: 'Customizability',
            },
            {
                title: 'Integrations',
                data: 'Enable integrations for data sharing, automation of tasks, and improved collaboration across teams, leading to a more efficient and effective CRM implementation',
            },
        ],
        whyChooseSubCaption:
            'Track and nurture leads efficiently, ensuring no opportunity is lost and improving conversion rates.',
        headerT1: 'Benefits of Naavy Lead Management System',
        subCaptionT1:
            'Lead Capture Automation and Distribution\nReal time Data Integration and Reports\nSales and Marketing Tools Automation',
        features: {
            digitizationOfOrder: {
                featureHeader: 'Digitization of order',
                featureDescription: 'Streamline order management end to end and reduce manual errors',
                featureParagraph:
                    "No more timecards, excessive paperwork, and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    'Generate detailed reports and analytics to monitor order processing performance and employee productivity.',
                    'Automatically capture and validate order details entered by employees or customers.',
                    'Provide real-time updates on order status and shipment tracking to employees and customers.',
                    'Automate order approval processes and assign tasks to appropriate employees.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Time Line based tracking of attendance',
                        paragraph:
                            'Attendance and Movement Monitor Events like Clock in and Clock Out and also Employees insights into their operations, performance metrics, or events.',
                    },
                    customFormsforDataManagement: {
                        header: 'Custom forms For Data Management',
                        paragraph: 'Monitor working hours based on Daily, Weekly, and Monthly.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Remote Access Attendance Reports',
                        paragraph: 'Access attendance Reports of Multiple Branches remotely.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Attendance Monitoring One',
                        description: [
                            "Gain Transparency of Employees' Punctuality",
                            'Save time and organize employee attendance without any additional action.',
                            'Easily monitor whether your employees have arrived at the workplace in time and completed their working hours or not.',
                            'Keep your workplace operations more organized and have a highly responsible working environment.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Attendance Monitoring Two',
                        description: [
                            'Intelligent Analysis of Attendance Data',
                            'Filters early leaving, late coming, and less tracking that you can select from the drop-down list.',
                            'This will help you to instantly generate your preferred information without having to search for it manually.',
                            'You can track attendance on a daily basis or you can choose an interval from the calendar.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Attendance Monitoring Three',
                        description: [
                            'Secure and transparent way to monitor and track employee attendance in real-time with a 100% touchless system.',
                            'Easy Setup',
                            'Automated Check-In and Check-Outs, Detailed Reports',
                            'Screenshots with Tracking Time',
                            'Activity Level Recording',
                            'Apps and Website Usage Monitoring',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'Target Management',
                featureDescription:
                    'Set clear and achievable targets for each employee based on their role and capabilities.',
                featureParagraph:
                    "No more timecards, excessive paperwork, and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
                fIIPoints: [
                    'Achieve Daily, Weekly, Monthly targets and keep records of employee achievement with live field tracking.',
                    'Real-Time Progress (number of targets achieved vs. remaining), Check-in/Check-out Times, No. of hours (duration) spent on each task, and location tracking.',
                    'Performance Metrics (completion rate, time taken, customer satisfaction scores), Comparison Against Targets (actual performance vs. target), Historical Data (past performance trends).',
                    'Send automated notifications to keep employees informed about Target Achievement Alerts, Performance Reminders, Deadline Warnings.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'Invoice Management',
                featureDescription:
                    'Create, track real-time invoice status and processing with automated alerts and notifications.',
                fIIPoints: [
                    'Set up systems to track invoice status and send automated reminders and alerts for due and overdue payments.',
                    'Standardized invoice templates that include all necessary details such as customer information, order details, tax, discounts, and payment terms.',
                    'Collect digital signatures and OTP-based proof of delivery as part of the invoice process.',
                    'Automatic Sync with Central System (cloud-based storage) and Real-Time Updates on Invoice Status.',
                ],
            },
            distributorOrder: {
                featureHeader: 'Distribution Order',
                featureDescription:
                    'Manage products/collection and handle task prioritization with real-time updates on inventory.',
                fIIPoints: [
                    'Create and process orders with all relevant details for each distribution and assign tasks based on availability.',
                    'Integrate with inventory management to ensure accurate stock allocation and update inventory levels in real-time.',
                    'Configure the system to send automated notifications at key stages of the order process.',
                    'Access dashboard where employees can check orders, track progress, and download necessary documents.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'Inventory Management',
                featureDescription:
                    'Right balance of stock to real-time updates and tracking of inventory for accurate data.',
                fIIPoints: [
                    'Ensure seamless integration between inventory and order management systems to synchronize stock levels with incoming and outgoing orders.',
                    'Tracks inventory across multiple warehouses, stores, or locations and access centralized control, optimizes stock distribution and improves visibility.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'Order / Collection Management',
                featureDescription: 'Simplify orders/collection operations with a scalable and automated solution.',
                fIIPoints: [
                    'A centralized system accessible by both on-site and off-site employees to manage orders, track progress, and update statuses in real-time.',
                    'Different levels of access and permissions based on roles (e.g., managers, on-site employees, off-site employees) to ensure data security and appropriate access.',
                    'Ensure that all order data is synchronized in real-time across all devices and locations, so everyone has access to the latest information.',
                    'Facilitate communication between on-site and off-site employees through in-app messaging and notifications.',
                ],
            },
        },
    },
};
export default products;
