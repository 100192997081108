const joinUsPage = {
    joinUsString: 'Bergabunglah dengan Kami',
    joinUsHeroPara:
        'Bergabunglah dengan tim kami di Naavy! Jelajahi peluang menarik di mana kerja sama tim dan kreativitas memimpin jalan. Temukan bagaimana Anda dapat berkontribusi pada misi kami untuk memberdayakan bisnis melalui solusi perangkat lunak yang intuitif. Kembangkan karier Anda di lingkungan yang mendukung dan menghargai bakat dan ide unik Anda.',
    ourRecruitmentProcess: 'Proses Rekrutmen Kami',
    ourBenifitsString: 'Manfaat Kami',
    ourRecruitmentProcessObjects: [
        {
            title: 'Wawancara Teknis',
            description:
                'Wawancara ini dirancang untuk menilai keterampilan teknis Anda, kemampuan pemecahan masalah, dan seberapa baik Anda menerapkan pengetahuan Anda dalam skenario dunia nyata. Kami ingin mendapatkan wawasan tentang keterampilan teknis Anda, kemampuan coding, dan pendekatan Anda dalam mengatasi tantangan. Putaran ini dilakukan melalui panggilan meet/skype.',
        },
        {
            title: 'Tes Latihan (Aptitude)',
            description:
                'Kami mengevaluasi logika coding Anda dan optimasi kode dengan memberikan tugas yang akan dikirim melalui email setelah putaran pertama Anda berdasarkan wawancara Anda. Penugasan akan diberikan dalam jangka waktu tertentu di mana kandidat harus menyelesaikannya.',
        },
        {
            title: 'Wawancara Manajemen',
            description:
                'Percakapan singkat dengan manajemen kami untuk memahami visi karier Anda dan kesesuaian budaya dalam organisasi kami. Kami mencari seseorang dengan pengetahuan materi yang solid dan etika kerja yang kuat.',
        },
        {
            title: 'Seleksi Akhir',
            description:
                'Finalisasi tawaran akan dilakukan dengan tim HR. Kami membuat seleksi akhir berdasarkan konsolidasi mendalam dari kinerja kandidat dalam semua putaran wawancara. Berdasarkan keputusan bersama, tanggal bergabung akan dikonfirmasi, dan kami akan menyediakan alat terbaik pada hari pertama Anda.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'Bekerja dari Mana Saja!',
            description:
                "Tampaknya kembali ke meja! Karena kantor rumah Anda agak terlalu ramah anjing dan 'ruang rapat' Anda saat ini sedang ditempati oleh tidur siang kucing. Kami tidak menawarkan Bekerja dari mana saja karena kami suka melihat Anda setiap hari.",
        },
        {
            name: 'Akhir Pekan Bergantian',
            description:
                'Ketika akhir pekan Anda sealternatif mungkin dengan perubahan suasana hati Anda. Satu minggu, Anda merencanakan liburan seru atau mengejar tidur, dan minggu berikutnya, Anda menghadapi tenggat waktu dan menjawab email. Ini semua bagian dari petualangan dalam menyeimbangkan kerja dan bermain.',
        },
        {
            name: 'Asuransi Kesehatan',
            description:
                'Untuk semua momen ‘Saya mungkin memerlukan dokter untuk ini’. Dengan cakupan komprehensif, Anda tidak perlu khawatir tentang ‘bagaimana jika’—fokuslah pada pemulihan dan kembali ke diri Anda yang terbaik. Karena ketika Anda tidak merasa yang terbaik, Anda pantas mendapatkan perawatan terbaik di ujung jari Anda.',
        },
        {
            name: 'Bonus Referensi',
            description:
                'Punya teman? Dapatkan bonus! Ini menang-menang. Bayangkan ini: Anda memiliki lingkaran teman yang luar biasa dalam apa yang mereka lakukan dan mencari peluang baru. Dengan merujuk mereka ke perusahaan kami, Anda tidak hanya membantu mereka menemukan pekerjaan yang fantastis, tetapi juga menambah bonus referensi Anda.',
        },
        {
            name: 'Kit Karyawan',
            description:
                'Kit Karyawan - Semua yang Anda butuhkan untuk menaklukkan hari kerja, kecuali klon diri Anda. Dari perlengkapan kantor penting hingga barang-barang motivasi, kit ini memiliki semuanya. Butuh pena? Cek. Buku catatan untuk semua ide brilian Anda? Cek.',
        },
        {
            name: 'Jam Kerja Fleksibel',
            description:
                'Tidak perlu terburu-buru pulang untuk menonton acara favorit Anda; sekarang, kantor Anda bisa menjadi sofa Anda, dan waktu istirahat Anda bisa menjadi prime time. Dengan jam kerja fleksibel, Anda dapat beralih dari panggilan konferensi ke acara, dari spreadsheet ke menonton acara Netflix.',
        },
        {
            name: 'Cuti Sabat',
            description: 'Cuti Sabat',
        },
        {
            name: 'Cuti Paternitas/Maternitas',
            description: 'Cuti Paternitas/Maternitas',
        },
        {
            name: 'Keseimbangan Kerja-Hidup',
            description: 'Keseimbangan Kerja-Hidup',
        },
        {
            name: 'Perayaan Kantor',
            description: 'Perayaan Kantor',
        },
    ],
    jobsAtTraceForce: 'Pekerjaan di Naavy',
};
export default joinUsPage;
