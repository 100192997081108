const homePage = {
    heroHeader: 'सेल्स उत्कृष्टता और संचालन दक्षता को बढ़ावा देने के लिए',
    heroHeaderTypist: ['उपस्थिति प्रबंधन', 'फील्ड ट्रैकिंग मॉनिटरिंग', 'सीआरएम'],
    heroHeaderSubText:
        'रियल-टाइम स्थान आधारित उपस्थिति, लाइव ट्रैकिंग, कार्य और ऑर्डर प्रबंधन, डेटा अंतर्दृष्टि और एपीआई सेवाएँ',
    moreProductivityLessExpenses: 'अधिक उत्पादकता, कम खर्चे',
    keyFeatures: [
        {
            title: 'लेंस',
            description:
                'एआई सहायता के साथ टचलेस चेहरे की उपस्थिति के लिए Clockin और Clockout। अपने मोबाइल कैमरे का उपयोग करके उपस्थिति दर्ज करें।',
        },
        {
            title: 'वेब ऐप',
            description:
                'उपयोगकर्ता के अनुकूल डैशबोर्ड, HR और प्रबंधक टीमें आसानी से विस्तृत रिपोर्ट्स तक पहुंच सकती हैं, कर्मचारी रिकॉर्ड प्रबंधित कर सकती हैं, प्रदर्शन को ट्रैक कर सकती हैं और पेरोल की जांच कर सकती हैं।',
        },
        {
            title: 'पदानुक्रम आधारित नियंत्रण',
            description:
                'विभिन्न संगठनात्मक स्तरों के लिए पदानुक्रम आधारित नियंत्रण प्रणाली, जिसमें प्रशासनिक, एचआर प्रबंधक, पर्यवेक्षक और कर्मचारी शामिल हैं।',
        },
        {
            title: 'रूट ऑप्टिमाइजेशन',
            description:
                'गतिशील मार्ग गणनाओं, मल्टी-स्टॉप ऑप्टिमाइजेशन, लागत अनुमान और वास्तविक समय अपडेट के साथ दक्षता बढ़ाएं और परिचालन लागत को कम करें।',
        },
    ],
    features: 'विशेषताएँ',
    ourFeatures:
        'हमारी विशेषताएँ सरल, शक्तिशाली हैं और किसी भी उद्योग में संगठन की उत्पादकता बढ़ाने के लिए उपयुक्त हैं।',
    ourFeaturesObject: [
        {
            title: 'उपस्थिति निगरानी',
            description: 'स्थान के साथ उपस्थिति चिह्नित करना और कार्य घंटों का ट्रैक रखना।',
        },
        {
            title: 'खर्च प्रबंधन',
            description: 'खर्चों को सटीक तरीके से ट्रैक और रिकॉर्ड किया जाता है।',
        },
        {
            title: 'कार्यबल ट्रैकिंग',
            description: 'कर्मचारी गतिविधियों और उत्पादकता की निगरानी और रिकॉर्डिंग।',
        },
        {
            title: 'पेरोल प्रबंधन',
            description: 'पेरोल प्रसंस्करण का अनुकूलन और सटीक भुगतान।',
        },
        {
            title: 'ऑर्डर प्रबंधन',
            description: 'कई अनुकूलन योग्य रणनीतियों के माध्यम से ऑर्डर/कलेक्शन का प्रबंधन।',
        },
        {
            title: 'रूट अनुकूलन',
            description: 'समय, ईंधन और संसाधनों को बचाने के लिए सबसे कुशल मार्ग ढूंढना।',
        },
    ],
    whatWeDoQuestion: 'Naavy क्या करता है?',
    whatWeDoCards: [
        {
            title: 'कर्मचारियों की गतिविधियों का रियल टाइम विश्लेषण',
            description: 'टीम की गतिविधियों का विस्तृत विश्लेषण दैनिक या घंटे के आधार पर प्राप्त करें।',
        },
        {
            title: 'जियोफेंस आधारित उपस्थिति और चेक-इन रिपोर्ट',
            description: 'मानक परिचालन उपस्थिति और अवकाश प्रबंधन के साथ टीम की दक्षता को सुदृढ़ करें।',
        },
        {
            title: 'ऑर्डर/संग्रह को प्रभावी ढंग से प्रबंधित करें',
            description: 'समय पर संग्रह और स्मार्ट ऑर्डर रणनीतियों के साथ उत्पादकता और ग्राहक संतोष को बढ़ाएं।',
        },
        {
            title: 'सम्पूर्ण कार्य प्रबंधन',
            description:
                'प्रोजेक्ट योजना को सुव्यवस्थित करें, कार्य सौंपें और टीम के सदस्यों के बीच वास्तविक समय में सहयोग को बढ़ावा दें।',
        },
        {
            title: 'स्वचालित रिपोर्टों पर आधारित अंतर्दृष्टि',
            description: 'कर्मचारी सगाई को दृश्य रूप में देखें और टीम विश्लेषण को बेहतरीन तरीके से करें।',
        },
        {
            title: 'भत्तों के आधार पर खर्चों का प्रबंधन',
            description:
                'उपस्थिति शीट्स में रिकॉर्ड के अनुसार ऑनलाइन पेस्लिप्स वितरित करने के लिए एचआर सिस्टम का प्रबंधन करें।',
        },
    ],
    whatWeDoList: [
        'उपस्थिति लॉग',
        'जियो लोकेशन ट्रैकर',
        'क्लाउड स्टोरेज',
        'अलर्ट और नोटिफिकेशन',
        'प्रोजेक्ट प्रबंधन',
        'सेल्स फनल प्रबंधन',
    ],
    featureTitle: 'विशेषताएँ',
    featureSubTitle:
        'हमारी विशेषताएँ सरल, शक्तिशाली और किसी भी उद्योग के लिए संगठन की उत्पादकता बढ़ाने के लिए आसानी से अनुकूल हैं।',
    featureCards: [
        {
            title: 'उपस्थिति निगरानी',
            description: 'स्थान के साथ उपस्थिति चिह्नित करना और कार्य समय को ट्रैक करना।',
        },
        {
            title: 'खर्च प्रबंधन',
            description: 'खर्चों को सटीक तरीके से ट्रैक और रिकॉर्ड किया जाता है।',
        },
        {
            title: 'वर्कफोर्स ट्रैकिंग',
            description: 'कर्मचारी गतिविधियों, उत्पादकता की निगरानी और रिकॉर्डिंग।',
        },
        {
            title: 'फील्ड सेल्स एनालिटिक्स',
            description: 'सेल्स डेटा एकत्र करें और सेल्स प्रदर्शन को मापें।',
        },
        {
            title: 'ऑर्डर प्रबंधन',
            description: 'कई अनुकूलन योग्य रणनीतियों के माध्यम से ऑर्डर/संग्रह का प्रबंधन।',
        },
        {
            title: 'लाइव रिपोर्टिंग',
            description: 'जानें कि आपके फील्ड कर्मचारी कहाँ हैं और किस काम में लगे हैं।',
        },
    ],
    integrationTitle: 'उद्योग',
    integrationSubTitle: 'हर उद्योग के लिए कार्यबल प्रबंधन और उत्पादकता को जोड़ना',
    orderManagementSubtitle: 'ऑर्डर प्रबंधन प्रणाली से कहीं अधिक',

    orderManagementTitle: 'सटीक ऑर्डर और संग्रह प्रबंधन के साथ स्वचालित करें, ट्रैक करें और वितरित करें।',
    orderManagementAccordian: [
        {
            title: 'इलेक्ट्रॉनिक ऑर्डर एंट्री',
            points: [
                'ऑर्डर की स्थिति को प्लेसमेंट से लेकर डिलीवरी तक रियल-टाइम में ट्रैक करें।',
                'ऑर्डर को कैप्चर करके और मैनुअल त्रुटियों को कम करके प्रक्रिया को डिजिटाइज़ करें।',
            ],
        },
        {
            title: 'स्वचालित इनवॉइसिंग',
            points: [
                'ऑर्डर विवरण के आधार पर स्वचालित रूप से चालान उत्पन्न करें।',
                'त्वरित प्रसंस्करण और भुगतान के लिए चालान इलेक्ट्रॉनिक रूप से भेजें।',
                'चालान के खिलाफ किए गए भुगतान के लिए ओटीपी आधारित सत्यापन।',
            ],
        },
        {
            title: 'सेल्स लक्ष्य',
            points: [
                'विस्तृत रिपोर्टों और विश्लेषण के साथ लक्ष्यों के खिलाफ प्रगति को ट्रैक करें।',
                'भूमिकाओं और जिम्मेदारियों के आधार पर टीम के लिए सेल्स लक्ष्य सेट करें और मॉनिटर करें।',
            ],
        },
        {
            title: 'रियल टाइम इन्वेंट्री ट्रैकिंग',
            points: [
                'हर बार जब ऑर्डर उत्पन्न और पूरा होता है तो स्टॉक स्तर की सटीक गणना रखें।',
                'कम स्टॉक पर स्वचालित रूप से खरीदारी आदेश उत्पन्न करने के लिए पुनः आदेश बिंदुओं को सेट करें।',
            ],
        },
        {
            title: 'ऑर्डर का वितरण',
            points: [
                'गोड़ के गोदाम से ग्राहक तक की मूवमेंट की निगरानी करें, सुनिश्चित करें कि जवाबदेही और पारदर्शिता बनी रहे।',
                'प्राथमिकता और स्थान के आधार पर बिक्री टीम के बीच वितरण प्रक्रिया को अनुकूलित करें।',
                'तेज़ वितरण और लागत अनुकूलन के लिए एआई आधारित मार्ग अनुकूलन।',
            ],
        },
    ],
    whyChooseTraceforce: 'Naavy को क्यों चुनें',
    whyChooseTraceforceSubtitle:
        'प्रशासनिक कार्यों को स्वचालित करके, प्रक्रियाओं को सुव्यवस्थित करके और कर्मचारी सगाई को बढ़ाकर मानव संसाधन प्रबंधन में क्रांति लाना।',
    whyChooseTraceForceList: [
        'उत्पादकता सुधार',
        'समय दक्षता',
        'सटीकता के साथ दावे',
        'रियल टाइम मॉनिटरिंग',
        'पूर्ण रूप से उत्तरदायी',
        'एआई आधारित अनुकूलन',
        'मानदंड आधारित अनुकूलन',
        'कई शाखा समर्थन',
        '24x7 समर्थन सेवा',
    ],
    calculatorTitle: 'कैलकुलेटर',
    calculatorSubtitle:
        'हमारी विशेषताएँ सरल, शक्तिशाली, और किसी भी उद्योग के लिए संगठन की उत्पादकता बढ़ाने के लिए आसानी से अनुकूल हैं।',
    workingHours: 'कार्य घंटे',
    uncountedHours: 'गिनती नहीं किए गए घंटे',
    dailySavings: 'दैनिक बचत',
    monthlySavings: 'मासिक बचत',
    productivityGrowth: 'उत्पादकता वृद्धि',
    numberOfEmployees: 'कर्मचारियों की संख्या',
    faqs: 'अक्सर पूछे जाने वाले प्रश्न',
    frequentlyAskedQuestions: 'अक्सर पूछे जाने वाले प्रश्न',
    faqLine: 'यहाँ हमारे ग्राहकों के लिए कुछ सामान्य प्रकार के प्रश्न हैं',
    faqAccordian: [
        {
            question: 'मैं अपनी फर्म को कैसे साइन अप कर सकता हूँ?',
            ans: 'आप Naavy पर 3 आसान चरणों में साइन अप कर सकते हैं, हमारे मोबाइल ऐप या वेब ऐप पर। बस अपना नाम, ईमेल और जीएसटी नंबर जोड़ें और आप तैयार हैं।',
        },
        {
            question: 'मैं अपने लॉगिन को प्रमाणित करने के लिए कौन-कौन से तरीके उपयोग कर सकता हूँ?',
            ans: 'Naavy आपको कई लॉगिन विकल्प प्रदान करता है, उदाहरण के लिए, हमारे पास डिफ़ॉल्ट मोबाइल लॉगिन विकल्प, सेल्फी आधारित विकल्प और सबसे सुरक्षित एआई आधारित फेस ऑथेंटिकेशन है।',
        },
        {
            question: 'उपस्थिति कैसे चिह्नित करें?',
            ans: 'उपस्थिति चिह्नित करने के लिए उपयोगकर्ता को केवल उस निर्धारित जियोफेंस में होना चाहिए जो उसे सौंपा गया है। प्रॉक्सी को सुरक्षित करने के लिए हमारे पास विभिन्न प्रमाणन विधियाँ हैं।',
        },
        {
            question:
                'क्या हम स्थान को मॉक कर सकते हैं या कार्य पूरा होने या उपस्थिति चिह्नित करने के लिए समय बदल सकते हैं?',
            ans: 'Naavy किसी भी स्पैम या अवांछित गतिविधि को पहचानता है और एप्लिकेशन को अक्षम कर देगा और उपयोगकर्ता को सूचित करेगा।',
        },
        {
            question: 'क्या Naavy दूरस्थ कर्मचारियों का समर्थन करता है?',
            ans: 'Naavy ठेके पर आधारित कर्मचारियों का समर्थन करता है और इसलिए फिक्स्ड घंटे या फ्लेक्सिबल घंटे शिफ्ट के आधार पर पेरोल की गणना करता है।',
        },
        {
            question: 'क्या मैं अपने कार्यबल को मोबाइल के माध्यम से ट्रैक कर सकता हूँ?',
            ans: 'Naavy आपको मोबाइल और वेब दोनों के माध्यम से अपने कार्यबल को ट्रैक और प्रबंधित करने में मदद करता है, जिससे आपको एक व्यापक दिन की जानकारी मिलती है।',
        },
        {
            question: 'Naavy के पास HRMS के अलावा और कौन-कौन सी सेवाएँ हैं?',
            ans: 'Naavy एक बहुत व्यापक सेवा सूची प्रदान करता है जो प्रबंधक या व्यापार मालिकों के काम को आसान बनाती है और उन्हें केवल आवश्यक सूचनाओं के साथ मदद करती है।',
        },
    ],

    referAndGet: 'संदर्भित करें और पाएं',
    referAndGetH2: 'Naavy वार्षिक पैकेज पर 8% की छूट प्राप्त करें!',
    referAndGetp:
        'किसी मित्र या ग्राहक को Naavy का संदर्भ दें और उनके जुड़ने पर अपने वार्षिक पैकेज पर 8% की छूट प्राप्त करें! लाभ साझा करें और साथ में बचत करें!',
};
export default homePage;
