const homePage = {
    heroHeader: 'Impulsione a excelência em vendas e a eficiência operacional através de',
    heroHeaderTypist: ['Gestão de Presença', 'Monitoramento de Campo', 'CRM'],
    heroHeaderSubText:
        'Presença baseada em localização em tempo real, Rastreamento ao Vivo, Gestão de Tarefas e Pedidos, Insights de Dados e serviços de API',
    moreProductivityLessExpenses: 'Mais Produtividade, Menos Despesas',
    keyFeatures: [
        {
            title: 'Lens',
            description:
                'Registro de presença facial sem toque com assistência de IA. Marque a presença usando a câmera do seu celular.',
        },
        {
            title: 'Aplicativo Web',
            description:
                'Dashboard amigável ao usuário, equipes de RH e gerentes podem acessar facilmente relatórios detalhados, gerenciar registros de funcionários, acompanhar o desempenho e verificar a folha de pagamento.',
        },
        {
            title: 'Controle Baseado em Hierarquia',
            description:
                'Sistema de controle baseado em hierarquia para acomodar vários níveis organizacionais, incluindo administração, gerentes de RH, supervisores e funcionários.',
        },
        {
            title: 'Otimização de Rotas',
            description:
                'Aumente a eficiência e reduza os custos operacionais com cálculos dinâmicos de rotas, otimização de múltiplas paradas, estimativa de custos e atualizações em tempo real.',
        },
    ],
    features: 'Recursos',
    ourFeatures:
        'Nossos recursos são simples, poderosos e facilmente adaptáveis a qualquer setor para aumentar a produtividade da organização.',
    ourFeaturesObject: [
        {
            title: 'Monitoramento de Presença',
            description: 'Registro de presença com localização e acompanhamento das horas trabalhadas',
        },
        {
            title: 'Gestão de Despesas',
            description: 'Despesas são rastreadas e registradas de forma precisa',
        },
        {
            title: 'Rastreamento da Força de Trabalho',
            description: 'Monitoramento e registro das atividades e produtividade dos funcionários',
        },
        {
            title: 'Gestão de Folha de Pagamento',
            description: 'Otimização do processamento da folha de pagamento e pagamentos precisos',
        },
        {
            title: 'Gestão de Pedidos',
            description: 'Processo de gestão de pedidos/coletas através de múltiplas estratégias personalizáveis',
        },
        {
            title: 'Otimização de Rotas',
            description: 'Encontrar o caminho mais eficiente para economizar tempo, combustível e recursos.',
        },
    ],
    whatWeDoQuestion: 'O que a Naavy faz?',
    whatWeDoCards: [
        {
            title: 'Analise as Atividades dos Funcionários em Tempo Real',
            description: 'Obtenha uma análise detalhada da atividade da equipe diariamente ou por hora.',
        },
        {
            title: 'Presença e Relatórios de Check-in Baseados em GeoFence',
            description: 'Aumente a eficiência da equipe com uma gestão padronizada de presença e licenças.',
        },
        {
            title: 'Gerencie Pedidos/Coletas de Forma Eficaz',
            description:
                'Minimize erros enquanto aumenta a produtividade e a satisfação do cliente com estratégias inteligentes de pedidos e coletas pontuais.',
        },
        {
            title: 'Gestão de Tarefas de Ponta a Ponta',
            description:
                'Simplifique o planejamento de projetos, atribua tarefas com facilidade e promova a colaboração em tempo real entre os membros da equipe.',
        },
        {
            title: 'Insights Baseados em Relatórios Automatizados',
            description: 'Visualize o engajamento dos funcionários e realize uma análise perfeita da equipe.',
        },
        {
            title: 'Gerencie Despesas Baseadas em Subsídios',
            description:
                'Gerencie sistemas de RH para distribuir contracheques online conforme os registros nas planilhas de presença.',
        },
    ],
    whatWeDoList: [
        'Registros de Presença',
        'Rastreador de Localização Geo',
        'Armazenamento em Nuvem',
        'Alertas e Notificações',
        'Gestão de Projetos',
        'Gestão do Funil de Vendas',
    ],
    featureTitle: 'Recursos',
    featureSubTitle:
        'Nossos recursos são simples, poderosos e facilmente adaptáveis a qualquer setor para aumentar a produtividade da organização.',
    featureCards: [
        {
            title: 'Monitoramento de Presença',
            description: 'Registro de presença com localização e acompanhamento das horas trabalhadas',
        },
        {
            title: 'Gestão de Despesas',
            description: 'Despesas são rastreadas e registradas de forma precisa',
        },
        {
            title: 'Rastreamento da Força de Trabalho',
            description: 'Monitoramento e registro das atividades e produtividade dos funcionários',
        },
        {
            title: 'Análise de Vendas de Campo',
            description: 'Coleta de dados de vendas e avaliação do desempenho de vendas',
        },
        {
            title: 'Gestão de Pedidos',
            description: 'Processo de gestão de pedidos/coletas através de múltiplas estratégias personalizáveis',
        },
        {
            title: 'Relatórios ao Vivo',
            description: 'Saiba onde seus funcionários de campo estão e em que trabalho estão engajados',
        },
    ],
    integrationTitle: 'INDÚSTRIAS',
    integrationSubTitle: 'Conectando Gestão de Força de Trabalho e Produtividade para Todos os Setores',
    orderManagementSubtitle: 'Mais do que apenas um sistema de gestão de pedidos',
    orderManagementTitle:
        'Automatize, acompanhe e entregue com uma gestão precisa de pedidos e coletas para sua equipe de vendas.',
    orderManagementAccordian: [
        {
            title: 'Entrada de Pedidos Eletrônica',
            points: [
                'Rastreamento em tempo real do status dos pedidos desde a colocação até a entrega.',
                'Digitalize o processo capturando pedidos e reduzindo erros manuais.',
            ],
        },
        {
            title: 'Faturamento Automatizado',
            points: [
                'Gere faturas automaticamente com base nos detalhes do pedido.',
                'Envie faturas eletronicamente para processamento e pagamento mais rápidos.',
                'Verificação OTP do pagamento realizado contra a fatura.',
            ],
        },
        {
            title: 'Meta de Vendas',
            points: [
                'Acompanhe o progresso em relação às metas com relatórios detalhados e análises.',
                'Defina e monitore metas de vendas para as equipes com base em funções e responsabilidades.',
            ],
        },
        {
            title: 'Rastreamento de Inventário em Tempo Real',
            points: [
                'Mantenha uma contagem precisa dos níveis de estoque toda vez que um pedido é gerado e concluído.',
                'Defina pontos de reordem para gerar automaticamente pedidos de compra em caso de baixo estoque.',
            ],
        },
        {
            title: 'Distribuição de Pedidos',
            points: [
                'Monitore o movimento de mercadorias do armazém para o cliente, garantindo responsabilidade e transparência.',
                'Otimizar o processo de distribuição entre a equipe de vendas com base em prioridade e localização.',
                'Otimização de rotas baseada em IA para entregas mais rápidas e otimização de custos.',
            ],
        },
    ],
    whyChooseTraceforce: 'Por Que Escolher Naavy',
    whyChooseTraceforceSubtitle:
        'Revolucionando a gestão de Recursos Humanos ao automatizar tarefas administrativas, simplificar processos e melhorar o engajamento dos funcionários.',
    whyChooseTraceForceList: [
        'Melhoria da Produtividade',
        'Eficiência de Tempo',
        'Precisão nas Reivindicações',
        'Monitoramento em Tempo Real',
        'Totalmente Responsivo',
        'Otimização Baseada em IA',
        'Personalização Baseada em Critérios',
        'Suporte a Múltiplas Filiais',
        'Serviço de Suporte 24x7',
    ],
    calculatorTitle: 'Calculadora',
    calculatorSubtitle:
        'Nossos recursos são simples, poderosos e facilmente adaptáveis a qualquer setor para aumentar a produtividade da organização.',
    workingHours: 'Horas de Trabalho',
    uncountedHours: 'Horas Não Contadas',
    dailySavings: 'Economias Diárias',
    monthlySavings: 'Economias Mensais',
    productivityGrowth: 'Crescimento da Produtividade',
    numberOfEmployees: 'Número de Funcionários',
    faqs: 'Perguntas Frequentes',
    frequentlyAskedQuestions: 'Perguntas Frequentes',
    faqLine: 'Aqui estão alguns dos tipos básicos de perguntas para nossos clientes',
    faqAccordian: [
        {
            question: 'Como posso cadastrar minha empresa?',
            ans: 'Você pode se cadastrar na Naavy em 3 passos fáceis no nosso aplicativo móvel ou aplicativo web. Basta adicionar seu nome, e-mail e número de GST e você estará pronto para começar.',
        },
        {
            question: 'Quais são as diferentes maneiras pelas quais posso autenticar meu login?',
            ans: 'A Naavy oferece várias opções de login, como a opção padrão de login móvel, a opção baseada em selfie e, por último, mas não menos importante, a autenticação facial baseada em IA.',
        },
        {
            question: 'Como marcar presença?',
            ans: 'O usuário pode marcar presença somente quando estiver dentro da área geofenciada atribuída a ele. Para evitar fraudes, temos diferentes métodos de autenticação.',
        },
        {
            question:
                'Podemos simular localização ou alterar o horário para a conclusão de tarefas ou marcação de presença?',
            ans: 'A Naavy detecta qualquer atividade fraudulenta ou não solicitada e desabilitará o aplicativo, além de notificar o usuário.',
        },
        {
            question: 'A Naavy suporta funcionários remotos?',
            ans: 'A Naavy suporta funcionários contratados por hora e, portanto, calcula a folha de pagamento com base em turno fixo ou flexível.',
        },
        {
            question: 'Posso rastrear minha força de trabalho pelo celular?',
            ans: 'A Naavy ajuda a rastrear e gerenciar sua força de trabalho por meio de dispositivos móveis e web, fornecendo uma visão detalhada do dia.',
        },
        {
            question: 'Quais são os outros serviços que a Naavy oferece além do HRMS?',
            ans: 'A Naavy oferece uma lista abrangente de serviços para facilitar o trabalho de gerentes ou proprietários de negócios, fornecendo apenas as notificações necessárias.',
        },
    ],

    referAndGet: 'Indique e Ganhe',
    referAndGetH2: 'Obtenha 8% de DESCONTO nos Pacotes Anuais da Naavy!',
    referAndGetp:
        'Indique um amigo ou cliente para a Naavy e obtenha 8% de desconto no seu pacote anual assim que eles se juntarem a nós! Compartilhe os benefícios e economize juntos!',
};
export default homePage;
