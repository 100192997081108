const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Rencana Fleksibel yang Berkembang Bersama Anda',
    subFlexiblePlansThatGrowsWithYou:
        'Pilih rencana harga yang memenuhi kebutuhan bisnis Anda. Naavy menawarkan paket harga dengan harga optimal namun nilai superior.',
    pricingPlans: 'Rencana Harga',
    pricingThatWorksForEveryone: 'Harga yang Bekerja untuk Semua Orang',
    monthly: 'Bulanan',
    annualy: 'Tahunan',
    international: 'Internasional',
    domestic: 'Domestik',
    month: 'Bulan',
    benifits: 'Manfaat',
    allPlanIncludeTheseBenifits: 'Semua rencana mencakup manfaat ini',
    someBenfitsOfMonthlyYearlySubscription: 'Beberapa Manfaat dari langganan bulanan/tahunan',
    benifitsArr: [
        {
            title: 'Dukungan Teknis',
            description: 'Tim dukungan teknis profesional kami akan membantu Anda di setiap langkah.',
        },
        {
            title: 'Teknologi',
            description: 'Pelatihan khusus untuk memulai dengan platform dari para profesional.',
        },
        {
            title: 'Analisis Pertumbuhan',
            description: 'Tim khusus untuk memberikan wawasan tentang pertumbuhan bulanan Anda.',
        },
        {
            title: 'Penghargaan',
            description: 'Penghargaan khusus untuk akun dengan performa terbaik setiap bulan.',
        },
    ],
    fAQs: 'Pertanyaan yang Sering Diajukan',
    frequentlyAskedQuestions: 'Pertanyaan yang Sering Diajukan',
    someBaTypeOfQuestions: 'Berikut beberapa jenis pertanyaan umum dari pelanggan kami',
    fAQArr: [
        {
            question: 'Bagaimana cara mendaftar perusahaan saya?',
            ans: 'Anda dapat mendaftar di Naavy dalam 3 langkah mudah melalui aplikasi Mobile atau Web kami. Cukup tambahkan nama, email, dan nomor GST Anda dan Anda siap untuk melanjutkan.',
        },
        {
            question: 'Apa saja cara-cara yang dapat saya gunakan untuk mengautentikasi login saya?',
            ans: 'Naavy menyediakan beberapa opsi Clock In, misalnya kami memiliki opsi login mobile default, opsi berbasis selfie, dan yang terakhir adalah autentikasi wajah berbasis AI yang paling aman.',
        },
        {
            question: 'Bagaimana cara menandai Kehadiran?',
            ans: 'Pengguna hanya dapat menandai kehadiran jika orang tersebut berada dalam Geofence yang dialokasikan untuknya. Selain itu, untuk menghindari proksi, kami memiliki metode autentikasi yang berbeda.',
        },
        {
            question:
                'Bisakah kami memalsukan lokasi atau mengubah waktu untuk tugas yang diselesaikan atau kehadiran yang ditandai?',
            ans: 'Naavy mendeteksi aktivitas spam atau yang tidak sah dan akan menonaktifkan aplikasi serta memberi tahu pengguna.',
        },
        {
            question: 'Apakah Naavy mendukung karyawan jarak jauh?',
            ans: 'Naavy mendukung karyawan kontrak berdasarkan per jam dan menghitung penggajian berdasarkan jam tetap atau shift jam fleksibel.',
        },
        {
            question: 'Bisakah saya melacak tenaga kerja saya melalui ponsel?',
            ans: 'Naavy membantu Anda melacak dan mengelola tenaga kerja Anda melalui ponsel dan web dalam tampilan timeline. Memberikan Anda rincian komprehensif sepanjang hari.',
        },
        {
            question: 'Apa saja layanan lain yang ditawarkan Naavy selain HRMS?',
            ans: 'Naavy menawarkan daftar layanan yang sangat komprehensif untuk mempermudah pekerjaan manajer atau pemilik bisnis dan membantu mereka dengan hanya pemberitahuan yang diperlukan.',
        },
    ],
};
export default pricingPage;
